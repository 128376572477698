import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Checkbox, CheckboxGroup } from 'react-checkbox-group';

const FormCheckboxGroup = ({ error, help, label, options, ...props }) => {
  const classes = classNames('form-group', { 'has-error': error });
  return (
    <div className={classes}>
      <label htmlFor={label.htmlFor} className="label-control">
        {label.text}
      </label>
      <CheckboxGroup
        name={props.name}
        value={props.value}
        onChange={(e) => props.onChange({ target: { name: props.name, value: e } })}
      >
        {options.map((option) => {
          return (
            <div className="checkbox" key={option[0]}>
              <label>
                <Checkbox {...props} value={option[0]} />
                {option[1]}
              </label>
            </div>
          );
        })}
      </CheckboxGroup>
      {help ? <p className="help-block">{help}</p> : null}
      {error ? <span className="help-block">{error}</span> : null}
    </div>
  );
};

FormCheckboxGroup.propTypes = {
  help: PropTypes.string,
  error: PropTypes.string,
  label: PropTypes.shape({
    htmlFor: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }),
  options: PropTypes.arrayOf(PropTypes.array),
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default FormCheckboxGroup;
