import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ThreeBounce } from 'better-react-spinkit';

class Loading extends React.Component {
  static propTypes = {
    fadeIn: PropTypes.oneOf(['full', 'half', 'quarter']),
    delay: PropTypes.number,
  };

  state = {
    show: false,
    showLongWait: false,
  };

  componentDidMount() {
    this._mounted = true;
    this._timer = setTimeout(
      () => this._mounted && this.setState({ show: true }),
      this.props.delay || 1000
    );
    this._longWaitTimer = setTimeout(
      () => this._mounted && this.setState({ showLongWait: true }),
      30 * 1000
    );
  }

  componentWillUnmount() {
    this._mounted = false;
    if (this._timer) {
      clearTimeout(this._timer);
    }
    if (this._longWaitTimer) {
      clearTimeout(this._longWaitTimer);
    }
  }

  render() {
    if (!this.state.show && !this.state.showLongWait) {
      return null;
    }
    const fadeIn = this.props.fadeIn || 'half';
    const classes = classNames({
      'sk-fade-in': fadeIn === 'full',
      'sk-fade-in-half-second': fadeIn === 'half',
      'sk-fade-in-quarter-second': fadeIn === 'quarter',
    });
    return (
      <React.Fragment>
        <div className="flex-single-row-container--loading mt3">
          <ThreeBounce className={classes} {...this.props} />
        </div>
        {this.state.showLongWait && (
          <p className="center mt1">
            This appears to be taking a while to load.
            <br />
            Check your internet connection, or try <a href="">refreshing the page</a>?
          </p>
        )}
      </React.Fragment>
    );
  }
}

export default Loading;
