import { combineReducers } from 'redux';
import { FETCH_QUESTION_DETAIL, QUESTION_UPDATE, QUESTION_DELETE } from 'store/actions/questions';

export const initialState = {};

export const keys = (state = initialState, action) => {
  let question;
  switch (action.type) {
    case FETCH_QUESTION_DETAIL.PENDING:
      question = state[action.questionId];
      return { ...state, [action.questionId]: { ...question, isLoading: action.bool } };
    case FETCH_QUESTION_DETAIL.SUCCESS:
      return { ...state, [action.question.id]: { ...action.question, isLoading: false } };
    case FETCH_QUESTION_DETAIL.ERROR:
      question = state[action.questionId];
      return { ...state, [action.questionId]: { ...question, hasErrored: action.bool } };
    case QUESTION_UPDATE:
      question = state[action.questionId];
      // handle special case of matrix
      if (action.data.answer && action.data.answer.value.matrix) {
        const currentMatrix = (question.answer && question.answer.value) || {};
        const combinedMatrix = { ...currentMatrix, ...action.data.answer.value.matrix };
        return {
          ...state,
          [action.questionId]: { ...question, answer: { value: combinedMatrix } },
        };
      }
      return { ...state, [action.questionId]: { ...question, ...action.data } };
    case QUESTION_DELETE:
      delete state[action.questionId];
      return { ...state };
    default:
      return state;
  }
};

export const ids = (state = [], action) => {
  let set;
  switch (action.type) {
    case FETCH_QUESTION_DETAIL.SUCCESS:
      set = new Set([...state, action.question.id]);
      return [...set];
    case QUESTION_DELETE:
      return state.filter((questionId) => questionId !== action.questionId);
    default:
      return state;
  }
};

const questions = combineReducers({
  keys,
  ids,
});

export default questions;
