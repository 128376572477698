import React from 'react';
import { SiteTutorials } from 'api';
import { Alert, Loading, PageHeader } from 'components';
import { failedAssertion } from '../../helpers/reporting';
import { ResourcesPage } from './ResourcesPage';

export class Tutorials extends React.Component {
  state = {
    listLoading: true,
    listError: false,
    videos: [],
  };

  async componentDidMount() {
    try {
      let videos = await SiteTutorials.list();
      this.setState({ videos, listLoading: false, listError: false });
    } catch (err) {
      failedAssertion(err);
      this.setState({ listError: true, listLoading: false });
    }
  }

  render() {
    return (
      <ResourcesPage>
        <PageHeader.Basic title="How to use the PDP website" />
        <div className="row">
          <TutorialVideosList
            error={this.state.listError}
            loading={this.state.listLoading}
            videos={this.state.videos}
          />
        </div>
      </ResourcesPage>
    );
  }
}

function TutorialVideosList(props) {
  if (props.error) {
    return (
      <div className="col-xs-12">
        <Alert type="danger" icon="fa-exclamation-triangle">
          Something went wrong. Our team has been notified, please try again or check back later.
        </Alert>
      </div>
    );
  }
  if (props.loading) {
    return <Loading />;
  }
  if (!props.videos || !props.videos.length) {
    return (
      <div className="col-xs-12">
        <p className="text-size">No videos have been found.</p>
      </div>
    );
  }
  return props.videos.map((video) => {
    return (
      <div title={video.name} key={video.id} className="col-xs-12 col-md-6 col-lg-4 center mb2">
        <div className="text-size-lg bold videos-header">{video.name}</div>
        <div className="video-wrapper">
          <iframe src={video.embed} allowFullScreen />
        </div>
      </div>
    );
  });
}
