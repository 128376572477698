import React from 'react';
import { Form } from './Form';
import { ResourcesPage } from '../ResourcesPage';

export const RidPinraForm = () => (
  <ResourcesPage>
    <Form
      header="RID PINRA Form - Non DAS PD Events"
      forms={[
        {
          title: 'RID_PINRA_Interactive_Form.pdf',
          url: 'files/forms/RID_PINRA_Interactive_Form.pdf',
        },
      ]}
    >
      <small>
        Note: Adobe Acrobat Reader DC is{' '}
        <strong>
          <i>REQUIRED</i>
        </strong>{' '}
        for filling out and signing the interactive PDF.
        <br />
        If you do not use Adobe Acrobat Reader DC, the information you enter will not be saved and
        your signature will not be recorded.
        <br />
        Please see{' '}
        <a href="https://daspdp.org/resources/tutorials/" target="_blank" rel="noopener noreferrer">
          this link
        </a>{' '}
        for instructions on downloading Adobe Acrobat Reader DC, setting up your digital signature,
        and signing a document.
      </small>
      <br />
      <br />
      <p>
        PINRA forms are provided as a vehicle for earning CEU credits for events that are
        educationally beneficial but do not fall under the purview of an RID sponsored event.
        Participant must discuss the activity with the PDP Manager for <strong>preliminary</strong>{' '}
        approval and fill out all information requested on the PINRA form. Participants are
        responsible for providing documentation of participation in the event and for returning all
        documentation requested to the PDP Manager for timely submission to the RID Sponsor.
      </p>
      <strong>
        Note: The participant must provide copies of registration, conference agenda, or other
        documentation that identifies content, hours, and attendance to enable the Sponsor to
        determine the appropriate number of CEUs and Content Area(s).
      </strong>
      <h3>
        <i>Step One:</i>
      </h3>
      <p>
        Choose the activity or conference you want to attend. The activity or conference does not
        have to pertain strictly to interpreting. It can be a regional or national conference of
        another profession, or could be a formal in-service training by an employer. As long as the
        activity is educationally beneficial to you as an interpreter, you can earn CEUs for the
        event!
      </p>
      <h3>
        <i>Step Two:</i>
      </h3>
      <p>
        Contact an RID Approved Sponsor and secure their agreement to process the paperwork for the
        conference. Be sure to discuss the Content Area for which you are applying (Professional
        Studies or General Studies).
        <br />
        How many CEUs will be earned? (1 CEU = 10 contact hours)
      </p>
      <h3>
        <i>Step Three:</i>
      </h3>
      <p>
        Complete the Participant Initiated Activity Plan and Activity Report form from your RID
        Sponsor.
        <br />
        <br />
        Attach pertinent documentation such as an agenda, program book.
        <br />
        <br />
        Return form and documentation to your Sponsor who will keep the form until your activity is
        completed.
        <br />
        <br />
        <strong>
          <u>This form must be sent to your Sponsor before the activity begins.</u>
        </strong>
      </p>
      <h3>
        <i>Step Four:</i>
      </h3>
      <p>
        Upon completion of the activity, attain a certificate of attendance or documentation such as
        an agenda or program book.
        <br />
        <br />
        Send documentation to your Sponsor.
        <br />
        <br />
      </p>
    </Form>
  </ResourcesPage>
);
