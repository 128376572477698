import 'fullcalendar/dist/fullcalendar';
import 'bootstrap-select/dist/js/bootstrap-select';
import { EventDictionary } from './dictionaries';
import PNotify from 'pnotify';

const PD_EVENTS = 'p';
const CALENDAR_EVENTS = 'c';
const unknownIndex = Object.keys(EventDictionary.EVENT_TYPES).indexOf('UNKNOWN');
let pdEventTypes = Object.values(EventDictionary.EVENT_TYPES);
pdEventTypes.splice(unknownIndex, 1);

export const renderCalendar = () => {
  let strand = $('#strand-selector').val() || '';
  let type = $('#type-selector').val() || '';

  var getUrl = function() {
    const calendarType = $('#calendar').attr('data-calendar-type');
    if (calendarType === 'cart') {
      let slug = $('#slug')
        .text()
        .trim();
      slug = slug === '' ? 'personal' : slug;
      return '/api/calendar/accounts/' + slug;
    }
    return '/api/calendar/events/';
  };

  var options;
  options = {
    header: {
      left: 'prev,next today',
      center: 'title',
      right: 'month,agendaWeek,agendaDay',
    },
    timezone: 'local',
    eventRender: function(event, element, view) {
      if (event.description) {
        element
          .find('.fc-title')
          .after(
            "<span class='fc-description' style='display: none'>" + event.description + '</span>'
          );
      }
      element.prop('title', event.description);
      if (strand === '' || event.strand === strand) {
        if (type === '') {
          return true;
        } else if (type === PD_EVENTS) {
          if (pdEventTypes.includes(event.type)) {
            return true;
          }
        } else if (type === CALENDAR_EVENTS) {
          if (!pdEventTypes.includes(event.type)) {
            return true;
          }
        }
      }
      return false;
    },
    events: {
      url: getUrl(),
      type: 'GET',
      timezoneParam: 'local',
      error: function() {
        PNotify.prototype.options.delay = 5000;
        window.show_stack_topleft('Error', 'There was an error fetching events.', 'error');
        PNotify.prototype.options.delay = 3000;
      },
    },
  };

  if ($('#calendar').data('event-id')) {
    options.events.data = {
      event: $('#calendar').data('event-id'),
    };
  }
  $('#calendar').fullCalendar(options);
  $('#strand-selector').on('change', function() {
    strand = $('#strand-selector').val();
    $('#calendar').fullCalendar('rerenderEvents');
  });
  $('#type-selector').on('change', function() {
    type = $('#type-selector').val();
    $('#calendar').fullCalendar('rerenderEvents');
  });
  const getParameterByName = (name, url) => {
    if (!url) url = window.location.href;
    // eslint-disable-next-line no-useless-escape
    name = name.replace(/[\[\]]/g, '\\$&');
    let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
    let results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  };
  const updateHref = (selector, params) => {
    let href = $('[data-calendar-export]').attr('href');
    let newHref = href.split('?')[0] + '?';
    if (selector.value) {
      newHref = `${newHref}${selector.key}=${selector.value}`;
    }
    let newParams = params.map((param) => {
      const paramValue = getParameterByName(param, href);
      if (paramValue) {
        return `&${param}=${getParameterByName(param, href)}`;
      }
      return '';
    });
    if (newParams) {
      newHref = newHref + newParams.join('');
    }
    return newHref;
  };
  const PARAMS = ['strand', 'filter'];
  $('[data-id=strand-selector]')
    .parent()
    .on('change', function(e) {
      const params = PARAMS.filter((x) => {
        return x !== 'strand';
      });
      const href = updateHref({ key: 'strand', value: e.target.value }, params);
      $('[data-calendar-export]').attr('href', href);
    });
  $('[data-id=type-selector]')
    .parent()
    .on('change', function(e) {
      const params = PARAMS.filter((x) => {
        return x !== 'filter';
      });
      const href = updateHref({ key: 'filter', value: e.target.value }, params);
      $('[data-calendar-export]').attr('href', href);
    });
};

if (process.env.NODE_ENV !== 'test') {
  $(document).ready(function() {
    renderCalendar();
  });
}
