import React from 'react';
import PropTypes from 'prop-types';
import IconTooltip from './IconTooltip';

export const Legend = (props) => {
  return (
    <div className="text-center">
      <h3>Legend</h3>
      <div className="flex-center">
        <div className="legend">
          <div className="color-box color-box--legend color-box__cart" />
          Added to Cart
        </div>
        <div className="legend">
          <div className="color-box color-box--legend color-box__reg-pending" />
          Registration Pending
        </div>
        <div className="legend">
          <div className="color-box color-box--legend color-box__reg-approved" />
          Registration Approved
        </div>
        <div className="legend">
          <div className="color-box color-box--legend color-box__enrolled" />
          Currently Enrolled
        </div>
      </div>
    </div>
  );
};

export const FullCalendar = (props) => {
  return (
    <div
      id={props.id}
      className="white-soft-box2"
      data-calendar-type={props.calendarType}
      data-event-id={props.eventId}
    />
  );
};

FullCalendar.propTypes = {
  id: PropTypes.string.isRequired,
  calendarType: PropTypes.oneOf(['cart', 'default']).isRequired,
  eventId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export const Calendar = (props) => {
  const wrappingClasses = props.wrappingClasses
    ? props.wrappingClasses
    : 'col-xs-12 col-md-10 col-md-offset-1';
  const title = props.calendarTitle ? props.calendarTitle : 'Calendar';
  const tooltipIcon = props.tooltipIcon ? props.tooltipIcon : 'fa-info-circle';
  return (
    <div className={wrappingClasses}>
      <h1 className="text-center">
        {title}
        <IconTooltip icon={tooltipIcon} message={props.tooltipMessage} />
      </h1>
      <Legend />
      <FullCalendar id={props.id} calendarType={props.calendarType} eventId={props.eventId} />
    </div>
  );
};

Calendar.propTypes = {
  id: PropTypes.string.isRequired,
  calendarType: PropTypes.oneOf(['cart', 'default']).isRequired,
  eventId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  wrappingClasses: PropTypes.string,
  tooltipMessage: PropTypes.string.isRequired,
  tooltipIcon: PropTypes.string,
  calendarTitle: PropTypes.string,
};
