import React from 'react';

const ReasonableAccomodationsStatement = (props) => {
  return (
    <p>
      If you require communication or access accommodations, please contact us at daspd@rit.edu at
      least two weeks prior to the start of the event.
    </p>
  );
};

export default ReasonableAccomodationsStatement;
