import queryString from 'query-string';
import { Events, Registration, notifyError } from 'api';
import { asyncActionType } from 'store/actions';
import { eventsExploreDetailUpdate } from 'store/actions/events/explore';
import get from 'lodash/get';

export const FETCH_EVENT_DETAIL = asyncActionType('FETCH_EVENT_DETAIL');

export const eventsDetailPending = (bool) => {
  return {
    type: FETCH_EVENT_DETAIL.PENDING,
    bool,
  };
};

export const eventsDetailError = (bool, error) => {
  return {
    type: FETCH_EVENT_DETAIL.ERROR,
    bool,
    error,
  };
};

export const eventsDetailSuccess = (data) => {
  return {
    type: FETCH_EVENT_DETAIL.SUCCESS,
    data,
  };
};

export const refreshEventsDetail = (eventId) => (dispatch, getState) => {
  eventId = parseInt(eventId);
  return Events.exploreDetail(eventId)
    .then((data) => {
      const { eventsExplore } = getState();
      const event = eventsExplore.events.find((event) => event.pk === eventId);
      if (event !== data) {
        dispatch(eventsDetailSuccess(data));
        dispatch(eventsExploreDetailUpdate(data));
      }
    })
    .catch((err) => {
      throw err;
    });
};

export const requestEventsDetail = (eventId) => (dispatch, getState) => {
  eventId = parseInt(eventId);
  const { eventsExplore } = getState();
  const event = eventsExplore.events.find((event) => event.pk === eventId);
  if (event) {
    dispatch(eventsDetailSuccess(event));
  } else {
    dispatch(eventsDetailPending(true));
    const params = queryString.parse(window.location.search);
    Events.exploreDetail(eventId, params ? params.token : undefined)
      .then((data) => {
        dispatch(eventsDetailSuccess(data));
        dispatch(eventsExploreDetailUpdate(data));
      })
      .catch((err) => {
        dispatch(eventsDetailError(true, err));
        const status = get(err, 'response.status');
        if (status !== 404) {
          throw err;
        }
      });
  }
};

export const submitRegistrationRequest = (data) => (dispatch, getState) => {
  return Registration.add(data)
    .then((res) => {
      return dispatch(refreshEventsDetail(res.event));
    })
    .catch((err) => {
      notifyError(err);
      throw err;
    });
};
