import { FETCH_AFFILIATION_LIST } from 'store/actions/accounts/affiliations';

export const initialState = { isLoading: true, hasErrored: false, list: [] };

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_AFFILIATION_LIST.PENDING:
      return { ...state, isLoading: action.bool };
    case FETCH_AFFILIATION_LIST.ERROR:
      return { ...state, hasErrored: action.bool };
    case FETCH_AFFILIATION_LIST.SUCCESS:
      return { ...state, list: action.data, isLoading: false, hasErrored: false };
    default:
      return state;
  }
};
