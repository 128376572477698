import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import { getDetailSelector } from 'store/selectors';
import {
  FETCH_SURVEY_LIST,
  FETCH_SURVEY_DETAIL,
  SURVEY_QUESTION_BLOCK_ADDED,
  SURVEY_UPDATE,
  SURVEY_DELETE,
  SURVEY_DETAIL_NOT_FOUND,
} from 'store/actions/surveys';
import { omit } from 'lodash';

export const initialState = {
  isLoading: false,
  hasErrored: false,
};

export const keys = (state = initialState, action) => {
  let survey;
  let surveysList;
  switch (action.type) {
    case FETCH_SURVEY_DETAIL.PENDING:
      survey = state[action.surveyId];
      return { ...state, [action.surveyId]: { ...survey, isLoading: action.bool } };
    case FETCH_SURVEY_DETAIL.SUCCESS:
      return { ...state, [action.survey.id]: { ...action.survey, isLoading: false } };
    case FETCH_SURVEY_DETAIL.ERROR:
      survey = state[action.surveyId];
      return { ...state, [action.surveyId]: { ...survey, hasErrored: action.bool } };
    case FETCH_SURVEY_LIST.PENDING:
      return { ...state, isLoading: action.bool };
    case FETCH_SURVEY_LIST.SUCCESS:
      surveysList = action.surveys.reduce((accumulator, survey) => {
        accumulator[survey.id] = survey;
        return accumulator;
      }, {});
      return { ...state, ...surveysList, isLoading: false };
    case FETCH_SURVEY_LIST.ERROR:
      return { ...state, hasErrored: action.bool };
    case SURVEY_QUESTION_BLOCK_ADDED:
      survey = state[action.surveyId];
      survey.question_blocks.push(action.block);
      return {
        ...state,
        [action.surveyId]: { ...survey, question_blocks: survey.question_blocks },
      };
    case SURVEY_UPDATE:
      survey = state[action.surveyId];
      return { ...state, [action.surveyId]: { ...survey, ...action.data } };
    case SURVEY_DELETE:
      return omit(state, action.surveyId);
    case SURVEY_DETAIL_NOT_FOUND:
      survey = state[action.surveyId];
      return { ...state, [action.surveyId]: { ...survey, notFound: action.bool } };
    default:
      return state;
  }
};

export const ids = (state = [], action) => {
  let set;
  switch (action.type) {
    case FETCH_SURVEY_DETAIL.SUCCESS:
      set = new Set([...state, action.survey.id]);
      return [...set];
    case FETCH_SURVEY_LIST.SUCCESS:
      set = new Set(state.concat(action.surveys.map((survey) => survey.id)));
      return [...set];
    case SURVEY_DELETE:
      return state.filter((id) => id !== action.surveyId);
    default:
      return state;
  }
};

const surveys = combineReducers({
  keys,
  ids,
});

export const questionBlockQuestionsSelector = (questionsReducer, answersReducer, blocks) =>
  blocks.map((block) => {
    if (!block || block.isLoading || !block.questions) {
      return block;
    }
    let questions = block.questions.map((questionId) =>
      getDetailSelector(questionsReducer, questionId)
    );
    questions = questions.sort((a, b) =>
      a.order_id && b.order_id ? (a.order_id < b.order_id ? 0 : 1) : a.number < b.number ? 0 : 1
    );
    return { ...block, questions };
  });

export const surveyQuestionBlocksSelector = (
  { surveys, questionBlocks, questions, answers },
  surveyId
) => {
  const survey = getDetailSelector(surveys, surveyId);
  if (!survey || survey.isLoading || !survey.question_blocks) {
    return survey;
  }
  let blocks = survey.question_blocks.map((block) => getDetailSelector(questionBlocks, block.id));
  blocks = blocks.filter((block) => !!block);
  blocks = questionBlockQuestionsSelector(questions, answers, blocks);
  blocks = blocks.sort((a, b) => (a.order_id < b.order_id ? 0 : 1));
  return {
    ...survey,
    question_blocks: blocks,
  };
};

export const makeSurveyDetailSelector = () => {
  return createSelector(
    surveyQuestionBlocksSelector,
    (survey) => survey
  );
};

export default surveys;
