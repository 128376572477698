import React from 'react';
import { ContactInfo } from './ContactInfo';
import { ContactForm } from './ContactForm';

export const ContactPage = () => (
  <div className="row">
    <div className="col-xs-12">
      <div className="white-soft-box2 white-soft-box2--no-animate">
        <div className="row">
          <div className="col-sm-6">
            <ContactInfo />
          </div>
          <div className="col-sm-6">
            <ContactForm />
          </div>
        </div>
      </div>
    </div>
  </div>
);
