import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const LinkComponent = (props) => {
  const { href, router, children, ...linkProps } = props;
  if (router) {
    return (
      <Link to={href} {...linkProps}>
        {children}
      </Link>
    );
  }
  return (
    <a href={href} {...linkProps}>
      {children}
    </a>
  );
};
LinkComponent.propTypes = {
  router: PropTypes.bool,
  href: PropTypes.string,
  children: PropTypes.node,
};

const ActionButtons = (props) => {
  let spacer = '';
  if (props.leftOrRightSpacing === 'left') {
    spacer = ' icon-space-l';
  } else if (props.leftOrRightSpacing === 'right') {
    spacer = ' icon-space-r';
  }
  return (
    <React.Fragment>
      {props.buttons.map((button, i) => {
        const classNames = `fa ${button[2]} icon-space-r`;
        return (
          <LinkComponent
            key={i}
            href={button[0]}
            className={`btn btn-default${spacer} ${props.buttonClassName}`}
            router={button[3]}
          >
            <i className={classNames} />
            {button[1]}
          </LinkComponent>
        );
      })}
    </React.Fragment>
  );
};

ActionButtons.propTypes = {
  buttons: PropTypes.arrayOf(PropTypes.array).isRequired,
  leftOrRightSpacing: PropTypes.oneOf(['left', 'right', 'none']),
  buttonClassName: PropTypes.string,
};

export default ActionButtons;
