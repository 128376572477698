import React from 'react';
import { PinraForm } from './PinraForm';

export const PinraPage = () => (
  <div className="row">
    <div className="col-xs-12 col-md-8 col-md-offset-2">
      <div className="white-soft-box2">
        <h1 className="page-header text-center" id="headline">
          Create Pinra Proposal
        </h1>
        <PinraForm />
      </div>
    </div>
  </div>
);
