import React from 'react';
import { Form } from './Form';
import { ResourcesPage } from '../ResourcesPage';

export const IndependentReportForm = () => (
  <ResourcesPage>
    <Form
      header="Independent Study Activity Report"
      forms={[
        {
          title: 'Independent_Study_Interactive_Form.pdf',
          url: 'files/forms/Independent_Study_Interactive_Form.pdf',
        },
      ]}
    >
      <small>
        Note: Adobe Acrobat Reader DC is{' '}
        <strong>
          <i>REQUIRED</i>
        </strong>{' '}
        for filling out and signing the interactive PDF.
        <br />
        If you do not use Adobe Acrobat Reader DC, the information you enter will not be saved and
        your signature will not be recorded.
        <br />
        Please see{' '}
        <a href="https://daspdp.org/resources/tutorials/" target="_blank" rel="noopener noreferrer">
          this link
        </a>{' '}
        for instructions on downloading Adobe Acrobat Reader DC, setting up your digital signature,
        and signing a document.
      </small>
      <h3>
        <i>Step One:</i>
      </h3>
      <p>
        <strong>Decide</strong> the activity for which you want to earn CEUs. Keep in mind that the
        Independent Study will require you to answer questions such as what and why you want to do
        the activity? What are your specific goals? How will you accomplish these goals? How will
        you show what you have learned? How much time will the activity involve?
      </p>
      <h3>
        <i>Step Two:</i>
      </h3>
      <p>
        <strong>Contact</strong> an <strong>RID Approved Sponsor</strong> to discuss your ideas,
        CEUs and to process your paperwork. A maximum of 2.0 CEUs can be awarded for each
        Independent Study. The Sponsor will give you the <strong>Independent Study Plan</strong>{' '}
        form to complete and <strong>sign</strong>. You will then <strong>return </strong> this form
        to the Sponsor. You may only begin work on your Independent Study once the Sponsor approves
        and signs-off on the Independent Study Plan!
      </p>
      <h3>
        <i>Step Three:</i>
      </h3>
      <p>
        <strong>Document</strong> your time and efforts throughout the activity. At the{' '}
        <strong>completion</strong> of the Independent Study, send the Sponsor your report,
        documentation and other information pertinent to the activity that you noted in your
        Activity Plan. The Sponsor reviews the documentation to ensure that it meets the standards
        and goals agreed upon in the Activity Plan, and if so, will file the paperwork with RID
        online at{' '}
        <a href="www.rid.org" target="_blank">
          www.rid.org
        </a>
        .
      </p>
    </Form>
  </ResourcesPage>
);
