import React from 'react';
import { Link } from 'react-router-dom';

export const ResourcesNav = () => (
  <div className="sidebar-page__sidebar" role="navigation" data-spy="affix" data-offset-top="224">
    <div className="white-soft-box2 white-soft-box2--no-animate">
      <ul className="nav nav-sidebar page-header-align">
        <li>
          <Link to={window.URLS['pd:resources']()}>Resources</Link>
        </li>
        <li>
          <Link to={window.URLS['pd:resources_book_library']()}>DAS Book Library</Link>
        </li>
        <li>
          <Link to={window.URLS['pd:resources_video_library']()}>DAS Video Library</Link>
        </li>
        <li>
          <Link to={window.URLS['pd:resources_site_tutorials']()}>PDP Site Tutorials</Link>
        </li>
        <li>
          <Link to={window.URLS['pd:resources_forms']()}>Forms</Link>
        </li>
        <li>
          <a target="_blank" href="https://wiki.rit.edu/dologin.action">
            Wiki
            <i className="fa fa-external-link-square icon-space-l" title="Open external website" />
          </a>
        </li>
        <li>
          <Link to={window.URLS['pd:resources_faq']()}>FAQ</Link>
        </li>
      </ul>
    </div>
  </div>
);
