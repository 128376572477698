import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const FormSelect = ({ error, help, label, options, ...props }) => {
  const classes = classNames('form-group', { 'has-error': error });
  return (
    <div className={classes}>
      <label htmlFor={label.htmlFor} className="label-control">
        {label.text}
      </label>
      <select className="select form-control" {...props}>
        {options.map((option) => {
          return (
            <option key={option[0]} value={option[0]}>
              {option[1]}
            </option>
          );
        })}
      </select>
      {help ? <p className="help-block">{help}</p> : null}
      {error ? <span className="help-block">{error}</span> : null}
    </div>
  );
};

FormSelect.propTypes = {
  error: PropTypes.string,
  label: PropTypes.shape({
    htmlFor: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }),
  help: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.array),
};

export default FormSelect;
