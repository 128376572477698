import { FETCH_CAROUSEL_LIST } from 'store/actions/carousels';

export const initialState = {
  isLoading: false,
  hasErrored: false,
  carousels: [],
};

const carousels = (state = initialState, action) => {
  let carousels;
  switch (action.type) {
    case FETCH_CAROUSEL_LIST.PENDING:
      return { ...state, isLoading: action.bool };
    case FETCH_CAROUSEL_LIST.SUCCESS:
      carousels = action.carousels;
      return { ...state, carousels, isLoading: false };
    case FETCH_CAROUSEL_LIST.ERROR:
      return { ...state, hasErrored: action.bool };
    default:
      return state;
  }
};

export default carousels;
