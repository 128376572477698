import { asyncActionType } from 'store/actions';
import { Profile } from 'api';

export const FETCH_PROFILE_LIST = asyncActionType('FETCH_PROFILE_LIST');
export const FETCH_PROFILE_DETAIL = asyncActionType('FETCH_PROFILE_DETAIL');
export const CLEAR_PROFILE_FILTERS = 'CLEAR_PROFILE_FILTERS';
export const UPDATE_PROFILE_FILTER = 'UPDATE_PROFILE_FILTER';

export const profileListPending = (bool) => {
  return {
    type: FETCH_PROFILE_LIST.PENDING,
    bool,
  };
};

export const profileListError = (bool) => {
  return {
    type: FETCH_PROFILE_LIST.ERROR,
    bool,
  };
};

export const profileListSuccess = (data) => {
  return {
    type: FETCH_PROFILE_LIST.SUCCESS,
    data,
  };
};

export const profileDetailPending = (bool) => {
  return {
    type: FETCH_PROFILE_DETAIL.PENDING,
    bool,
  };
};

export const profileDetailError = (bool) => {
  return {
    type: FETCH_PROFILE_DETAIL.ERROR,
    bool,
  };
};

export const profileDetailSuccess = (data) => {
  return {
    type: FETCH_PROFILE_DETAIL.SUCCESS,
    data,
  };
};

export const updateProfileFilter = ({ name, value }) => {
  return {
    type: UPDATE_PROFILE_FILTER,
    name,
    value,
  };
};

export const clearProfileFilters = () => (dispatch, getState) => {
  dispatch({ type: CLEAR_PROFILE_FILTERS });
  return Promise.resolve();
};

export const profileListThunk = (updatedFilters = {}) => (dispatch, getState) => {
  const filters = { ...getState().profiles.keys.filters, ...updatedFilters };
  dispatch(profileListPending(true));
  return Profile.list(filters)
    .then((data) => {
      dispatch(profileListSuccess(data));
    })
    .catch((err) => {
      dispatch(profileListError(true));
      throw err;
    });
};

export const profileDetailThunk = (userSlug) => (dispatch, getState) => {
  let profile = getState().profiles.keys[userSlug];
  if (profile) {
    return Promise.resolve(profile);
  }
  dispatch(profileDetailPending(true));
  return Profile.detail(userSlug)
    .then((data) => {
      dispatch(profileDetailSuccess(data));
    })
    .catch((err) => {
      dispatch(profileDetailError(true));
      throw err;
    });
};
