import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import RadioButtonGroup from './RadioButtonGroup';

const FormRadioBtnGroup = ({ error, help, label, options, value, ...props }) => {
  const classes = classNames('form-group', { 'has-error': error });
  return (
    <div className={classes}>
      <label htmlFor={label.htmlFor} className="label-control">
        {label.text}
      </label>
      <div>
        <RadioButtonGroup name={props.name} selectedValue={value} options={options} {...props} />
      </div>
      {help ? <p className="help-block">{help}</p> : null}
      {error ? <span className="help-block">{error}</span> : null}
    </div>
  );
};

FormRadioBtnGroup.propTypes = {
  error: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.array),
  label: PropTypes.shape({
    htmlFor: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }),
  help: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
};

export default FormRadioBtnGroup;
