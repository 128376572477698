import React from 'react';

export const ContactInfo = () => (
  <React.Fragment>
    <h2 className="page-header">Location &amp; Contact Info</h2>
    <div className="contact-info">
      <p>
        <i className="fa fa-fw fa-phone" />
        <span itemProp="telephone">(585) 475-3972</span>
      </p>
      <p>
        <i className="fa fa-fw fa-video-camera" />
        <span itemProp="telephone">(585) 286-4147</span>
      </p>
      <p>
        <i className="fa fa-fw fa-envelope-o" />
        <span itemProp="email">daspd@rit.edu</span>
      </p>
      <p>
        <i className="fa fa-fw fa-map-marker" />
        Hugh L. Carey Hall (Building 14)
        <br />1 Lomb Memorial Dr
        <br />
        Rochester, NY 14623
      </p>
    </div>
    <iframe
      className="contact-map"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d937.105336729247!2d-77.67912421123232!3d43.0823888980802!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d14c5e5c507f1d%3A0xf58cecb575afa407!2sHugh+Carey+Bldg%2C+Rochester%2C+NY+14623!5e1!3m2!1sen!2sus!4v1454704944930"
      width="100%"
      height="400"
      frameBorder="0"
      style={{ border: 0 }}
      allowFullScreen=""
    />
  </React.Fragment>
);
