import Fuse from 'fuse.js';

$('.search-container').each(function() {
  var fuse, items, updateSearch;
  items = [];
  $('.search-text').each(function() {
    return items.push($.trim($(this).text()));
  });
  fuse = new Fuse(items, { threshold: 0.3 });
  updateSearch = function(query) {
    var indices;
    if ($.trim(query).length === 0) {
      $('.search-item').show();
      $('.no-results').hide();
      return;
    }
    indices = fuse.search(query);
    $('.search-item').each(function(i) {
      if (indices.includes(i)) {
        return $(this).show();
      } else {
        return $(this).hide();
      }
    });
    if (indices.length === 0) {
      return $('.no-results').show();
    } else {
      return $('.no-results').hide();
    }
  };
  $('.search-input').on('input', function() {
    return updateSearch($(this).val());
  });
  return $('.search-input[type=text]').focus();
});
