import React from 'react';
import cx from 'classnames';
import { Formik } from 'formik';
import { object, string } from 'yup';
import RecaptchaWrapper from 'react-google-recaptcha';
import { Contact } from 'api';
import { failedAssertion } from '../../helpers/reporting';

const contactSchema = object().shape({
  name: string()
    .required()
    .max(255)
    .trim(),
  email: string()
    .email()
    .required()
    .max(255),
  subject: string()
    .required()
    .max(255)
    .trim(),
  body: string()
    .required()
    .max(5000)
    .trim(),
});

const inputFields = [
  { name: 'name', label: 'My name is', placeholder: 'John Smith', type: 'text' },
  { name: 'email', label: 'My email is', placeholder: 'jsmith@rit.edu', type: 'email' },
  { name: 'subject', label: 'I want to talk about', placeholder: 'Subject', type: 'text' },
];

export class ContactForm extends React.Component {
  state = {
    recaptcha: false,
  };

  onRecaptchaChange = (value) => {
    this.setState({ recaptcha: !!value });
  };

  handleSubmit = async (data, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    if (!this.state.recaptcha) {
      setSubmitting(false);
      return window.show_stack_topleft(
        'Error',
        'ReCAPTCHA verification failed, please try again.',
        'error'
      );
    }
    try {
      await Contact.post(data);
      window.show_stack_topleft(
        'Success',
        'Thanks! We will get in touch as soon as we can.',
        'success'
      );
      setSubmitting(false);
      resetForm({ name: '', email: '', subject: '', body: '' });
    } catch (err) {
      window.show_stack_topleft('Error', 'Failed to submit, please try again later.', 'error');
      failedAssertion(err, data);
      setSubmitting(false);
    }
  };
  render() {
    return (
      <React.Fragment>
        <h2 className="page-header">Contact Us</h2>
        <p>Fill out the form below to get in contact with us!</p>
        <Formik
          initialValues={{ name: '', email: '', subject: '', body: '' }}
          validationSchema={contactSchema}
          onSubmit={this.handleSubmit}
        >
          {({ values, touched, errors, isSubmitting, handleChange, handleBlur, handleSubmit }) => (
            <form onSubmit={handleSubmit} className="form">
              {inputFields.map(({ name, label, placeholder, type }) => (
                <div
                  key={name}
                  className={cx('form-group', { 'has-error': touched[name] && errors[name] })}
                >
                  <label className="control-label" htmlFor={`id_${name}`}>
                    {label}
                  </label>
                  <input
                    type={type}
                    name={name}
                    value={values[name]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={placeholder}
                    id={`id_${name}`}
                    className="form-control"
                  />
                  {touched[name] && errors[name] && (
                    <span className="help-block">{errors[name]}</span>
                  )}
                </div>
              ))}

              <div className={cx('form-group', { 'has-error': touched.body && errors.body })}>
                <label className="control-label" htmlFor="id_body">
                  Here's what I have to say
                </label>
                <textarea
                  name="body"
                  cols="40"
                  rows="10"
                  placeholder="Content"
                  className="form-control"
                  id="id_body"
                  value={values.body}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {touched.body && errors.body && <span className="help-block">{errors.body}</span>}
              </div>
              <RecaptchaWrapper
                sitekey="6LcFD5UUAAAAAKZPvSKXCBOVeDBkkuFURfict47n"
                onChange={this.onRecaptchaChange}
              />
              <div className="form-group">
                <button className="btn btn-primary col-xs-12" type="submit" disabled={isSubmitting}>
                  <i className="fa fa-send icon-space-r" />
                  Send
                </button>
              </div>
            </form>
          )}
        </Formik>
      </React.Fragment>
    );
  }
}
