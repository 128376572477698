import {
  EVENTS_EXPLORE_DETAIL_UPDATE,
  EXPLORE_FILTER,
  FETCH_EVENTS_EXPLORE,
  SYNC_EXPLORE_FILTERS,
} from 'store/actions/events/explore';

import { defaultFilterOptions } from 'components/events/helpers';

const initialState = {
  events: [],
  isLoading: true,
  hasErrored: false,
  eventScope: '',
  filteredEvents: [],
  options: defaultFilterOptions,
  searchTerms: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EVENTS_EXPLORE.PENDING:
      return { ...state, isLoading: action.bool };
    case FETCH_EVENTS_EXPLORE.SUCCESS:
      return { ...state, events: action.data, filteredEvents: action.data, isLoading: false };
    case FETCH_EVENTS_EXPLORE.ERROR:
      return { ...state, hasErrored: action.bool, isLoading: false };
    case EVENTS_EXPLORE_DETAIL_UPDATE:
      if (state.events.length === 0) {
        // Should not assume updated event belongs in event explore list.
        // The default behavior below only updates existing events
        return state;
      }
      return {
        ...state,
        events: state.events.map((event) => {
          if (event.pk === action.event.pk) {
            return action.event;
          }
          return event;
        }),
        filteredEvents: state.filteredEvents.map((event) => {
          if (event.pk === action.event.pk) {
            return action.event;
          }
          return event;
        }),
      };
    case SYNC_EXPLORE_FILTERS:
      return {
        ...state,
        filteredEvents: action.events,
        options: action.options,
        searchTerms: action.searchTerms,
      };
    case EXPLORE_FILTER:
      return { ...state, eventScope: action.filter };
    default:
      return state;
  }
};
