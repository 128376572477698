import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { navPillPropTypes } from 'helpers/proptypes';

const NavPills = (props) => {
  const className = classNames('nav nav-pills', {
    'nav-stacked': props.stacked,
    'nav-justified': props.justified,
  });
  return (
    <div>
      <ul className={className}>
        {props.pills.map((pill, index) => {
          const active = window.location.pathname === pill.url;
          return (
            <li key={index} className={active ? 'active' : ''}>
              <Link to={pill.url}>{pill.text}</Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

NavPills.propTypes = {
  pills: navPillPropTypes,
  stacked: PropTypes.bool,
  justified: PropTypes.bool,
};

export default NavPills;
