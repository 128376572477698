import React from 'react';
import Fuse from 'fuse.js';
import { PageHeader } from 'components';
import { ResourcesPage } from './ResourcesPage';

export class FAQ extends React.Component {
  state = {
    search: '',
  };

  fuse = null;

  handleChange = (e) => {
    this.setState({ search: e.target.value }, this.updateSearch);
  };

  updateSearch = () => {
    const { search } = this.state;
    if (search.length === 0) {
      document.querySelectorAll('.search-item').forEach((node) => (node.style.display = 'block'));
      document.querySelector('.no-results').style.display = 'none';
      return;
    }
    let indices = this.fuse.search(search);
    document.querySelectorAll('.search-item').forEach((node, i) => {
      if (indices.includes(i)) {
        node.style.display = 'block';
      } else {
        node.style.display = 'none';
      }
    });
    if (indices.length === 0) {
      return (document.querySelector('.no-results').style.display = 'block');
    } else {
      return (document.querySelector('.no-results').style.display = 'none');
    }
  };

  toggleItem = (e) => {
    const isActive = e.target.classList.contains('active');
    if (isActive) {
      e.target.classList.remove('active');
      e.target.parentElement.classList.remove('active');
    } else {
      e.target.classList.add('active');
      e.target.parentElement.classList.add('active');
    }
  };

  componentDidMount() {
    let items = [...document.querySelectorAll('a.search-text')]
      .map((item) => (item && item.innerText ? item.innerText.trim() : false))
      .filter(Boolean);
    this.fuse = new Fuse(items, { threshold: 0.3 });
  }

  render() {
    return (
      <ResourcesPage>
        <div className="row search-container">
          <div className="col-xs-12">
            <div className="white-soft-box2 white-soft-box2--no-animate">
              <PageHeader.Basic center title="Frequently Asked Questions" />
              <div className="form-group">
                <div className="input-group col-xs-10 col-xs-offset-1 col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3">
                  <input
                    type="text"
                    id="search"
                    value={this.state.search}
                    onChange={this.handleChange}
                    className="form-control input-lg search-input"
                    placeholder="Search (e.g. What is a PDC?)"
                    name="query"
                    autoComplete="off"
                    autoFocus
                  />
                  <label className="sr-only" htmlFor="search">
                    Search FAQs
                  </label>
                  <div className="input-group-addon">
                    <i className="fa fa-search" />
                  </div>
                </div>
              </div>
              <div className="no-results text-center" styles={{ display: 'none' }}>
                <h3>
                  <i>No results</i>
                </h3>
              </div>
              <ul className="nav accordion">
                <h3 className="page-header welcome">General FAQ</h3>
                <li className="search-item">
                  <a className="search-text" onClick={this.toggleItem}>
                    <i className="fa fa-fw fa-angle-right" />
                    <i className="fa fa-fw fa-angle-down" />
                    What are the PD attendence and participation policies?
                  </a>
                  <div>
                    <p>
                      These protocols apply to all members of the DAS staff (including
                      apprenticeship interpreters and students) wishing to participate in our
                      program. They are in place to ensure the quality of our Professional
                      Development Program.
                    </p>
                    <strong>DAS PDP Attendance Policy</strong>
                    <div>
                      <ul>
                        <li>
                          Attendance at any registered PD event is mandatory unless previously
                          excused with valid reason
                          <ul>
                            <li>
                              This includes PDCs, workshops, and formally established small group
                              pdc's
                            </li>
                          </ul>
                        </li>
                        <li>
                          Attendance is reported to the managers upon request for PDCs and workshops
                        </li>
                        <li>
                          Anyone wishing to receive RID CEUs will only be excused from one PDC or
                          small group pdc session (with prior notification) and still be awarded
                          CEUs
                          <ul>
                            <li>
                              Individuals missing 1 PDC session will still sign an Activity Report
                              form
                            </li>
                          </ul>
                        </li>
                        <li>
                          Anyone missing more than one PDC or small group pdc session whether
                          excused or not will not be eligible to receive CEUs
                        </li>
                        <li>
                          Small group pdc's can arrange to make up time lost due to one or more
                          member's inability to attend with valid reason
                          <ul>
                            <li>
                              This make up session must be agreed upon by all group members and be
                              reflected on the attendance sheet
                            </li>
                          </ul>
                        </li>
                        <li>
                          Workshop attendance will continue to be monitored and CEU credit will only
                          be awarded to those present for the entire duration of the event
                        </li>
                        <li>
                          Anyone wishing to withdraw from a PDP event prior to or during the event
                          can do so with manager approval
                          <ul>
                            <li>
                              Intent to withdraw should be communicated via email to the event
                              facilitator, your manager, coordinator and the PDP manager.
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                    <strong>DAS PDP Participation Guidelines:</strong>
                    <p>
                      Professional Development activities are designed with you in mind. Our program
                      offers DAS staff the opportunity to devote time during their workday to focus
                      on developing the skills and content/cultural knowledge necessary to provide
                      the high level of service we are committed to providing to the institute and
                      to one another.
                    </p>
                    <strong>
                      Those wishing to engage in professional development activities offered through
                      the PDP agree to:
                    </strong>
                    <div>
                      <ul>
                        <li>
                          Prior to the PD (workshop, PDC, small group pdc) event:
                          <ul>
                            <li>
                              Discuss with their manager the PDP offerings and how they might serve
                              to fulfill expectations for professional growth
                            </li>
                            <li>
                              Attend to registration timelines and register in a timely manner
                            </li>
                            <li>
                              Communicate with their coordinators to ensure their availability for
                              the duration of the event
                            </li>
                            <li>
                              Be sure you are clear on the learning objectives outlined for any
                              event you register for
                            </li>
                            <li>Complete any preparatory work assigned by the facilitator</li>
                          </ul>
                        </li>
                        <li>
                          During the PD (workshop, PDC, small group pdc) event:
                          <ul>
                            <li>Arrive on time for the event</li>
                            <li>Sign in</li>
                            <li>
                              Be present and ready to fully participate in all activities and
                              discussions
                            </li>
                            <li>Have completed any "homework" assigned by the facilitator(s)</li>
                            <li>
                              Silence cell phones and put away all digital devices not in use for
                              the actual PD event
                            </li>
                            <li>No sleeping</li>
                            <li>Complete evaluation for every event</li>
                            <li>
                              CEUs and RID participation certificates will not be given to anyone
                              not completing evaluations
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                    <p>
                      <strong>Special Note: </strong>If you have committed to a PD event, but you
                      are unable to attend the event (workshop, PDC, small group pdc) for any
                      reason, you must contact the event facilitator(s), your manager, and the PDP
                      manager prior to the event. Attendance is reported to managers following every
                      event.{' '}
                      <strong>
                        Please see the DAS PDP Attendance Policy for more information.
                      </strong>
                    </p>
                  </div>
                </li>
                <li className="search-item">
                  <a className="search-text" onClick={this.toggleItem}>
                    <i className="fa fa-fw fa-angle-right" />
                    <i className="fa fa-fw fa-angle-down" />
                    How do I develop a PD plan?
                  </a>
                  <div>
                    <p>
                      The very best way to begin to develop a PD plan is to talk with your manager.
                      Ideally, the process would start with a thorough review of your work including
                      interpreting skills, content knowledge, cultural, ethical and professional
                      considerations and your general career goals.
                    </p>
                    <p>
                      Discuss with your manager the best process for accomplishing the goals you
                      establish (working with a mentor, completing observations, studying a
                      particular content area, participating in OR leading of an PDC, workshop or
                      small group, etc.). The PDP manager can help you find the right activities as
                      well as provide suggestion/guidance on how to document your progress for
                      appraisal purposes.
                    </p>
                    <p>
                      Your plan may be well addressed with workshop and PDC offerings; if not, you
                      may wish to establish a small group pdc or investigate other creative avenues.
                    </p>
                    <p>
                      Talk with the PDP manager! There are a number of ways to address professional
                      development goals and to ensure that your plan is documented for review and
                      appraisal within DAS and the RID CMP.
                    </p>
                  </div>
                </li>
                <li className="search-item">
                  <a className="search-text" onClick={this.toggleItem}>
                    <i className="fa fa-fw fa-angle-right" />
                    <i className="fa fa-fw fa-angle-down" />
                    What is a small group pdc?
                  </a>
                  <div>
                    <p>
                      Small group pdc's are self selected groups of up to 6 people with specific
                      interest in a particular topic or skill set. They are typically 7-15 weeks in
                      duration during the regular academic semester or for a few sessions during
                      exam/break weeks.
                    </p>
                  </div>
                </li>
                <li className="search-item">
                  <a className="search-text" onClick={this.toggleItem}>
                    <i className="fa fa-fw fa-angle-right" />
                    <i className="fa fa-fw fa-angle-down" />
                    How do I put together a small group pdc?
                  </a>
                  <div>
                    <p>
                      Small group pdc's are a great way to address a very specific professional
                      development need. For those individuals needing/preferring a more intimate and
                      focused learning experience the small group pdc is a perfect choice. Small
                      groups are generally comprised of 3-5 (maximum of 6) members. All members are
                      self-selected or invited by the person establishing the group. The group
                      members work together to establish goals and learning objectives, activities
                      and timelines. Anyone wishing to establish a formal small group pdc should
                      alert the PDP manager to ensure that learning objectives, participants,
                      activities, and timelines are properly documented. Members wishing to receive
                      CEU credits will need to complete additional paperwork.
                    </p>
                    <p>
                      <strong>
                        All staff wishing to participate in a small group pdc must discuss the
                        option with their managers and work with their coordinators to choose the
                        best possible time to meet.
                      </strong>
                    </p>
                  </div>
                </li>
                <li className="search-item">
                  <a className="search-text" onClick={this.toggleItem}>
                    <i className="fa fa-fw fa-angle-right" />
                    <i className="fa fa-fw fa-angle-down" />
                    What is a Workshop?
                  </a>
                  <div>
                    <p>
                      Principally, workshops are an exposure mechanism to current best practices,
                      content, interpreting and linguistic theory, and skills focused activities,
                      helpful/assistive technology, and cultural and professional and ethical
                      topics.
                    </p>
                    <p>
                      Workshops are typically 2-6 hours in duration and they are offered during
                      semester/holiday break.
                    </p>
                  </div>
                </li>
                <li className="search-item">
                  <a className="search-text" onClick={this.toggleItem}>
                    <i className="fa fa-fw fa-angle-right" />
                    <i className="fa fa-fw fa-angle-down" />
                    How do I develop a workshop?
                  </a>
                  <div>
                    <p>
                      As with anything, workshops start out as ideas! If you have an idea for a
                      workshop think about the when, the duration, what you will teach and how you
                      will teach it. Then bring your idea to the PDP manager.
                    </p>
                    <div>
                      <ul>
                        <li>
                          When would you like to present?
                          <ul>
                            <li>
                              Have several dates and times in mind in case your first choice isn't
                              available
                            </li>
                          </ul>
                        </li>
                        <li>
                          Duration of your workshop?
                          <ul>
                            <li>
                              Consider how much material you have, activities you want to do and how
                              long you will be able to engage your participants
                            </li>
                          </ul>
                        </li>
                        <li>
                          What are the educational objectives for the workshop?
                          <ul>
                            <li>
                              What is it you want to teach people? The PDP manager can help you
                              write appropriate educational objectives.
                            </li>
                          </ul>
                        </li>
                        <li>
                          How will you accomplish the learning objectives?
                          <ul>
                            <li>How will you teach what you want folks to know?</li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                    <p>
                      <strong>
                        Be sure your manager is on board with having you present! Then, make and
                        appointment with the PDP manager and you are on your way!
                      </strong>
                    </p>
                  </div>
                </li>
                <li className="search-item">
                  <a className="search-text" onClick={this.toggleItem}>
                    <i className="fa fa-fw fa-angle-right" />
                    <i className="fa fa-fw fa-angle-down" />
                    Where can I find out information about upcoming workshops?
                  </a>
                  <div>
                    <p>
                      Please go to the <a href={window.URLS['pd:calendar']()}>calendar section</a>{' '}
                      and select a workshop for more info.
                    </p>
                  </div>
                </li>
                <li className="search-item">
                  <a className="search-text" onClick={this.toggleItem}>
                    <i className="fa fa-fw fa-angle-right" />
                    <i className="fa fa-fw fa-angle-down" />
                    What is an PDC?
                  </a>
                  <div>
                    <p>
                      PDC stands for Professional Development Course. PDCs offer more in-depth work
                      with current best practices, content, interpreting and linguistic theory, and
                      skills focused activities and cultural, professional and ethical topics. PDCs
                      are typically 15 weeks in duration during the regular academic semester.
                    </p>
                  </div>
                </li>
                <li className="search-item">
                  <a className="search-text" onClick={this.toggleItem}>
                    <i className="fa fa-fw fa-angle-right" />
                    <i className="fa fa-fw fa-angle-down" />
                    How do I develop an PDC?
                  </a>
                  <div>
                    <p>
                      Developing an PDC is very similar to developing a workshop. In addition to the
                      what, when, and how questions, you need to determine how to pace and parcel
                      your material so that there is a logical flow for your participants week to
                      week.
                    </p>
                    <div>
                      <ul>
                        <li>
                          When do you want to present? What semester and what time of day?
                          <ul>
                            <li>PDCs can be scheduled for any day of the week.</li>
                          </ul>
                        </li>
                        <li>
                          Duration of your PDC
                          <ul>
                            <li>For 1 hour/week or 2 hours/week?</li>
                          </ul>
                        </li>
                        <li>What are the educational objectives?</li>
                        <li>How will you accomplish the educational objectives?</li>
                      </ul>
                    </div>
                    <p>
                      <strong>
                        Be sure your manager is on board with having you present! Then, make and
                        appointment with the PDP manager and you are on your way!
                      </strong>
                    </p>
                  </div>
                </li>
                <li className="search-item">
                  <a className="search-text" onClick={this.toggleItem}>
                    <i className="fa fa-fw fa-angle-right" />
                    <i className="fa fa-fw fa-angle-down" />
                    What are Student/Intern/Apprentice colloquiums?
                  </a>
                  <div>
                    <p>
                      A colloquium is a forum for the newest members of the profession and
                      department. The group meets weekly during the semester to discuss issues and
                      learn about resources and strategies and to discuss best practices and current
                      topics in the field.
                    </p>
                  </div>
                </li>
                <li className="search-item">
                  <a className="search-text" onClick={this.toggleItem}>
                    <i className="fa fa-fw fa-angle-right" />
                    <i className="fa fa-fw fa-angle-down" />
                    How do I determine which PD events to attend?
                  </a>
                  <div>
                    <p>
                      Consider your goals for the year! Are you wanting or needing to focus on a
                      specific skill set? Are you in need of content knowledge in a specific area?
                      Talk with your manager to see what they say about the types of PD you should
                      engage in.
                    </p>
                    <p>
                      Come and talk with the PDP manager to discuss how the PD events offered might
                      fit in to your plan or if there might be other avenues for you to pursue.
                    </p>
                  </div>
                </li>
                <li className="search-item">
                  <a className="search-text" onClick={this.toggleItem}>
                    <i className="fa fa-fw fa-angle-right" />
                    <i className="fa fa-fw fa-angle-down" />
                    Who can participate in DAS PD events?
                  </a>
                  <div>
                    <p>
                      Anyone employed by the DAS has the opportunity to take advantage of our PD
                      events: Interpreters, Captionists, student interpreters, Apprenticeship
                      Interpreters, Staff Assistants, Coordinators, and Managers.
                    </p>
                    <p>
                      Please read the event descriptions carefully. Information about the level of
                      instruction and whom the event is most appropriate for will be indicated
                      there.
                    </p>
                  </div>
                </li>
                <li className="search-item">
                  <a className="search-text" onClick={this.toggleItem}>
                    <i className="fa fa-fw fa-angle-right" />
                    <i className="fa fa-fw fa-angle-down" />
                    What's the difference between professional studies and general studies CEUs?
                  </a>
                  <div>
                    <p>The following is taken from RID Standards and Criteria Manual:</p>
                    <p>
                      The content Areas in which Interpreters/Transliterators can receive Continuing
                      Education Units (CEUs) are divided into two categories: Professional Studies
                      (linguistic and cultural studies; theoretical and experiential studies;
                      specialization studies) and General Studies (human service and leadership;
                      general knowledge studies). In determining the content Areas, the RID PDC
                      considered the recommended standards in the curriculum section of the
                      Conference of Interpreter Trainers' (CIT) Educational Standards Package.
                    </p>
                    <div>
                      <ol>
                        <li>
                          <strong>Professional Studies</strong> contain content that directly
                          affects the field of interpreting/ transliterating. A minimum of 7.0 CEUs
                          must be completed in this section during each Certification Maintenance
                          Cycle. Examples of Professional Studies topics include, but are not
                          limited to:
                          <ol>
                            <li>
                              Linguistic and Cultural Studies: The study of any language or
                              linguistic system, as well as general cultural studies, and the study
                              of any specific culture. Examples include:
                              <ul>
                                <li>Advanced English vocabulary and grammar development</li>
                                <li>Linguistics of ASL</li>
                                <li>Linguistics of English</li>
                                <li>Language variation</li>
                                <li>Sociolinguistics</li>
                                <li>Deaf culture</li>
                                <li>American culture</li>
                                <li>Minority group dynamics</li>
                                <li>Cross-cultural studies</li>
                                <li>Multi-cultural studies, etc.</li>
                              </ul>
                            </li>
                            <li>
                              Theoretical and Experiential Studies: The process of
                              interpreting/transliterating through the application of systems of
                              principles, philosophy, ideas or concepts. Examples include:
                              <ul>
                                <li>History and issues in interpretation and transliteration</li>
                                <li>Theory of interpretation and transliteration</li>
                                <li>Skills development in interpretation and/or transliteration</li>
                                <li>
                                  Skills development in consecutive and simultaneous interpretation
                                </li>
                                <li>Professional ethics, etc.</li>
                              </ul>
                            </li>
                            <li>
                              Specialization Studies: Building skills in more narrowly focused areas
                              within the broader field of interpreting or transliterating.
                              Documentation must be present detailing the way in which the activity
                              relates to the development of interpreting skills in that particular
                              field or setting. Examples include aspects of:
                              <ul>
                                <li>Educational settings</li>
                                <li>Rehabilitation settings</li>
                                <li>Legal settings</li>
                                <li>Medical or mental health settings</li>
                                <li>Substance abuse recovery programs</li>
                                <li>Technical areas</li>
                                <li>Ethics as applied in specialized settings, etc.</li>
                              </ul>
                            </li>
                          </ol>
                        </li>
                        <li>
                          <strong>General Studies</strong> include topics that enhance the
                          interpreter/transliterator’s general knowledge base.There are no minimum
                          requirements for CEUs in this category, however, only two (2) of the eight
                          (8) required CEUs will be counted toward certification maintenance.
                        </li>
                      </ol>
                    </div>
                  </div>
                </li>
                <br />
                <h3 className="page-header welcome">Seminar Series FAQ</h3>
                <li className="search-item">
                  <a className="search-text" onClick={this.toggleItem}>
                    <i className="fa fa-fw fa-angle-right" />
                    <i className="fa fa-fw fa-angle-down" />
                    What is the DAS Seminar Series?
                  </a>
                  <div>
                    <p>
                      The DAS Seminar Series is our response to a staff request that PD be offered
                      at times other than Fridays. Now, we are offering weekly 50-minute “mini PD”
                      opportunities. The 50-minute sessions will be scheduled at a variety of times
                      and on different days.
                    </p>
                    <p>
                      <strong>50 Minutes/ That's not enough time!</strong>
                    </p>
                    <p>
                      These sessions will be short and sweet! They will start on the hour and finish
                      for folks to get to their next assignment.
                    </p>
                    <div>
                      <ul>
                        <li>20-30 minutes for lecture or intro of topic</li>
                        <li>15-25 minutes for discussion and questions</li>
                        <li>5 minutes for evaluation and you are out the door!</li>
                      </ul>
                    </div>
                  </div>
                </li>
                <li className="search-item">
                  <a className="search-text" onClick={this.toggleItem}>
                    <i className="fa fa-fw fa-angle-right" />
                    <i className="fa fa-fw fa-angle-down" />
                    Who presents the Seminars?
                  </a>
                  <div>
                    <p>
                      <strong>Anyone on the DAS staff can present!</strong>
                    </p>
                    <p>
                      Interpreters, Captionists, Staff Assistants, Schedulers, Coordinators,
                      Managers Directors, Deans, Apprentices, Students… ANYONE! We ALL have
                      something to offer!
                    </p>
                  </div>
                </li>
                <li className="search-item">
                  <a className="search-text" onClick={this.toggleItem}>
                    <i className="fa fa-fw fa-angle-right" />
                    <i className="fa fa-fw fa-angle-down" />
                    What topics can be/are offered?
                  </a>
                  <div>
                    <p>
                      Use your imagination but keep it current, and related to the field of Access
                      Services.
                    </p>
                    <div>
                      <ul>
                        <li>Present your research</li>
                        <li>Content-focused discussion related to your area</li>
                        <li>Best Practices in interpreting, captioning, note taking, etc.</li>
                        <li>Wellness topics/activities</li>
                        <li>Your favorite interpreting model</li>
                        <li>Great apps or websites you have found that help you prep</li>
                        <li>Department information</li>
                        <li> Professional ethics, dress, etc.</li>
                        <li>How to write the “perfect” confirmation email</li>
                        <li>How to manage your email for optimal success</li>
                        <li>What’s the sign for 'x'?</li>
                      </ul>
                    </div>
                  </div>
                </li>
                <li className="search-item">
                  <a className="search-text" onClick={this.toggleItem}>
                    <i className="fa fa-fw fa-angle-right" />
                    <i className="fa fa-fw fa-angle-down" />
                    Who can attend?
                  </a>
                  <div>
                    <p>
                      Anyone available at the time of the presentation! Seminars topics will be
                      solicited week 1 and are due on Wednesday of week 3, scheduling will occur
                      week 4 and the seminars will be held weeks 5-15. No seminars will be offered
                      during finals week.
                    </p>
                    <p>ALL sessions will be digitally recorded for later viewing!</p>
                  </div>
                </li>
                <li className="search-item">
                  <a className="search-text" onClick={this.toggleItem}>
                    <i className="fa fa-fw fa-angle-right" />
                    <i className="fa fa-fw fa-angle-down" />
                    Will CEUs be available?
                  </a>
                  <div>
                    <p>
                      For SOME seminars, CEUs are available, but not for all. It depends on the
                      topic, and if there are clear educational objectives defined by the presenter.
                      No matter what, these offer a good chance to learn something!
                    </p>
                    <p>
                      CEUs will be awarded using the RID PINRA form. Certified interpreters wanting
                      CEU credit will need to fill out a PINRA, PINRA Proof of Participation (acts
                      as your certificate) and an evaluation.
                    </p>
                  </div>
                </li>
                <li className="search-item">
                  <a className="search-text" onClick={this.toggleItem}>
                    <i className="fa fa-fw fa-angle-right" />
                    <i className="fa fa-fw fa-angle-down" />
                    How will I know when the seminar is happening?
                  </a>
                  <div>
                    <p>
                      The seminars will be announced on the PDP Website Calendar and will run weeks
                      4-15 during the academic year. Check the calendar for date, time and location!
                    </p>
                    <p>
                      Look for email blasts! Check out the posters hung in the Mail Room and on the
                      PDP bulletin Board.
                    </p>
                  </div>
                </li>
                <li className="search-item">
                  <a className="search-text" onClick={this.toggleItem}>
                    <i className="fa fa-fw fa-angle-right" />
                    <i className="fa fa-fw fa-angle-down" />I want to present! How do I get on the
                    calendar?
                  </a>
                  <div>
                    <p>
                      The call for proposals will be sent Friday of week 1. You must fill out the
                      form in its entirety for each topic you would like to present and include the
                      following information:
                    </p>
                    <div>
                      <ul>
                        <li>Title of your presentation</li>
                        <li>2-3 sentence description of your topic for the advertisement</li>
                        <li>A brief abstract (200 word max) and educational objectives</li>
                        <li>
                          Days and times you are available to present (specific dates will be
                          honored if possible)
                        </li>
                        <li>Any special equipment and/or room requirements</li>
                        <li>
                          Professional Bio (short paragraph about you, your experience, degrees,
                          certifications, professional interests)
                        </li>
                        <li>
                          You will receive a confirmation email by the end of week four (4) with
                          your presentation day/date and time.
                        </li>
                      </ul>
                    </div>
                    <p>
                      You will receive a confirmation email by the end of week four (4) with your
                      presentation day/date and time.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </ResourcesPage>
    );
  }
}
