import PropTypes from 'prop-types';

import { EventDictionary } from 'helpers/dictionaries';
import {
  eventTypePropType,
  eventStrandPropType,
  eventRegistrationStatusPropType,
  eventPublicationStatusPropType,
} from 'components/events/proptypes';

export const loadingPropTypes = PropTypes.shape({
  isLoading: PropTypes.bool.isRequired,
});

export const errorPropTypes = PropTypes.shape({
  hasErrored: PropTypes.bool.isRequired,
});

export const loadingOrErrorPropTypes = PropTypes.oneOfType([loadingPropTypes, errorPropTypes])
  .isRequired;

function loadingOrErrorOrShapePropType(normalShape) {
  return PropTypes.oneOfType([loadingPropTypes, errorPropTypes, PropTypes.shape(normalShape)]);
}

export const recordPropTypes = PropTypes.shape({
  id: PropTypes.number.isRequired,
  attendanceLog: PropTypes.shape({
    id: PropTypes.number.isRequired,
    author: PropTypes.number.isRequired,
    editor: PropTypes.number,
    event: PropTypes.number.isRequired,
    created: PropTypes.string.isRequired,
    modified: PropTypes.string,
    date: PropTypes.string.isRequired,
  }),
  status: PropTypes.string.isRequired,
  attendee: PropTypes.number.isRequired,
});

export const resultPropTypes = PropTypes.shape({
  author: PropTypes.number.isRequired,
  final: PropTypes.bool.isRequired,
  ip: PropTypes.string,
  email: PropTypes.string,
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  duration: PropTypes.string,
  started: PropTypes.string,
  completed: PropTypes.string,
  survey: PropTypes.number,
  created: PropTypes.string.isRequired,
  modified: PropTypes.string,
});

export const questionPropTypes = loadingOrErrorOrShapePropType({
  id: PropTypes.number.isRequired,
  number: PropTypes.number.isRequired,
  question_text: PropTypes.string.isRequired,
  question_type: PropTypes.string.isRequired,
  question_block: PropTypes.number,
  isLoading: PropTypes.bool,
  hasErrored: PropTypes.bool,
});

export const questionBlockPropTypes = loadingOrErrorOrShapePropType({
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  intro: PropTypes.string,
  order_id: PropTypes.number,
  survey: PropTypes.number,
  questions: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.number, questionPropTypes, loadingOrErrorPropTypes]).isRequired
  ),
  isLoading: PropTypes.bool,
  hasErrored: PropTypes.bool,
});

export const surveyPropTypes = loadingOrErrorOrShapePropType({
  id: PropTypes.number.isRequired,
  author: PropTypes.number.isRequired,
  editor: PropTypes.number,
  created: PropTypes.string.isRequired,
  modified: PropTypes.string,
  event: PropTypes.object,
  name: PropTypes.string.isRequired,
  publication_status: PropTypes.oneOf(Object.values(EventDictionary.PUB_STATUS)).isRequired,
  question_blocks: PropTypes.arrayOf(questionBlockPropTypes),
  blocks: PropTypes.arrayOf(questionBlockPropTypes),
  isLoading: PropTypes.bool,
  hasErrored: PropTypes.bool,
  start: PropTypes.string,
  end: PropTypes.string,
});

export const answerPropTypes = loadingOrErrorOrShapePropType({
  id: PropTypes.number.isRequired,
  author: PropTypes.number.isRequired,
  editor: PropTypes.number,
  created: PropTypes.string.isRequired,
  modified: PropTypes.string,
  question: PropTypes.number.isRequired,
  response: PropTypes.string,
  survey: PropTypes.number.isRequired,
  isLoading: PropTypes.bool,
  hasErrored: PropTypes.bool,
});

export const errorPropType = PropTypes.oneOfType([PropTypes.object, PropTypes.bool]);

export const registrationPropTypes = PropTypes.shape({
  pk: PropTypes.number.isRequired,
  status: PropTypes.oneOf(Object.values(EventDictionary.REG_STATUS)).isRequired,
  event: PropTypes.object.isRequired,
  reason: PropTypes.string,
  modified: PropTypes.string,
});

export const registrationDetailPropTypes = PropTypes.shape({
  id: PropTypes.number.isRequired,
  status: PropTypes.oneOf(Object.values(EventDictionary.REG_STATUS)).isRequired,
  user: PropTypes.number.isRequired,
  event: PropTypes.number.isRequired,
  created: PropTypes.string.isRequired,
  modified: PropTypes.string,
  reason: PropTypes.string,
  withdraw_reason: PropTypes.string,
  name: PropTypes.shape({
    rit_username: PropTypes.string.isRequired,
    first: PropTypes.string,
    last: PropTypes.string,
    attendance: PropTypes.string,
    manager: PropTypes.string,
  }).isRequired,
});

export const eventRegistrationPropTypes = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  status: PropTypes.oneOf(Object.values(EventDictionary.REG_STATUS)),
  event: PropTypes.number.isRequired,
  user: PropTypes.number.isRequired,
});

export const eventExplorePropTypes = PropTypes.shape({
  // Props that both explore and admin events have (required)
  pk: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  type: eventTypePropType.isRequired,
  strand_type: eventStrandPropType,
  times: PropTypes.arrayOf(PropTypes.array),
  ceu_val: PropTypes.number.isRequired,
  ceu_type: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  canceled: PropTypes.bool.isRequired,
  cancelation_reason: PropTypes.string,
  private: PropTypes.bool.isRequired,
  can_drop: PropTypes.bool.isRequired,
  can_withdraw: PropTypes.bool.isRequired,

  // Props that may appear in one but not the other (not required)
  registration_status: eventRegistrationStatusPropType,
  publication_status: eventPublicationStatusPropType,
  registration_start: PropTypes.string,
  pending_requests: PropTypes.number,
  seats_open: PropTypes.number,
});

export const eventDetailPropTypes = PropTypes.shape({
  id: PropTypes.number.isRequired,
  publication_status: PropTypes.oneOf(Object.values(EventDictionary.PUB_STATUS)).isRequired,
  created: PropTypes.string.isRequired,
  modified: PropTypes.string,
  strand_type: PropTypes.oneOf(Object.values(EventDictionary.STRAND_TYPES)).isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  learning_type: PropTypes.oneOf(Object.values(EventDictionary.LEARNING_TYPES)).isRequired,
  type: PropTypes.oneOf(Object.values(EventDictionary.EVENT_TYPES)).isRequired,
  employee_type: PropTypes.arrayOf(PropTypes.oneOf(Object.values(EventDictionary.EMPLOYEE_TYPES))),
  ceu_type: PropTypes.oneOf(Object.values(EventDictionary.CEU_TYPES)).isRequired,
  ceu_val: PropTypes.number,
  registration_start: PropTypes.string.isRequired,
  registration_end: PropTypes.string.isRequired,
  guest_speaker: PropTypes.string,
  instruction_level: PropTypes.oneOf(Object.values(EventDictionary.INSTRUCTION_LEVELS)).isRequired,
  credit_approval: PropTypes.oneOf(Object.values(EventDictionary.CREDIT_TYPES)).isRequired,
  capacity: PropTypes.number.isRequired,
  target_audience: PropTypes.string,
  educational_objectives: PropTypes.string,
  media_materials: PropTypes.string,
  assessment: PropTypes.string,
  facilitator_availability: PropTypes.string,
  facilitator_notes: PropTypes.string,
  private: PropTypes.bool.isRequired,
  canceled: PropTypes.bool.isRequired,
  cancelation_reason: PropTypes.string,
  rid_activity_number: PropTypes.string,
  workshop_id: PropTypes.string,
  survey: PropTypes.string,
  first: PropTypes.string,
  last: PropTypes.string,
  facilitators: PropTypes.arrayOf(PropTypes.number),
  registrations: PropTypes.arrayOf(eventRegistrationPropTypes),
  facilitator_requesting_ceus: PropTypes.bool.isRequired,
});

export const profilePropTypes = PropTypes.shape({
  pk: PropTypes.number.isRequired,
  manager: PropTypes.number,
  title: PropTypes.string.isRequired,
  office: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  avatar: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  employee_type: PropTypes.oneOf(Object.values(EventDictionary.EMPLOYEE_TYPES)),
  ceu_tracking: PropTypes.string.isRequired,
  joined: PropTypes.string.isRequired,
});

export const ridprofilePropTypes = PropTypes.shape({
  first_name: PropTypes.string.isRequired,
  last_name: PropTypes.string.isRequired,
  rid_account_number: PropTypes.number,
});

export const userPropTypes = PropTypes.shape({
  pk: PropTypes.number.isRequired,
  email: PropTypes.string.isRequired,
  groups: PropTypes.arrayOf(
    PropTypes.shape({ pk: PropTypes.number.isRequired, name: PropTypes.string.isRequired })
  ).isRequired,
  shoppingcart: PropTypes.shape({
    owner: PropTypes.number.isRequired,
    events: PropTypes.arrayOf(PropTypes.number).isRequired,
  }),
  profile: profilePropTypes.isRequired,
  ridprofile: ridprofilePropTypes,
  name: PropTypes.string,
});

export const breadcrumbPropTypes = PropTypes.shape({
  active: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  url: PropTypes.string,
  params: PropTypes.object,
  router: PropTypes.bool,
});

export const navPillPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    active: PropTypes.bool,
    text: PropTypes.string.isRequired,
    url: PropTypes.string,
  })
).isRequired;

export const carouselPropTypes = PropTypes.shape({
  id: PropTypes.number.isRequired,
  created: PropTypes.string.isRequired,
  modified: PropTypes.string,
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  button_text: PropTypes.string,
  button_link: PropTypes.string,
  background: PropTypes.string,
  active: PropTypes.bool.isRequired,
  author: PropTypes.number,
  editor: PropTypes.number,
});

export const publicProfilePropTypes = PropTypes.shape({
  pk: PropTypes.number.isRequired,
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  avatar: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  title: PropTypes.string,
  about: PropTypes.string,
  office: PropTypes.string,
  phone: PropTypes.string,
  employee_type: PropTypes.string,
  user: PropTypes.shape({
    pk: PropTypes.number.isRequired,
    email: PropTypes.string.isRequired,
    rit_username: PropTypes.string.isRequired,
  }),
  manager: PropTypes.shape({
    pk: PropTypes.number.isRequired,
    email: PropTypes.string.isRequired,
    rit_username: PropTypes.string.isRequired,
    profile: PropTypes.shape({
      pk: PropTypes.number.isRequired,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      avatar: PropTypes.string,
      slug: PropTypes.string.isRequired,
      title: PropTypes.string,
      about: PropTypes.string,
      office: PropTypes.string,
      phone: PropTypes.string,
      employee_type: PropTypes.string,
      manager: PropTypes.number,
    }),
  }),
});
