import Loadable from 'react-loadable';
import { Loading } from 'components';

const AsyncAttendanceTrackerPage = Loadable({
  loader: () => import('./attendance'),
  loading: Loading,
});

const AsyncFacilitatorWrapUpPage = Loadable({
  loader: () => import('./wrap-up'),
  loading: Loading,
});

const AsyncInvitationsPage = Loadable({
  loader: () => import('./proposals'),
  loading: Loading,
});

const AsyncSurveyResultsPage = Loadable({
  loader: () => import('./survey-results'),
  loading: Loading,
});

export {
  AsyncAttendanceTrackerPage,
  AsyncFacilitatorWrapUpPage,
  AsyncInvitationsPage,
  AsyncSurveyResultsPage,
};
