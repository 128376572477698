import { getCookie } from 'helpers/_getCookie';
import PNotify from 'pnotify';
import queryString from 'query-string';

const API_ROOT = window.location.origin + '/api';
const NOTIFY_API_ROOT = window.location.origin + '/notifications';

const encode = encodeURIComponent;

export const encodeQueryString = (filters) => {
  const queryString = Object.entries(filters)
    .filter((filter) => typeof filter[1] !== 'undefined')
    .map((filter, idx) => {
      if (idx === 0) {
        return `?${encode(filter[0])}=${encode(filter[1])}`;
      }
      return `&${encode(filter[0])}=${encode(filter[1])}`;
    })
    .join('');
  return queryString;
};

export const notifySuccess = (message, title, delay) => {
  PNotify.prototype.options.delay = delay || 3000;
  window.show_stack_topleft(title || 'Success', message, 'success');
};

export const notifyError = (error, message) => {
  PNotify.prototype.options.delay = 5000;
  const genericMessage =
    message || error.message || 'Something went wrong, please try again later.';
  if (error.response && !error.response.bodyUsed) {
    error.response
      .json()
      .then((json) => {
        let msg = Object.values(json).join('\n');
        window.show_stack_topleft('Error', msg, 'error');
        return msg;
      })
      .catch((error) => {
        window.show_stack_topleft('Error', genericMessage, 'error');
        error = new Error(
          `Error response could not be json serialized. The server should return a json serializable error message.`
        );
        throw error;
      });
  } else {
    window.show_stack_topleft('Error', genericMessage, 'error');
  }
  PNotify.prototype.options.delay = 3000;
  return error;
};

export const checkStatus = async (response) => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    const error = new Error(`HTTP Error ${response.statusText} for ${response.url}`);
    error.status = response.statusText;
    error.response = response;
    try {
      error.message = await response.json().then((json) => Object.values(json).join('\n'));
    } catch (error) {
      error.message = 'An error occurred, please try again later.';
    }
    throw error;
  }
};

export const parseJSON = (res) => res.json();

const defaultApiOptions = {
  credentials: 'same-origin',
  headers: {
    Accept: 'application/json',
  },
};

const apiFetch = (url, options) => {
  if (options.body || ['PATCH', 'PUT', 'POST', 'DELETE'].includes(options.method)) {
    options.headers['X-CSRFToken'] = getCookie('csrftoken');
  }
  return fetch(url, options)
    .then(checkStatus)
    .then((res) => (res.status !== 204 ? parseJSON(res) : res));
};

const api = (method, url, body) => {
  const options = {
    ...defaultApiOptions,
    method: method,
  };

  if (body) {
    options.body = JSON.stringify(body);
    options.headers['Content-Type'] = 'application/json';
  }

  return apiFetch(url, options);
};

const apiFormData = (method, url, body) => {
  const options = {
    ...defaultApiOptions,
    method: method,
  };
  options.headers['x-requested-with'] = 'XMLHttpRequest';

  if (body) {
    var formData = new window.FormData();
    for (var key in body) {
      formData.append(key, body[key]);
    }
    options.body = formData;
  }

  return apiFetch(url, options);
};

const requests = {
  get: (url) => api('GET', `${API_ROOT}${url}`),
  patch: (url, body) => api('PATCH', `${API_ROOT}${url}`, body),
  put: (url, body) => api('PUT', `${API_ROOT}${url}`, body),
  post: (url, body) => api('POST', `${API_ROOT}${url}`, body),
  delete: (url, body) => api('DELETE', `${API_ROOT}${url}`, body),
};

const notifyRequests = {
  get: (url) => api('GET', `${NOTIFY_API_ROOT}${url}`),
  post: (url, body) => apiFormData('POST', `${NOTIFY_API_ROOT}${url}`, body),
};

export const Events = {
  list: (fields) => (fields ? requests.get(`/events/?fields=${fields}`) : requests.get(`/events/`)),
  explore: (filter) => requests.get(`/events/explore/?filter=${filter}`),
  exploreDetail: (eventId, token) =>
    requests.get(`/events/explore/${eventId}${token ? `?token=${encode(token)}` : ''}`),
  exploreCounts: () => requests.get(`/events/explore/counts/`),
  detail: (eventId) => requests.get(`/events/${eventId}`),
  admin: (academicyear) => requests.get(`/events/admin/?academicyear=${academicyear}`),
  getIds: () => requests.get('/events/ids/'),
  updateIds: (id, data) => requests.put(`/events/ids/${id}`, data),
  facilitatorWrapUp: () => requests.get('/events/facilitators/wrap-up/'),
  facilitatorWrapUpDetail: (eventId) => requests.get(`/events/${eventId}/facilitators/wrap-up/`),
  initializeGrades: (eventId) => requests.post(`/events/${eventId}/grades/initialize`, {}),
  gradeList: (eventId) => requests.get(`/events/${eventId}/grades/`),
  getMyGrade: (eventId) => requests.get(`/events/${eventId}/grades/me`),
  postGrade: (eventId, data) => requests.post(`/events/${eventId}/grades/`, data),
  patchGrade: (eventId, gradeId, data) =>
    requests.patch(`/events/${eventId}/grades/${gradeId}`, data),
  getAttendanceList: (eventId) => requests.get(`/events/${eventId}/attendance-log`),
  postAttendanceRecord: (eventId, logId, data) =>
    requests.post(`/events/${eventId}/attendance/logs/${logId}/`, data),
  patchAttendanceRecord: (eventId, logId, attendeeId, data) =>
    requests.patch(`/events/${eventId}/attendance/logs/${logId}/records/${attendeeId}`, data),
  postAttendanceLog: (eventId, data) => requests.post(`/events/${eventId}/attendance-log`, data),
  patchProgressReport: (eventId, data) =>
    requests.patch(`/events/${eventId}/progress-report/`, data),
  postProgressReport: (eventId, data) => requests.post(`/events/${eventId}/progress-report/`, data),
  copy: (eventId, data) => requests.post(`/events/${eventId}/copy`, data),
  registrationList: (eventId) => requests.get(`/events/${eventId}/registrations/`),
};

export const AttendanceComments = {
  get: (eventId) => requests.get(`/attendance-comments/?event=${encode(eventId)}`),
  patch: (commentId, data) => requests.patch(`/attendance-comments/${commentId}/`, data),
  put: (commentId, data) => requests.put(`/attendance-comments/${commentId}/`, data),
  post: (data) => requests.post(`/attendance-comments/`, data),
};

export const AttendanceLog = {
  delete: (logId) => requests.delete(`/attendancelogs/${logId}/`, {}),
  records: (userSlug, eventId) => requests.get(`/attendance/${userSlug}/event/${eventId}`),
};

export const Carousels = {
  list: () => requests.get('/carousels/'),
};

export const EventInvitations = {
  get: (eventId, userId, unreadOnly) =>
    requests.get(
      `/event-invitations/${encodeQueryString({
        event: eventId,
        user: userId,
        unread_only: unreadOnly,
      })}`
    ),
  list: () => requests.get('/event-invitations/'),
  patch: (invitetId, data) => requests.patch(`/event-invitations/${invitetId}/`, data),
  put: (invitetId, data) => requests.put(`/event-invitations/${invitetId}/`, data),
  post: (data) => requests.post(`/event-invitations/`, data),
  delete: (inviteId) => requests.delete(`/event-invitations/${inviteId}`, {}),
};

export const EventMessages = {
  list: ({ event, sender, tags }) =>
    requests.get(
      `/event-messages/${encodeQueryString({
        event,
        sender,
        tags: (tags || []).join(','),
      })}`
    ),
};

export const User = {
  current: () => requests.get('/users/current'),
  get: (userId) => requests.get(`/users/${userId}`),
  addToCart: (data) => requests.patch('/shoppingcart/events/', data),
  removeFromCart: (data) => requests.delete('/shoppingcart/events/', data),
  groupList: (group) => requests.get(`/users/groups/${encode(group)}`),
  permissionList: (codename) => requests.get(`/users/permissions/${encode(codename)}`),
  addPermission: (codename) => requests.patch(`/users/permissions/${encode(codename)}`, {}),
  removePermission: (codename) => requests.delete(`/users/permissions/${encode(codename)}`, {}),
};

export const Affiliations = {
  list: () => requests.get('/affiliations/'),
};

export const Employees = {
  list: () => requests.get('/employees/'),
  detail: (userId) => requests.get(`/employees/${userId}/`),
};

export const Registration = {
  detail: (registrationId) => requests.get(`/registrations/${registrationId}`),
  managerUpdate: (registrationId, data) =>
    requests.patch(`/registrations/${registrationId}/manager`, data),
  add: (data) => requests.post('/registrations/add', data),
  recent: () => requests.get(`/registrations/?recent=true`),
  byYear: (academicYear) => requests.get(`/registrations/?academicyear=${academicYear}`),
};

export const Profile = {
  patch: (profileId, data) => requests.patch(`/profile/${profileId}/`, data),
  list: (filters = {}) => requests.get(`/profiles/${encodeQueryString(filters)}`),
  detail: (userSlug) => requests.get(`/profiles/${userSlug}`),
};

export const RidProfile = {
  patch: (ridprofileId, data) => requests.patch(`/ridprofile/${ridprofileId}/`, data),
  post: (data) => requests.post(`/ridprofile/`, data),
};

export const Notifications = {
  list: (filter, page) =>
    notifyRequests.get(`/api/all/?${queryString.stringify({ filter, page })}`),
  read: (notificationId) => notifyRequests.post(`/mark-read/`, { id: notificationId }),
  readAll: () => notifyRequests.post(`/mark-all/`, { action: 'read' }),
};

export const Survey = {
  get: (surveyId) => requests.get(`/surveys/${surveyId}/`),
  list: () => requests.get('/surveys/'),
  put: (surveyId, data) => requests.put(`/surveys/${surveyId}/`, data),
  patch: (surveyId, data) => requests.patch(`/surveys/${surveyId}/`, data),
  post: (data) => requests.post('/surveys/', data),
  delete: (surveyId) => requests.delete(`/surveys/${surveyId}/`, {}),
  copy: (surveyId) => requests.post(`/surveys/${surveyId}/copy`, {}),
  answersList: (surveyId) => requests.get(`/surveys/${surveyId}/answers/`),
};

export const QuestionBlock = {
  get: (blockId) => requests.get(`/question-blocks/${blockId}/`),
  list: () => requests.get('/question-blocks/'),
  put: (blockId, data) => requests.put(`/question-blocks/${blockId}/`, data),
  patch: (blockId, data) => requests.patch(`/question-blocks/${blockId}/`, data),
  post: (data) => requests.post('/question-blocks/', data),
  delete: (blockId) => requests.delete(`/question-blocks/${blockId}/`, {}),
};

export const Question = {
  get: (questionId) => requests.get(`/questions/${questionId}/`),
  getAnswer: (questionId, authorId) =>
    requests.get(`/questions/${questionId}/answers/author/${authorId}`),
  list: () => requests.get('/questions/'),
  put: (questionId, data) => requests.put(`/questions/${questionId}/`, data),
  patch: (questionId, data) => requests.patch(`/questions/${questionId}/`, data),
  post: (data) => requests.post('/questions/', data),
  delete: (questionId) => requests.delete(`/questions/${questionId}/`, {}),
};

export const Answer = {
  get: (answerId) => requests.get(`/answers/${answerId}/`),
  list: () => requests.get('/answers/'),
  put: (answerId, data) => requests.put(`/answers/${answerId}/`, data),
  patch: (answerId, data) => requests.patch(`/answers/${answerId}/`, data),
  post: (data) => requests.post('/answers/', data),
  delete: (answerId) => requests.delete(`/answers/${answerId}/`, {}),
};

export const Result = {
  get: (resultId) => requests.get(`/results/${resultId}/`),
  list: () => requests.get('/results/'),
  put: (resultId, data) => requests.put(`/results/${resultId}/`, data),
  patch: (resultId, data) => requests.patch(`/results/${resultId}/`, data),
  post: (data) => requests.post('/results/', data),
  delete: (resultId) => requests.delete(`/results/${resultId}/`, {}),
};

export const Transcript = {
  get: (userId) => requests.get(`/transcripts/${userId}`),
};

export const Contact = {
  post: (data) => requests.post('/about/contact/', data),
};

export const VideoCategories = {
  authenticate: ({ password }) => requests.post('/video-categories/auth', { password }),
  list: () => requests.get('/video-categories/'),
};

export const SiteTutorials = {
  list: () => requests.get('/site-tutorials/'),
};

export const EditLock = {
  checkin: (data) => requests.post('/edit-locks/checkin', data),
};

export const PinraEvents = {
  current: () => requests.get('/users/current'),
  get: (slug, completed) => requests.get(`/pinra/?slug=${slug}&completed=${completed}`),
  list: () => requests.get('/pinra/'),
  managerGetPinras: (completed) => requests.get(`/pinra/?completed=${completed}`),
  post: (data) => requests.post('/pinra/', data),
  updateActivityCode: (activityCode) => requests.patch('/pinra/', activityCode),
  updateCeus: (ceus) => requests.patch('/pinra/', ceus),
  updateStatus: (status) => requests.patch('/pinra/', status),
  updateContentArea: (contentArea) => requests.patch('/pinra/', contentArea),
  listTranscript: (slug) => requests.get(`/pinra/?slug=${slug}`),
};
