import React from 'react';
import { VideoCategories } from 'api';
import { Alert, Loading, PageHeader } from 'components';
import { failedAssertion } from '../../helpers/reporting';
import { ResourcesPage } from './ResourcesPage';
import playlisticon from '../../../static-images/playlisticon.png';

export class VideoLibrary extends React.Component {
  state = {
    password: '',
    authenticated: false,
    authLoading: false,
    authFailed: false,
    authError: '',
    listLoading: false,
    listError: false,
    token: '',
    videoCategories: [],
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { password } = this.state;
    this.setState({ authLoading: true });

    this.authenticate(password);
  };

  authenticate = async (password) => {
    let authRes;
    try {
      authRes = await VideoCategories.authenticate({ password });
    } catch (err) {
      const authError = (err && err.message) || 'Could not reach authentication server.';
      return this.setState({
        authFailed: true,
        authLoading: false,
        authError,
      });
    }

    if (!authRes || !authRes.token) {
      return this.setState({ authFailed: true, authLoading: false, authError: '' });
    }

    this.setState(
      {
        authenticated: true,
        authLoading: false,
        authFailed: false,
        authError: '',
        password: '',
        listLoading: true,
        token: authRes.token,
      },
      this.fetchVideos
    );
  };

  fetchVideos = async () => {
    try {
      let videoCategories = await VideoCategories.list();
      this.setState({ videoCategories, listLoading: false, listError: false });
    } catch (err) {
      failedAssertion(err);
      this.setState({ listError: true, listLoading: false });
    }
  };

  render() {
    if (!this.state.authenticated) {
      return (
        <ResourcesPage>
          <PageHeader.Basic title="DAS Video Library" />
          <div className="row">
            <div className="col-xs-12">
              <h2>Please Enter the Video Library Password</h2>
              <p>
                Don't know it?{' '}
                <a href={window.URLS['committee:index']()}>
                  Contact your PD Committee representative.
                </a>
              </p>
              <form className="form" id="password-protect-login" onSubmit={this.handleSubmit}>
                <div className={`form-group ${this.state.authFailed ? 'has-error' : ''}`}>
                  <label className="control-label" htmlFor="id_password">
                    Password
                  </label>
                  <input
                    type="password"
                    name="password"
                    className="form-control"
                    placeholder="Password"
                    title=""
                    required=""
                    value={this.state.password}
                    onChange={(e) => this.setState({ password: e.target.value })}
                    id="id_password"
                  />
                  {this.state.authError && <div className="help-block">{this.state.authError}</div>}
                </div>
                <button className="btn btn-info" type="submit" disabled={this.state.authLoading}>
                  Let me in
                </button>
              </form>
            </div>
          </div>
        </ResourcesPage>
      );
    }
    return (
      <ResourcesPage>
        <PageHeader.Basic title="DAS Video Library Playlists" />
        Note: selecting the playlist icon(
        <img src={playlisticon} alt="playlist icon" height="15" />) will allow you to view specific
        videos
        <div className="row">
          {this.state.listError ? (
            <div className="col-xs-12">
              <Alert type="danger" icon="fa-exclamation-triangle">
                Something went wrong. Our team has been notified, please try again or check back
                later.
              </Alert>
            </div>
          ) : this.state.listLoading ? (
            <Loading />
          ) : !this.state.videoCategories.length ? (
            <div className="col-xs-12">
              <p className="text-size">No videos have been added to the DAS Video Library.</p>
            </div>
          ) : (
            this.state.videoCategories.map((category) => (
              <div key={category.id} className="col-xs-12 col-md-6 col-lg-4 center mb2">
                <p className="text-size-lg bold videos-header">{category.name}</p>
                <div
                  className="video-wrapper"
                  dangerouslySetInnerHTML={{ __html: category.embed }}
                />
              </div>
            ))
          )}
        </div>
      </ResourcesPage>
    );
  }
}
