import Loadable from 'react-loadable';
import { Loading } from 'components';

const AsyncSurveyAdminListPage = Loadable({
  loader: () => import('./list-view/SurveyAdminListPage'),
  loading: Loading,
});

const AsyncSurveyAdminDetailPage = Loadable({
  loader: () => import('./detail-view/SurveyAdminDetailPage'),
  loading: Loading,
});

const AsyncSurveyAdminDeletePage = Loadable({
  loader: () => import('./delete-view/SurveyAdminDeletePage'),
  loading: Loading,
});

const AsyncSurveyAdminDistributionsPage = Loadable({
  loader: () => import('./distributions-view/SurveyAdminDistributionsPage'),
  loading: Loading,
});

export {
  AsyncSurveyAdminListPage,
  AsyncSurveyAdminDetailPage,
  AsyncSurveyAdminDeletePage,
  AsyncSurveyAdminDistributionsPage,
};
