import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';

import { failedAssertion } from 'helpers/reporting';

import { NotFound } from '../';

import BreadcrumbContext, { observedBits } from './BreadcrumbContext';

class BreadRouteUnwrapped extends React.Component {
  static propTypes = {
    ...Route.propTypes,
    addCrumb: PropTypes.func.isRequired,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    root: PropTypes.bool,
  };

  state = { hasError: false };

  fillPathParams(path) {
    const { params } = this.props.computedMatch;
    let index = path.indexOf(':');
    while (index >= 0) {
      let param = path.substring(index + 1);
      const paramEndIndex = param.indexOf('/');
      if (paramEndIndex >= 0) {
        param = param.substring(0, paramEndIndex);
      }
      path = path.replace(`:${param}`, params[param]);
      index = path.indexOf(':');
    }
    return path;
  }

  componentDidMount() {
    this.props.updateCrumbs();
  }

  componentDidUpdate() {
    this.props.updateCrumbs();
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    failedAssertion(error, { info });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="page-empty page-empty--error">
          <div className="page-empty__icon">
            <span className="fa fa-exclamation-triangle" />
          </div>
          <div className="page-empty__title">Something went wrong</div>
          <div className="page-empty__subtitle">Please reload the page.</div>
          <a href="" className="page-empty__button btn btn-default">
            Reload
          </a>
        </div>
      );
    }

    const { addCrumb, title, path, url, root, router, children, ...rest } = this.props;

    // If there are children, it is assumed those children are other BreadRoutes.
    // Automatically <switch> between routes, AND include the fallback 404 page
    let newChildren;
    if (children) {
      newChildren = (
        <Switch>
          {children}
          <Route component={NotFound} />
        </Switch>
      );
    }

    const output = <Route children={newChildren} {...rest} />;

    // Omitting the title prop will skip adding the crumb.
    // Useful for matching the parent route, e.g:
    // <Route path=/foo root title=Foo>
    //   <Route exact path=/foo/bar title=Bar />
    //   <Route exact path=/foo />
    if (output !== null && title) {
      let crumbUrl = url;
      if (!crumbUrl) {
        crumbUrl = this.fillPathParams(path);
      }
      addCrumb(title, crumbUrl, this.props.computedMatch.params, root, router);
    }

    return output;
  }
}

const BreadRoute = (props) => (
  <BreadcrumbContext.Consumer unstable_observedBits={observedBits.IGNORE}>
    {({ addCrumb, updateCrumbs }) => (
      <BreadRouteUnwrapped addCrumb={addCrumb} updateCrumbs={updateCrumbs} {...props} />
    )}
  </BreadcrumbContext.Consumer>
);

export default BreadRoute;
