import { asyncActionType } from 'store/actions';
import { questionDetail, questionUpdate } from 'store/actions/questions';
import { getDetailSelector } from 'store/selectors';
import { QuestionBlock, Question, Answer, notifyError } from 'api';

export const FETCH_QUESTION_BLOCK_DETAIL = asyncActionType('FETCH_QUESTION_BLOCK_DETAIL');
export const QUESTION_BLOCK_UPDATE = 'QUESTION_BLOCK_UPDATE';
export const QUESTION_BLOCK_DELETE = 'QUESTION_BLOCK_DELETE';
export const QUESTION_BLOCK_QUESTION_ADDED = 'QUESTION_BLOCK_QUESTION_ADDED';
export const QUESTION_BLOCK_QUESTION_MOVED = 'QUESTION_BLOCK_QUESTION_MOVED';

export const questionBlockUpdate = (blockId, data) => {
  return {
    type: QUESTION_BLOCK_UPDATE,
    blockId,
    data,
  };
};

export const questionBlockDelete = (blockId) => {
  return {
    type: QUESTION_BLOCK_DELETE,
    blockId,
  };
};

export const questionBlockQuestionAdded = (questionId, blockId) => {
  return {
    type: QUESTION_BLOCK_QUESTION_ADDED,
    questionId: parseInt(questionId, 10),
    blockId: parseInt(blockId, 10),
  };
};

export const questionBlockQuestionMoved = (questionId, oldBlockId, newBlockId) => {
  return {
    type: QUESTION_BLOCK_QUESTION_MOVED,
    questionId: parseInt(questionId, 10),
    oldBlockId: parseInt(oldBlockId, 10),
    newBlockId: parseInt(newBlockId, 10),
  };
};

export const questionBlockDetailPending = (blockId, bool) => {
  return {
    type: FETCH_QUESTION_BLOCK_DETAIL.PENDING,
    blockId,
    bool,
  };
};

export const questionBlockDetailError = (blockId, bool) => {
  return {
    type: FETCH_QUESTION_BLOCK_DETAIL.ERROR,
    blockId,
    bool,
  };
};

export const questionBlockDetailSuccess = (block) => {
  return {
    type: FETCH_QUESTION_BLOCK_DETAIL.SUCCESS,
    block,
  };
};

export const questionBlockDetail = (blockId) => (dispatch, getState) => {
  dispatch(questionBlockDetailPending(blockId, true));
  return QuestionBlock.get(blockId)
    .then((data) => {
      dispatch(questionBlockDetailSuccess(data));
      data.questions.forEach((question) => dispatch(questionDetail(question)));
      return data;
    })
    .catch((err) => {
      notifyError(err);
      dispatch(questionBlockDetailError(blockId, true));
    });
};

export const retrieveQuestionBlock = (blockId) => (dispatch, getState) => {
  const { keys } = getState().questionBlocks;
  let block = keys[blockId];
  if (block) {
    return Promise.resolve(block);
  }
  return QuestionBlock.get(blockId)
    .then((data) => {
      dispatch(questionBlockDetailSuccess(data));
      data.questions.forEach((question) => dispatch(questionDetail(question.id)));
      return data;
    })
    .catch((err) => {
      notifyError(err);
      dispatch(questionBlockDetailError(blockId, true));
    });
};

export const deleteQuestionBlock = (blockId) => (dispatch, getState) => {
  return QuestionBlock.delete(blockId)
    .then((data) => {
      dispatch(questionBlockDelete(blockId));
      return true;
    })
    .catch((err) => {
      notifyError(err);
      return false;
    });
};

export const saveBlockAnswersThunk = (blockId) => (dispatch, getState) => {
  dispatch(questionBlockUpdate(blockId, { isSaving: true }));
  let { accountsUser, questions, questionBlocks } = getState();
  const block = getDetailSelector(questionBlocks, blockId);
  const user = accountsUser[accountsUser.current];
  const results = block.questions.map(async (questionId) => {
    const question = getDetailSelector(questions, questionId);
    let answer;
    try {
      answer = await Question.getAnswer(question.id, user.pk);
    } catch (err) {
      throw err;
    }
    if (answer) {
      Answer.patch(answer.id, {
        question: question.id,
        survey: question.survey,
        editor: user.pk,
        response: question.answer,
      })
        .then((res) => dispatch(questionUpdate(question.id, { hasErrored: false })))
        .catch((err) => {
          dispatch(questionUpdate(question.id, { hasErrored: true }));
          throw err;
        });
    } else {
      Answer.post({
        question: question.id,
        survey: question.survey,
        author: user.pk,
        response: question.answer,
      })
        .then((res) => dispatch(questionUpdate(question.id, { hasErrored: false })))
        .catch((err) => {
          dispatch(questionUpdate(question.id, { hasErrored: true }));
          throw err;
        });
    }
  });

  Promise.all(results).then(() => {
    dispatch(questionBlockUpdate(blockId, { isSaving: false }));
  });
};
