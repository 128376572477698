import { combineReducers } from 'redux';
import { FETCH_ANSWER_LIST, FETCH_ANSWER_DETAIL, ANSWER_UPDATE } from 'store/actions/answers';

export const initialState = {
  isLoading: false,
  hasErrored: false,
};

export const keys = (state = initialState, action) => {
  let answer;
  let answerList;
  switch (action.type) {
    case FETCH_ANSWER_LIST.PENDING:
      return { ...state, isLoading: action.bool };
    case FETCH_ANSWER_LIST.SUCCESS:
      answerList = action.answers.reduce((accumulator, answer) => {
        accumulator[answer.id] = answer;
        return accumulator;
      }, {});
      return { ...state, ...answerList, isLoading: false };
    case FETCH_ANSWER_LIST.ERROR:
      return { ...state, hasErrored: action.bool, isLoading: false };
    case FETCH_ANSWER_DETAIL.PENDING:
      answer = state[action.answerId];
      return { ...state, [action.answerId]: { ...answer, isLoading: action.bool } };
    case FETCH_ANSWER_DETAIL.SUCCESS:
      return { ...state, [action.answer.id]: { ...action.answer, isLoading: false } };
    case FETCH_ANSWER_DETAIL.ERROR:
      answer = state[action.answerId];
      return { ...state, [action.answerId]: { ...answer, hasErrored: action.bool } };
    case ANSWER_UPDATE:
      answer = state[action.answerId];
      return { ...state, [action.answerId]: { ...answer, ...action.data } };
    default:
      return state;
  }
};

let set;

export const ids = (state = [], action) => {
  switch (action.type) {
    case FETCH_ANSWER_LIST.SUCCESS:
      set = new Set([...state, ...action.answers.map((a) => a.id)]);
      return [...set];
    case FETCH_ANSWER_DETAIL.SUCCESS:
      set = new Set([...state, action.answer.id]);
      return [...set];
    default:
      return state;
  }
};

const answers = combineReducers({
  keys,
  ids,
});

export default answers;
