import {
  FETCH_REGISTRATION_DETAIL,
  FETCH_REGISTRATION_LIST,
  FETCH_REGISTRATION_USER,
  FETCH_REGISTRATION_RECENT,
  FETCH_REGISTRATION_BYYEAR,
  REGISTRATION_UPDATE,
} from 'store/actions/registrations';

export const initialState = {
  listLoading: true,
  detailLoading: true,
  userLoading: true,
  recentRegistrationsLoading: true,
  registrationsByYearLoading: true,
  error: false,
  list: [],
  detail: null,
  user: null,
  recentRegistrations: null,
  registrationsByYear: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_REGISTRATION_LIST.PENDING:
      return { ...state, listLoading: action.bool, error: false };
    case FETCH_REGISTRATION_LIST.SUCCESS:
      return { ...state, list: action.data, listLoading: false, error: false };
    case FETCH_REGISTRATION_LIST.ERROR:
      return { ...state, error: action.error || action.bool, listLoading: false };
    case FETCH_REGISTRATION_DETAIL.PENDING:
      return { ...state, detailLoading: action.bool };
    case FETCH_REGISTRATION_DETAIL.SUCCESS:
      return { ...state, detail: action.data, detailLoading: false };
    case FETCH_REGISTRATION_DETAIL.ERROR:
      return { ...state, error: action.error, detailLoading: false };
    case FETCH_REGISTRATION_USER.PENDING:
      return { ...state, userLoading: action.bool };
    case FETCH_REGISTRATION_USER.SUCCESS:
      return { ...state, user: action.data, userLoading: false };
    case FETCH_REGISTRATION_USER.ERROR:
      return { ...state, error: action.error, userLoading: false };
    case FETCH_REGISTRATION_RECENT.PENDING:
      return { ...state, recentRegistrationsLoading: action.bool };
    case FETCH_REGISTRATION_RECENT.SUCCESS:
      return { ...state, recentRegistrations: action.data, recentRegistrationsLoading: false };
    case FETCH_REGISTRATION_RECENT.ERROR:
      return { ...state, error: action.error, recentRegistrationsLoading: false };
    case FETCH_REGISTRATION_BYYEAR.PENDING:
      return { ...state, error: false, registrationsByYearLoading: action.bool };
    case FETCH_REGISTRATION_BYYEAR.SUCCESS:
      return {
        ...state,
        error: false,
        registrationsByYear: action.data,
        registrationsByYearLoading: false,
      };
    case FETCH_REGISTRATION_BYYEAR.ERROR:
      return { ...state, error: action.error, registrationsByYearLoading: false };
    case REGISTRATION_UPDATE:
      return {
        ...state,
        list: state.list.map((registration) => {
          if (registration.id === action.registration.pk) {
            return { ...registration, status: action.registration.status };
          }
          return registration;
        }),
      };
    default:
      return state;
  }
};
