import { combineReducers, createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import debounceMiddleware from './middleware/debounce';

import accountsManager from './reducers/accounts/manager';
import accountsUser from './reducers/accounts/user';
import affiliations from './reducers/accounts/affiliations';
import breadcrumbs from './reducers/breadcrumbs';
import groups from './reducers/accounts/groups';
import carousels from './reducers/carousels';
import eventsAdmin from './reducers/events/admin';
import eventsExplore from './reducers/events/explore';
import eventsDetail from './reducers/events/detail';
import notifications from './reducers/notifications';
import profiles from './reducers/accounts/profiles';
import registrations from './reducers/registrations';
import surveys from './reducers/surveys';
import questionBlocks from './reducers/question-blocks';
import questions from './reducers/questions';
import answers from './reducers/answers';

const reducer = combineReducers({
  accountsUser,
  accountsManager,
  affiliations,
  breadcrumbs,
  carousels,
  eventsAdmin,
  eventsExplore,
  eventsDetail,
  groups,
  notifications,
  profiles,
  registrations,
  surveys,
  questionBlocks,
  questions,
  answers,
});

export function configureStore() {
  if (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test') {
    const loggerMiddleware = createLogger();
    return createStore(
      reducer,
      composeWithDevTools(applyMiddleware(thunkMiddleware, loggerMiddleware, debounceMiddleware))
    );
  }
  return createStore(reducer, applyMiddleware(thunkMiddleware, debounceMiddleware));
}

let store = configureStore();

export default store;
