if (typeof tinymce !== 'undefined') {
  tinymce.init({
    selector: 'textarea.tinymce',
    setup: function(ed) {
      ed.on('init', function(args) {
        $('#' + args.target.id).removeAttr('disabled');
        $('#' + args.target.id).removeAttr('title');
      });
    },
    menubar: true,
    statusbar: false,
    height: 300,
    browser_spellcheck: true,
    plugins: [
      'advlist autolink link image lists charmap print hr',
      'searchreplace wordcount fullscreen insertdatetime media nonbreaking',
      'save contextmenu directionality paste textcolor',
    ],
    toolbar:
      'undo redo | styleselect | bold italic blockquote forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image',
    style_formats: [
      { title: 'PDP Das Style' },
      {
        title: 'Heading 1',
        block: 'h1',
        classes: 'big bold',
        exact: true,
      },
      {
        title: 'Heading 2',
        block: 'h2',
        classes: 'diminished article-subtitle',
        exact: true,
      },
      {
        title: 'Text - 21pt',
        block: 'p',
        classes: 'blog-text text-size-lg',
        exact: true,
      },
      {
        title: 'Text - 16pt',
        block: 'p',
        classes: 'blog-text text-size',
        exact: true,
      },
      {
        title: 'Text - 12pt',
        block: 'p',
        classes: 'blog-text text-size-sm',
        exact: true,
      },
    ],
  });
  // tinymce makes the textareas hidden and copies the editor contents to the textarea right
  // before the form submits. As a result browser validation will not be able to focus the hidden
  // textarea field and throw a console error, causing the form to not submit properly. So instead
  // we disable the required attr here so validation doesn't occur and check it server side instead
  // this is a workaround until tinymce adds browser support or we roll our own solution.
  let textareas = $('textarea.tinymce');
  textareas.attr('required', false);
  // let the user know it is a required field
  textareas
    .parent()
    .children('label')
    .each(function() {
      let text = $(this).text();
      text += '*';
      $(this).text(text);
    });
}
