import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { makeSurveyDetailSelector } from 'store/reducers/surveys';

import { BreadRoute, NotFound } from 'components';

import AsyncPdDashboard from './pd-dashboard';
import { AsyncEventsExplore, AsyncEventsAdmin, AsyncEventDetail, AsyncEventsHomePage } from './events';
import AsyncEventIdsPage from './event-ids';
import { AsyncAttendanceTrackerPage, AsyncFacilitatorWrapUpPage, AsyncInvitationsPage, AsyncSurveyResultsPage } from './facilitator-dashboard';
import AsyncManagerDashboard from './manager-dashboard';
import AsyncOnboarding from './onboarding';
import AsyncNotifications from './notifications';
import AsyncRegistrationListPage from './registration-list';
import { AsyncSurveyAdminListPage, AsyncSurveyAdminDetailPage, AsyncSurveyAdminDeletePage, AsyncSurveyAdminDistributionsPage } from './survey-admin';
import { AsyncPublicProfilesListPage, AsyncPublicProfileDetailPage } from './public-profiles';
import { AsyncSurveyPage, AsyncCompleteSurveyPage } from './surveys';
import AsyncAttendanceRecordsPage from './attendance-records';
import AsyncPDPTimelinePage from './calendar-nav';
import AsyncPdTranscriptPage from './pd-transcript';
import { ContactPage } from './about';
import { PinraPage } from './pinra';
import { ResourcesIndex, BookLibrary, VideoLibrary, Tutorials, FAQ } from './resources';
import { FormsIndex, AcademicCourseworkForm, FacilitatorPlanForm, SponsorPlanForm, IndependentReportForm, RidActivityForm, RidPinraForm } from './resources/forms';
import { Registrations, RegistrationsArchive } from './registrations';

const titleUserSlug = (state, params) => params.userSlug;

const titleEventName = (state, params) => {
  if (
    !state.eventsDetail ||
    !state.eventsDetail.event ||
    // eslint-disable-next-line eqeqeq
    params.eventId != state.eventsDetail.event.pk
  ) {
    return;
  }
  return state.eventsDetail.event.title;
};

const titleSurvey = (state, params) => {
  const surveyDetailSelector = makeSurveyDetailSelector();
  const survey = surveyDetailSelector(state, params.surveyId);
  // eslint-disable-next-line eqeqeq
  if (!survey || survey.id != params.surveyId) {
    return;
  }
  return survey.name;
};

const titleAttendance = (state, params) => `Attendance for ${params.userSlug}`;

/*
 * All routes should be BreadRoutes.
 * A BreadRoute takes care of several things:
 *   1. Automatic breadcrumb generation (w/static or dynamic titles)
 *   2. Automatically wrapping children in a <Switch>
 *   3. Providing a default <NotFound /> route if nothing in the <Switch> matches
 *
 * The hierarchy of BreadRoutes should follow nesting by URL path piece.
 * This makes it easy for developers to follow how navigation works,
 * and for users that breadcrumb pieces are consistent.
 * (e.g, for /foo/.., the "foo" part of the URL always means the same thing)
 *
 * E.g for three pages like /foo/bar, /foo/bar/baz, and /foo/bar/bing, the structure would be:
 * <BreadRoute path="/foo" title="Foo root">
 *   <BreadRoute path="/foo/bar" title="Bar">
 *     <BreadRoute path="/foo/bar/baz" title="Baz">
 *     <BreadRoute path="/foo/bar/bing" title="Bing">
 *   </BreadRoute>
 * </BreadRoute>
 */

const Routes = () => (
  <Switch>
    <BreadRoute exact path="/" component={AsyncPdDashboard} title="PD Dashboard" root />
    <BreadRoute exact path="/accounts/onboarding" component={AsyncOnboarding} title="Onboarding" root />
    <BreadRoute path="/events/manager-dashboard" component={AsyncManagerDashboard} title="Manager Dashboard" root />

    <BreadRoute path="/events/" title="Events" root>
      <BreadRoute path="/events/admin-dashboard" title="Events Admin">
        <BreadRoute exact path="/events/admin-dashboard" component={AsyncEventsAdmin} />
        <BreadRoute exact path="/events/admin-dashboard/ids" component={AsyncEventIdsPage} title="Event IDs List" />
      </BreadRoute>
      <BreadRoute path="/events/explore/" component={AsyncEventsExplore} title="Explore Events" />
      <BreadRoute path="/events/:eventId" title={titleEventName}>
        <BreadRoute exact path="/events/:eventId/draft" component={AsyncEventDetail} title="Draft" />
        <BreadRoute exact path="/events/:eventId/attendance" component={AsyncAttendanceTrackerPage} title="Attendance Tracker" />
        <BreadRoute exact path="/events/:eventId/attendance/:userSlug" component={AsyncAttendanceRecordsPage} title={titleAttendance} />
        <BreadRoute exact path="/events/:eventId/event-invitations" component={AsyncInvitationsPage} title="Invitations" />
        <BreadRoute exact path="/events/:eventId/wrap-up" component={AsyncFacilitatorWrapUpPage} title="Wrap Up" />
        <BreadRoute path="/events/:eventId/registration-list" component={AsyncRegistrationListPage} title="Registration List" />
        <BreadRoute path="/events/:eventId/survey/:surveyId" title={titleSurvey}>
          <BreadRoute exact path="/events/:eventId/survey/:surveyId/complete" component={AsyncCompleteSurveyPage} title="Complete" />
          <BreadRoute exact path="/events/:eventId/survey/:surveyId" component={AsyncSurveyPage} />
        </BreadRoute>
        <BreadRoute exact path="/events/:eventId" component={AsyncEventDetail} />
      </BreadRoute>
      <BreadRoute exact path="/events/" component={AsyncEventsHomePage} />
    </BreadRoute>

    <BreadRoute exact path="/accounts/notifications/" component={AsyncNotifications} title="Notifications" root />
    <BreadRoute path="/accounts/profiles/" title="Profiles" root>
      <BreadRoute exact path="/accounts/profiles/" component={AsyncPublicProfilesListPage} />
      <BreadRoute path="/accounts/profiles/:userSlug" title={titleUserSlug}>
        <BreadRoute exact path="/accounts/profiles/:userSlug/transcript" component={AsyncPdTranscriptPage} title="Transcript" />
        <BreadRoute exact path="/accounts/profiles/:userSlug" component={AsyncPublicProfileDetailPage} />
      </BreadRoute>
    </BreadRoute>

    <BreadRoute path="/accounts/registrations/" title="Registrations" root>
      <BreadRoute exact path="/accounts/registrations/" component={Registrations} />
      <BreadRoute exact path="/accounts/registrations/archive/" component={RegistrationsArchive} title="Archive" />
    </BreadRoute>

    <BreadRoute exact path="/calendar/timeline" component={AsyncPDPTimelinePage} title="PDP Timeline" root />

    <BreadRoute exact path="/about/contact/" component={ContactPage} title="Contact" root />

    <BreadRoute exact path="/pinra/" component={PinraPage} title="Pinra" root />

    <BreadRoute path="/resources/" title="Resources" root>
      <BreadRoute exact path="/resources/" component={ResourcesIndex} />
      <BreadRoute exact path="/resources/books/" title="DAS Book Library" component={BookLibrary} />
      <BreadRoute exact path="/resources/faq/" title="FAQ" component={FAQ} />
      <BreadRoute exact path="/resources/videos/" title="DAS Video Library" component={VideoLibrary} />
      <BreadRoute exact path="/resources/tutorials/" title="PDP Site Tutorials" component={Tutorials} />
      <BreadRoute path="/resources/forms/" title="Forms">
        <BreadRoute exact path="/resources/forms/academic-coursework/" title="Academic Coursework Activity Report" component={AcademicCourseworkForm} />
        <BreadRoute exact path="/resources/forms/facilitator-plan/" title="Facilitator's Plan" component={FacilitatorPlanForm} />
        <BreadRoute exact path="/resources/forms/sponsor-plan/" title="Sponsor's Plan" component={SponsorPlanForm} />
        <BreadRoute exact path="/resources/forms/independent-report/" title="Independent Report" component={IndependentReportForm} />
        <BreadRoute exact path="/resources/forms/rid-activity/" title="RID Activity" component={RidActivityForm} />
        <BreadRoute exact path="/resources/forms/rid-pinra/" title="RID Pinra" component={RidPinraForm} />
        <BreadRoute exact path="/resources/forms/" component={FormsIndex} />
      </BreadRoute>
    </BreadRoute>

    <BreadRoute path="/surveys/admin/" title="Survey Admin" root>
      <BreadRoute exact path="/surveys/admin/" component={AsyncSurveyAdminListPage} />
      <BreadRoute path="/surveys/admin/:surveyId" title={titleSurvey}>
        <BreadRoute exact path="/surveys/admin/:surveyId" component={AsyncSurveyAdminDetailPage} />
        <BreadRoute exact path="/surveys/admin/:surveyId/distributions" component={AsyncSurveyAdminDistributionsPage} title="Distributions" />
        <BreadRoute exact path="/surveys/admin/:surveyId/delete" component={AsyncSurveyAdminDeletePage} title="Delete" />
      </BreadRoute>
    </BreadRoute>

    <BreadRoute exact path="/surveys/:surveyId/results" title="Facilitator Dashboard" url={window.URLS['pd:facilitator_dashboard']()} root router={false}>
      <BreadRoute exact path="/surveys/:surveyId/results" component={AsyncSurveyResultsPage} title={titleSurvey} />
    </BreadRoute>

    <Route path="*" component={NotFound} />
  </Switch>
);

export default Routes;
