import { asyncActionType } from 'store/actions';
import { questionBlockDetailSuccess } from 'store/actions/question-blocks';
import { questionDetail } from 'store/actions/questions';
import { answerDetailSuccess } from 'store/actions/answers';
import { Survey, notifyError } from 'api';
import flatten from 'lodash/flatten';

export const FETCH_SURVEY_LIST = asyncActionType('FETCH_SURVEY_LIST');
export const FETCH_SURVEY_DETAIL = asyncActionType('FETCH_SURVEY_DETAIL');
export const SURVEY_QUESTION_BLOCK_ADDED = 'SURVEY_QUESTION_BLOCK_ADDED';
export const SURVEY_UPDATE = 'SURVEY_UPDATE';
export const SURVEY_DELETE = 'SURVEY_DELETE';
export const SURVEY_DETAIL_NOT_FOUND = 'SURVEY_DETAIL_NOT_FOUND';

export const surveyQuestionBlockAdded = (surveyId, block) => {
  return {
    type: SURVEY_QUESTION_BLOCK_ADDED,
    surveyId,
    block,
  };
};

export const surveyDetailNotFound = (surveyId, bool) => {
  return {
    type: SURVEY_DETAIL_NOT_FOUND,
    surveyId,
    bool,
  };
};

export const surveyDetailPending = (surveyId, bool) => {
  return {
    type: FETCH_SURVEY_DETAIL.PENDING,
    surveyId,
    bool,
  };
};

export const surveyDetailError = (surveyId, bool) => {
  return {
    type: FETCH_SURVEY_DETAIL.ERROR,
    surveyId,
    bool,
  };
};

export const surveyDetailSuccess = (survey) => {
  return {
    type: FETCH_SURVEY_DETAIL.SUCCESS,
    survey,
  };
};

export const surveyListPending = (bool) => {
  return {
    type: FETCH_SURVEY_LIST.PENDING,
    bool,
  };
};

export const surveyListError = (bool) => {
  return {
    type: FETCH_SURVEY_LIST.ERROR,
    bool,
  };
};

export const surveyListSuccess = (surveys) => {
  return {
    type: FETCH_SURVEY_LIST.SUCCESS,
    surveys,
  };
};

export const surveyUpdate = (surveyId, data) => {
  return {
    type: SURVEY_UPDATE,
    surveyId,
    data,
  };
};

export const surveyDelete = (surveyId) => {
  return {
    type: SURVEY_DELETE,
    surveyId,
  };
};

export const surveyDetailThunk = (surveyId) => (dispatch, getState) => {
  dispatch(surveyDetailPending(surveyId, true));
  return Survey.get(surveyId)
    .then((data) => {
      const surveyPromises = data.question_blocks.map((block) => {
        const questionBlockPromises = block.questions.map((questionId) =>
          dispatch(questionDetail(questionId)).then((question) => {
            if (question.answer) {
              dispatch(answerDetailSuccess(question.answer));
            }
          })
        );
        return Promise.all(flatten(questionBlockPromises)).then(() =>
          dispatch(questionBlockDetailSuccess(block))
        );
      });
      Promise.all(surveyPromises).then(() => dispatch(surveyDetailSuccess(data)));
      return data;
    })
    .catch((err) => {
      if (err.response.status === 404) {
        dispatch(surveyDetailNotFound(surveyId, true));
      } else {
        dispatch(surveyDetailError(surveyId, true));
        notifyError(err);
      }
    });
};

export const surveyListThunk = () => (dispatch, getState) => {
  dispatch(surveyListPending(true));
  return Survey.list()
    .then((data) => dispatch(surveyListSuccess(data)))
    .catch((err) => {
      notifyError(err);
      dispatch(surveyListError(true));
    });
};

export const copySurveyThunk = (surveyId) => (dispatch, getState) => {
  return Survey.copy(surveyId)
    .then((data) => dispatch(surveyDetailSuccess(data)))
    .catch((err) => notifyError(err));
};

export const createSurveyThunk = (data) => (dispatch, getState) => {
  return Survey.post(data)
    .then((data) => dispatch(surveyDetailSuccess(data)))
    .catch((err) => notifyError(err));
};

export const updateSurveyThunk = (surveyId, data) => (dispatch, getState) => {
  return Survey.patch(surveyId, data)
    .then((data) => dispatch(surveyUpdate(surveyId, data)))
    .catch((err) => {
      notifyError(err);
      dispatch(surveyDetailError(surveyId, true));
      throw err;
    });
};

export const deleteSurveyThunk = (surveyId) => (dispatch, getState) => {
  return Survey.delete(surveyId)
    .then(() => dispatch(surveyDelete(surveyId)))
    .catch(() => false);
};

export const retrieveSurvey = (surveyId) => (dispatch, getState) => {
  const { keys } = getState().surveys;
  let survey = keys[surveyId];
  if (surveyId) {
    return Promise.resolve(survey);
  }
  return surveyDetailThunk(surveyId);
};
