import Loadable from 'react-loadable';
import { Loading } from 'components';

const AsyncPublicProfilesListPage = Loadable({
  loader: () => import('./PublicProfilesListPage'),
  loading: Loading,
});

const AsyncPublicProfileDetailPage = Loadable({
  loader: () => import('./PublicProfileDetailPage'),
  loading: Loading,
});

export { AsyncPublicProfilesListPage, AsyncPublicProfileDetailPage };
