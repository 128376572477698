import React from 'react';
import PropTypes from 'prop-types';

import { EventDictionary } from 'helpers/dictionaries';

const { REG_STATUS } = EventDictionary;

const RegistrationStatusLabel = (props) => {
  if (props.status === REG_STATUS.PENDING) {
    return <span className="label label-info">Pending</span>;
  } else if (props.status === REG_STATUS.DENIED) {
    return <span className="label label-danger">Denied</span>;
  } else if (props.status === REG_STATUS.APPROVED) {
    return <span className="label label-success">Approved</span>;
  } else if (props.status === REG_STATUS.APPROVED_PENDING_REVIEW) {
    return <span className="label label-warning">Manager Approved</span>;
  } else if (props.status === REG_STATUS.WITHDRAW) {
    return <span className="label label-default">Withdrawn</span>;
  } else if (props.status === REG_STATUS.FACILITATOR) {
    return <span className="label label-primary">Facilitator</span>;
  } else if (props.status === REG_STATUS.IN_CART) {
    return <span className="label label-primary">In Cart</span>;
  } else {
    return <span className="label label-default">Not Registered</span>;
  }
};

RegistrationStatusLabel.propTypes = {
  status: PropTypes.string,
};

export default RegistrationStatusLabel;
