import moment from 'moment';

let d = new Date();
const startDate = new Date(d.setMonth(d.getMonth() - 12));
let dateOpt = {
  format: 'yyyy/mm/dd',
  autoclose: true,
  startDate: startDate,
};
let timeOpt = {
  timeFormat: 'h:i A',
  scrollDefault: '6am',
  step: 5,
};
let agenda = $('#id_start_0');
if (agenda !== null) {
  $('#id_start_0').datepicker(dateOpt);
  $('#id_end_0').datepicker(dateOpt);
  $('#id_release_date_0').datepicker(dateOpt);
  $('#id_start_1').timepicker(timeOpt);
  $('#id_end_1').timepicker(timeOpt);
  $('#id_release_date_1').timepicker(timeOpt);
  $('#id_start_1').on('changeTime', function() {
    let timeString = $(this).val();
    timeString = moment(timeString, 'hh:mm A')
      .add(1, 'hour')
      .format('hh:mm A');
    $('#id_end_1').timepicker('setTime', timeString);
  });
  $('#id_start_0').on('changeDate', function() {
    $('#id_end_0').datepicker('setDate', $(this).val());
  });
  $('[name="agenda"]').attr('required', false);
}

let meeting = $('#id_start_date');
if (meeting !== null) {
  timeOpt['timeformat'] = 'g:ia';
  $('#id_start_date').datepicker(dateOpt);
  $('#id_end_date').datepicker(dateOpt);
  $('#id_start_time').timepicker(timeOpt);
  $('#id_end_time').timepicker(timeOpt);
  $('#id_start_time').on('changeTime', function() {
    let timeString = $(this).val();
    timeString = moment(timeString, 'hh:mm A')
      .add(1, 'hour')
      .format('hh:mm A');
    $('#id_end_time').timepicker('setTime', timeString);
  });
  $('#id_start_date').on('changeDate', function() {
    $('#id_end_date').datepicker('setDate', $(this).val());
  });
}
let minutes = $('textarea[name="minutes"]');
if (minutes !== null) {
  $('#id_release_date_0').datepicker(dateOpt);
  $('#id_release_date_1').timepicker(timeOpt);
}

let datePicker = $('.datepicker');
if (datePicker !== null) {
  datePicker.datepicker(dateOpt);
}

let eventProposal = $('[name="educational_objectives"]');
if (eventProposal !== null) {
  $('label.btn').on('click', function() {
    if ($(this).hasClass('btn-default')) {
      $(this)
        .siblings('.btn-primary')
        .removeClass('btn-primary')
        .addClass('btn-default')
        .children()
        .prop('checked', false);
      $(this)
        .removeClass('btn-default')
        .addClass('btn-primary');
    }
  });
  $('.btnNext').click(function() {
    $('.nav-tabs > .active')
      .next('li')
      .find('a')
      .trigger('click');
  });
  $('.btnPrevious').click(function() {
    $('.nav-tabs > .active')
      .prev('li')
      .find('a')
      .trigger('click');
  });
}

let reservation = $('#id_doors_open_0');
if (reservation !== null) {
  $('#id_doors_open_0').datepicker(dateOpt);
  $('#id_doors_open_1').timepicker(timeOpt);

  $('#id_doors_close_0').datepicker(dateOpt);
  $('#id_doors_close_1').timepicker(timeOpt);

  $('#id_reservations_open_0').datepicker(dateOpt);
  $('#id_reservations_open_1').timepicker(timeOpt);

  $('#id_reservations_close_0').datepicker(dateOpt);
  $('#id_reservations_close_1').timepicker(timeOpt);
}
