import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const FormInput = ({ error, label, help, ...props }) => {
  const classes = classNames('form-group', { 'has-error': error });
  return (
    <div className={classes}>
      <label htmlFor={label.htmlFor} className="label-control">
        {label.text}
      </label>
      <input {...props} />
      {help ? <p className="help-block">{help}</p> : null}
      {error ? <span className="help-block">{error}</span> : null}
    </div>
  );
};

FormInput.propTypes = {
  help: PropTypes.string,
  error: PropTypes.string,
  label: PropTypes.shape({
    htmlFor: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }),
};

export default FormInput;
