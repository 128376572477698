// React components
import React from 'react';
import ReactDOM from 'react-dom';

// CSS resources
import 'font-awesome-sass-loader';
import './index.sass';
import 'react-bootstrap-modal/lib/styles/rbm-patch.css';
import 'react-virtualized/styles.css';

import 'helpers/browser-support';

// Third party resources
import $ from 'jquery';
import 'bootstrap-sass';
import 'bootstrap-datepicker';
import 'timepicker';
import 'helpers/selectize';
import 'qtip2';
import moment from 'moment';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

// Various PDP resources
import './ajax';
import 'helpers/reverse';
import 'helpers/scripts';
import 'helpers/search';
import 'helpers/tinymce';
import 'helpers/blurry';
import 'helpers/person-modal';
import 'helpers/responsive-tabs';
import 'helpers/calendar';
import 'helpers/pnotify';
import 'helpers/toggle-checkboxes';
import 'helpers/select2';
import 'helpers/bootstrap-table';
import 'helpers/date-time-picker';
import { checkinIfNeeded } from 'helpers/edit-lock';
import unsavedAlert from 'helpers/unsaved-alert';
import { getCookie } from 'helpers/_getCookie';

import { AutoBreadcrumbs, WrappedBreadcrumbs } from './components/breadcrumbs';
import GlobalRouting from './components/GlobalRouting';
import Routes from './components/Routes';
import store from 'store';

// Ensure required globals are available
window.$ = $;
window.jQuery = $;
window.moment = moment;
window.getCookie = getCookie;

// React 16.3's new Context API conflicts with React Router, preventing location updates.
// See https://github.com/ReactTraining/react-router/issues/6072#issuecomment-379559532
// Once fixed, this can be re-integrated in the main render() call below instead of
// being a separate function.
const Root = () => (
  <AutoBreadcrumbs>
    <React.Fragment>
      <WrappedBreadcrumbs />
      <Routes />
    </React.Fragment>
  </AutoBreadcrumbs>
);

const reactGlobal = document.getElementById('react-global');
if (reactGlobal) {
  ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter>
        <GlobalRouting>
          <Root />
        </GlobalRouting>
      </BrowserRouter>
    </Provider>,
    reactGlobal
  );
}

$(document).ready(function() {
  unsavedAlert();
  checkinIfNeeded();
});
