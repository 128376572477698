import React from 'react';
import PropTypes from 'prop-types';
import { ResourcesNav } from './ResourcesNav';

export const ResourcesPage = ({ children }) => (
  <>
    <ResourcesNav />
    <div className="sidebar-page__content">
      <div className="white-soft-box2 white-soft-box2--no-animate">{children}</div>
    </div>
  </>
);

ResourcesPage.propTypes = {
  children: PropTypes.node,
};
