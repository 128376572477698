import { asyncActionType } from 'store/actions';
import { User } from 'api';

export const FETCH_USER_GROUP_LIST = asyncActionType('FETCH_USER_GROUP_LIST');

export const fetchUserGroupListPending = (bool) => {
  return {
    type: FETCH_USER_GROUP_LIST.PENDING,
    bool,
  };
};

export const fetchUserGroupListError = (bool) => {
  return {
    type: FETCH_USER_GROUP_LIST.ERROR,
    bool,
  };
};

export const fetchUserGroupListSuccess = (groupName, data) => {
  return {
    type: FETCH_USER_GROUP_LIST.SUCCESS,
    groupName,
    data,
  };
};

export const userGroupListThunk = (groupName) => (dispatch, getState) => {
  dispatch(fetchUserGroupListPending(true));
  return User.groupList(groupName)
    .then((data) => {
      dispatch(fetchUserGroupListSuccess(groupName, data));
      return data;
    })
    .catch((err) => {
      dispatch(fetchUserGroupListError(true));
      throw err;
    });
};
