import React from 'react';

export class PinraInstructions extends React.Component {
  render() {
    return (
      <React.Fragment>
        <h3>What is a PINRA Form?</h3>
        <p>
          PINRA Form stands for Participant Initiated Non-RID Activities Form. PINRA forms are
          provided as a vehicle for earning CEU credits for events that are educationally beneficial
          but do not fall under the purview of an RID sponsored event. Participants are responsible
          for providing documentation of participation in the event and for returning all
          documentation requested to the PDP Manager for timely submission to the RID Sponsor.
        </p>
        <p>
          <strong>
            Note: You must provide copies of registration, event advertisement/poster, event agenda,
            or other documentation that identifies content, hours, and attendance to enable the
            Sponsor to determine the appropriate number of CEUs and Content Area(s).
          </strong>
        </p>
        <h3>Step One: Select the Event</h3>
        <p>
          Choose the activity or conference you want to attend. The activity or conference does not
          have to pertain strictly to interpreting. It can be a regional or national conference of
          another profession, or could be a formal presentation offered by an employer. As long as
          the activity is educationally beneficial to you as an interpreter, you can earn CEUs for
          the event!
        </p>
        <h3>Step Two: Before the Event:</h3>
        <p>
          Complete the form below to begin the process of securing sponsorship for the event. Be
          sure to include the Content Area for which you are requesting CEUs (Professional Studies
          or General Studies).
          <br />
          How many CEUs will be earned? <strong>(1 Contact Hour = .1 CEU)</strong>
        </p>
        <h3>Step Three: Submit your Initial Documents</h3>
        <p>
          Using the <span style={{ color: '#db7d4b' }}>‘Upload Using Google Forms’</span> button,
          submit pertinent documentation including the
          <strong> advertisement/poster, event agenda, and your proof of registration.</strong>
          <br />
        </p>
        <p>
          Sponsor approval will be visible in your PD dashboard. You can follow the status of this
          PINRA and view any required action necessary on your part in your PD dashboard.
        </p>
        <p>
          <strong>
            <u>
              This form and all required documentation must be submitted a minimum of 3 days before
              the activity begins.
            </u>
          </strong>
        </p>
        <h3>Step Four: Attend the Event</h3>
        <p>
          Following the official approval that is visible in your PD Dashboard, attend the event.
        </p>
        <h3>Step Five: After the Event</h3>
        <p>
          Upon completion of the activity, attain a certificate of attendance or documentation such
          as notes taken during the event or a brief description of the event along with what you
          learned and how it applies to your work. This will serve as your proof of attendance.
        </p>
        <p>
          Submit these documents by clicking{' '}
          <span style={{ color: '#db7d4b' }}>‘Upload Final Documents’</span> in your PD Dashboard
          under PINRAs.
        </p>
        <hr />
      </React.Fragment>
    );
  }
}
