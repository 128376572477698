import React from 'react';
import { Link } from 'react-router-dom';
import DocumentTitle from 'react-document-title';

const NotFound = () => (
  <DocumentTitle title="404 - Not Found">
    <div className="text-center flex-center">
      <div>
        <h1>Not Found</h1>
        <h2>We couldn't find this page.</h2>
        <form
          method="get"
          action="http://www.google.com/search"
          className="form form-inline mt2 mb2"
        >
          <div className="input-group">
            <input
              type="text"
              name="q"
              size="25"
              maxLength="255"
              placeholder="Search..."
              className="form-control"
            />
            <span className="input-group-btn">
              <input type="submit" value="Go" className="btn btn-primary" />
            </span>
          </div>
          <input type="hidden" name="sitesearch" value="daspdp.org" />
        </form>
        <ul className="list-inline mt1">
          <li className="list-inline-item text-muted">
            <Link to={window.URLS['pd:home']()}>Home</Link>
          </li>
          <li className="list--item text-muted">|</li>
          <li className="list-inline-item text-muted">
            <a href={window.URLS['accounts:login']()}>Login</a>
          </li>
          <li className="list-inline-item text-muted">|</li>
          <li className="list-inline-item text-muted">
            <a href={window.URLS['accounts:register']()}>Register</a>
          </li>
        </ul>
        <small>
          RIT DAS PDP &copy; Copyright 2015 <a href={window.URLS['pd:home']()}>DAS PDP at RIT</a> -
          All rights reserved
        </small>
      </div>
    </div>
  </DocumentTitle>
);

export default NotFound;
