import React from 'react';
import { Link } from 'react-router-dom';
import { ResourcesPage } from '../ResourcesPage';
import { PageHeader } from 'components';

export const FormsIndex = () => (
  <ResourcesPage>
    <PageHeader.Basic title="Forms" />
    <p>
      Pick a form to get started. Email completed forms to{' '}
      <a href="mailto:daspd@rit.edu">the PDP Manager</a>.
    </p>
    <ul className="nav">
      <li>
        <Link to={window.URLS['pd:resources_forms']('academic-coursework')}>
          <i className="fa fa-angle-right icon-space-r" />
          Academic Coursework Activity Report
        </Link>
      </li>
      <li>
        <Link to={window.URLS['pd:resources_forms']('facilitator-plan')}>
          <i className="fa fa-angle-right icon-space-r" />
          Facilitator's Continuing Education Activity Plan
        </Link>
      </li>
      <li>
        <Link to={window.URLS['pd:resources_forms']('sponsor-plan')}>
          <i className="fa fa-angle-right icon-space-r" />
          Sponsor's Continuing Education Activity Plan
        </Link>
      </li>
      <li>
        <Link to={window.URLS['pd:resources_forms']('independent-report')}>
          <i className="fa fa-angle-right icon-space-r" />
          Independent Study Activity Report
        </Link>
      </li>
      <li>
        <Link to={window.URLS['pd:resources_forms']('rid-activity')}>
          <i className="fa fa-angle-right icon-space-r" />
          RID Activity Report Form
        </Link>
      </li>
      <li>
        <Link to={window.URLS['pd:resources_forms']('rid-pinra')}>
          <i className="fa fa-angle-right icon-space-r" />
          RID PINRA Form
        </Link>
      </li>
      <li>
        <a href={`${process.env.STATIC_URL}files/forms/Seminar%20Proposal%20Form.docx`}>
          <i className="fa fa-file-word-o icon-space-r" />
          Seminar Proposal Form
        </a>
      </li>
    </ul>
    <h2 className="section-header">Form Resources</h2>
    <p>The below resources may be useful when completing forms.</p>
    <ul className="nav">
      <li>
        <a href={`${process.env.STATIC_URL}files/forms/PDC%20Handout.pdf`}>
          <i className="fa fa-file-pdf-o" />
          &nbsp;&nbsp;CEUs - Professional vs. General
        </a>
      </li>
      <li>
        <a href={`${process.env.STATIC_URL}files/forms/Blooms%20Taxonomy.pdf`}>
          <i className="fa fa-file-pdf-o" />
          &nbsp;&nbsp;Active verbs to craft Educational Objectives
        </a>
      </li>
    </ul>
  </ResourcesPage>
);
