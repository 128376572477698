import Loadable from 'react-loadable';
import { Loading } from 'components';

const AsyncSurveyPage = Loadable({
  loader: () => import('./SurveyPage'),
  loading: Loading,
});

const AsyncCompleteSurveyPage = Loadable({
  loader: () => import('./CompleteSurveyPage'),
  loading: Loading,
});

export { AsyncSurveyPage, AsyncCompleteSurveyPage };
