import React from 'react';
import PropTypes from 'prop-types';
import { PageHeader } from 'components';

export const Form = ({ header, forms, children }) => {
  return (
    <>
      <PageHeader.Basic title={header} />
      <p className="mt1">
        <strong>Downloads:&nbsp;&nbsp;</strong>
      </p>
      {forms.map(({ title, url }) => {
        let faFileIcon = 'fa-file-o';
        if (url.includes('.pdf')) {
          faFileIcon = 'fa-file-pdf-o';
        } else if (url.includes('.doc')) {
          faFileIcon = 'fa-file-word-o';
        }
        return (
          <div key={url}>
            <a href={`${process.env.STATIC_URL}${url}`}>
              <i className={`fa ${faFileIcon} icon-space-r`} />
              {title}
            </a>
          </div>
        );
      })}
      <div className="mb1" />
      {children}
    </>
  );
};

Form.propTypes = {
  header: PropTypes.string.isRequired,
  forms: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ),
  children: PropTypes.node.isRequired,
};
