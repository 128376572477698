import { Events } from 'api';
import { asyncActionType } from 'store/actions';

export const EXPLORE_FILTER = 'EXPLORE_FILTER';
export const EVENTS_EXPLORE_DETAIL_UPDATE = 'EVENTS_EXPLORE_DETAIL_UPDATE';
export const FETCH_EVENTS_EXPLORE = asyncActionType('FETCH_EVENTS_EXPLORE');
export const SYNC_EXPLORE_FILTERS = 'SYNC_EXPLORE_FILTERS';

export const exploreFilter = (filter) => {
  return {
    type: EXPLORE_FILTER,
    filter,
  };
};

export const eventsExploreDetailUpdate = (event) => {
  return {
    type: EVENTS_EXPLORE_DETAIL_UPDATE,
    event,
  };
};

export const eventsExplorePending = (bool) => {
  return {
    type: FETCH_EVENTS_EXPLORE.PENDING,
    bool,
  };
};

export const eventsExploreError = (bool) => {
  return {
    type: FETCH_EVENTS_EXPLORE.ERROR,
    bool,
  };
};

export const eventsExploreSuccess = (data) => {
  return {
    type: FETCH_EVENTS_EXPLORE.SUCCESS,
    data,
  };
};

export const syncExploreFilters = (filters) => {
  return {
    type: SYNC_EXPLORE_FILTERS,
    events: filters.events,
    options: filters.options,
    searchTerms: filters.searchTerms,
  };
};

export const requestEventsExplore = (filter) => (dispatch, getState) => {
  const { eventsExplore } = getState();
  if (filter === eventsExplore.eventScope) {
    return;
  }
  dispatch(exploreFilter(filter));
  dispatch(eventsExplorePending(true));
  Events.explore(filter)
    .then((data) => {
      dispatch(eventsExploreSuccess(data));
    })
    .catch((err) => {
      dispatch(eventsExploreError(true));
      throw err;
    });
};
