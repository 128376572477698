import React from 'react';
import { Form } from './Form';
import { ResourcesPage } from '../ResourcesPage';

export const RidActivityForm = () => (
  <ResourcesPage>
    <Form
      header="RID Activity Report Form"
      forms={[
        {
          title: 'RID Activity Report Form.pdf',
          url: 'files/forms/RID%20Activity%20Report%20Form.pdf',
        },
      ]}
    >
      <p>
        The Activity Report form is filled out for each event by the PDP Manager and lists al of the
        information required for participants in the event to earn RID CEU credits. At the
        conclusion of the IST, workshop or small group pdc, participants who are eligible for and
        who wish to receive RID CEU credit must sign the form and provide their RID Member number.
      </p>
      <p>
        Failure of participants to provide legible signature and accurate, legible RID Member Number
        may result in a loss of expected credit.
      </p>
      <p>
        Facilitators facilitating an IST, or workshop topic for the first time will receive General
        Studies CEU credits for their teaching. Facilitators must provide their RID Member Number
        and check the box indicating their desire and eligibility for credits.
      </p>
    </Form>
  </ResourcesPage>
);
