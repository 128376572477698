import { asyncActionType } from 'store/actions';
import { Affiliations } from 'api';

export const FETCH_AFFILIATION_LIST = asyncActionType('FETCH_AFFILIATION_LIST');

export const affiliationListPending = (bool) => {
  return {
    type: FETCH_AFFILIATION_LIST.PENDING,
    bool,
  };
};

export const affiliationListError = (bool) => {
  return {
    type: FETCH_AFFILIATION_LIST.ERROR,
    bool,
  };
};

export const affiliationListSuccess = (data) => {
  return {
    type: FETCH_AFFILIATION_LIST.SUCCESS,
    data,
  };
};

export const affiliationListThunk = () => (dispatch, getState) => {
  const affiliations = getState().affiliations.list;
  if (affiliations.length) {
    return Promise.resolve(affiliations);
  }
  dispatch(affiliationListPending(true));
  return Affiliations.list()
    .then((data) => {
      dispatch(affiliationListSuccess(data));
    })
    .catch((err) => {
      dispatch(affiliationListError(true));
      throw err;
    });
};
