import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Alert = ({ className, type, children, icon, dismissable }) => (
  <div className={classnames('alert', type && `alert-${type}`, className)} role="alert">
    {dismissable && (
      <button type="button" className="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">×</span>
      </button>
    )}
    {icon && <i className={`icon-space-r fa ${icon}`} />}
    {children}
  </div>
);

Alert.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
  type: PropTypes.string,
  children: PropTypes.any,
  dismissable: PropTypes.bool,
};

Alert.defaultProps = {
  type: 'default',
};

export default Alert;
