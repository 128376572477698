import React from 'react';
import { Form } from './Form';
import { ResourcesPage } from '../ResourcesPage';

export const AcademicCourseworkForm = () => (
  <ResourcesPage>
    <Form
      header="Academic Coursework Activity Report"
      forms={[
        {
          title: 'Academic Coursework Instructions for Participants.doc',
          url: 'files/forms/Academic%20Coursework%20Instructions%20for%20Participants.doc',
        },
        {
          title: 'Academic Coursework Activity Report.docx',
          url: 'files/forms/Academic%20Coursework%20Activity%20Report.docx',
        },
      ]}
    >
      <h4>Step one</h4>
      <p>
        Choose the course you want to attend at an accredited college or university. The course does
        not have to pertain strictly to interpreting; it can be a course in Linguistics, Business,
        Psychology, Social Work, etc. As long as the course is educationally beneficial to you as an
        interpreter, it can earn CEUs for you!
      </p>
      <h4>Step two</h4>
      <p>
        Contact an RID Approved Sponsor to secure their agreement to process the paperwork for the
        course. Be sure to discuss the Content Area for which you are applying (i.e. Professional
        Studies or General Studies) and how many CEUs will be earned. The standard formula for
        determining the number of CEUs per course is as follows: 1.5 CEUs per credit hour for a
        course taken in a semester session. 1.0 CEUs per credit hour for a course taken in a quarter
        session.
      </p>
      <h4>Step three</h4>
      <p>
        Complete the Academic Coursework Activity Report form, which can be downloaded above. Attach
        the required documentation such as a course outline and proof that the college/university is
        accredited through the Council for Higher Education Accreditation (CHEA). Please visit
        www.chea.org to obtain this information.
      </p>
      <h4>Step four</h4>
      <p>
        Upon completion of the course, attach a copy of the transcript showing successful completion
        of the course with a grade of “C” or higher (2.0 or higher). Return the form and
        documentation to your Sponsor, who will file the completed paperwork through their sponsor
        account online at myaccount.rid.org . The RID Approved Sponsor signs the bottom portion of
        the form, indicating their agreement and verification to sponsor and award CEU credits.
      </p>
      <h4>Note</h4>
      <p>
        Academic Coursework can be processed for CEUs after the course has actually been completed.
        However, the coursework must have been taken during the CMP participant’s current cycle. If
        an individual&#39;s cycle will end December 31 of the year, the paperwork must be submitted
        no later than December 31.
      </p>
    </Form>
  </ResourcesPage>
);
