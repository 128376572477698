import { getCookie } from 'helpers/_getCookie';

$(document).ready(function() {
  const removeFromCart = function(e) {
    e.preventDefault();
    const csrftoken = getCookie('csrftoken');
    const container = $(this).closest('.registration-box');
    $.ajaxSetup({
      beforeSend: function(xhr, settings) {
        if (!this.crossDomain) {
          xhr.setRequestHeader('X-CSRFToken', csrftoken);
        }
      },
    });
    const data = { events: $(this).data('event-id') };
    $.ajax({
      url: $(this).attr('action'),
      data: data,
      error: function(response) {
        window.show_stack_topleft(
          'Error',
          'Could not be removed from cart. Please try again later.',
          'error'
        );
        return false;
      },
      success: function(response) {
        container.remove();
        window.show_stack_topleft('Removed', 'The event was removed from your cart.', 'success');
        $('button.remove-from-cart').attr('disabled', true);
        $('#cart').text(parseInt($('#cart').text()) - 1);
        if (parseInt($('#cart').text()) === 0) {
          $('#empty').css('display', 'block');
        }
        return false;
      },
      type: $(this).attr('method'),
    });
  };
  $('form.remove-cart').submit(removeFromCart);
});
