import React from 'react';
import { PageHeader } from 'components';
import { ResourcesPage } from './ResourcesPage';

export const BookLibrary = () => (
  <ResourcesPage>
    <PageHeader.Basic title="DAS Book Library" />
    <p>
      Our library database is stored in an online service called{' '}
      <a target="_blank" href="https://cloud.collectorz.com/DASLibrary/books">
        Book Collector
      </a>
      . Book Collector allows you to sort and search on all of our libraries titles. Our library
      contains books on non-ASL topics such as Human Anatomy because those resources can be useful
      to learn unfamiliar terminology used in a classroom that you might need to translate.
    </p>
    <p>
      <a href="http://infoguides.rit.edu/prf.php?account_id=43304" target="_blank">
        Joan Naturale
      </a>{' '}
      manages our library and if you have any questions feel free to{' '}
      <a href="email:jxnwml@rit.edu">email</a> her.
    </p>
    <p>
      <a
        target="_blank"
        href="https://cloud.collectorz.com/DASLibrary/books"
        className="btn btn-default btn-gray"
      >
        Visit Library
      </a>
    </p>
  </ResourcesPage>
);
