import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap-modal';

const InfoModal = (props) => {
  return (
    <Modal show={props.modalOpen} onHide={props.onModalClose} modalPrefix="bootstrap-modal modal">
      <Modal.Header closeButton>
        <Modal.Title>{props.modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.children}</Modal.Body>
      <Modal.Footer>
        <Modal.Dismiss className="btn btn-default">Close</Modal.Dismiss>
      </Modal.Footer>
    </Modal>
  );
};

InfoModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  onModalClose: PropTypes.func.isRequired,
  modalTitle: PropTypes.string.isRequired,
  children: PropTypes.element,
};

export default InfoModal;
