import { combineReducers } from 'redux';
import {
  FETCH_PROFILE_LIST,
  FETCH_PROFILE_DETAIL,
  CLEAR_PROFILE_FILTERS,
  UPDATE_PROFILE_FILTER,
} from 'store/actions/accounts/profiles';
import { getName } from 'helpers/utils';
import { EventLabels } from 'helpers/dictionaries';

export const initialState = {
  isLoading: false,
  hasErrored: false,
  filters: {
    employee_type: Object.keys(EventLabels.EMPLOYEE_TYPES),
    manager: '',
    user: '',
    affiliation: '',
    seed: '',
  },
  previous: null,
  next: null,
  count: 0,
  page_size: 0,
};

export const keys = (state = initialState, action) => {
  let profileList;
  switch (action.type) {
    case FETCH_PROFILE_LIST.PENDING:
      return { ...state, isLoading: action.bool };
    case FETCH_PROFILE_LIST.SUCCESS:
      let { results: profiles, seed, ...rest } = action.data;
      profileList = profiles.reduce((accumulator, profile) => {
        profile.name = getName(profile, 'profile');
        accumulator[profile.slug] = profile;
        return accumulator;
      }, {});
      return {
        ...state,
        ...profileList,
        ...rest,
        filters: { ...state.filters, seed },
        isLoading: false,
      };
    case FETCH_PROFILE_LIST.ERROR:
      return { ...state, hasErrored: action.bool, isLoading: false };
    case FETCH_PROFILE_DETAIL.PENDING:
      return { ...state, isLoading: action.bool };
    case FETCH_PROFILE_DETAIL.SUCCESS:
      action.data.name = getName(action.data, 'profile');
      return { ...state, [action.data.slug]: action.data, isLoading: false, hasErrored: false };
    case FETCH_PROFILE_DETAIL.ERROR:
      return { ...state, hasErrored: action.bool, isLoading: false };
    case UPDATE_PROFILE_FILTER:
      return { ...state, filters: { ...state.filters, [action.name]: action.value } };
    case CLEAR_PROFILE_FILTERS:
      return { ...state, filters: { ...initialState.filters, search: state.filters.search } };
    default:
      return state;
  }
};

export const ids = (state = [], action) => {
  switch (action.type) {
    case FETCH_PROFILE_LIST.SUCCESS:
      return action.data.results.map((r) => r.slug);
    case FETCH_PROFILE_DETAIL.SUCCESS:
      return [...state, action.data.slug];
    default:
      return state;
  }
};

const profiles = combineReducers({
  keys,
  ids,
});

export default profiles;
