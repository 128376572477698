import React from 'react';
import Loading from './Loading';

export default (request) => (component) =>
  class withAPI extends React.Component {
    state = {
      data: null,
      isLoading: true,
      timedOut: false,
    };

    handleTimeout = () => {
      this.setState({ timedOut: true });
    };

    componentDidMount() {
      request(this.props).then((data) => {
        if (!this.state.timedOut) {
          this.setState({ isLoading: false, data });
        }
      });
    }

    render() {
      let { data, isLoading } = this.state;
      if (isLoading) {
        return <Loading />;
      }
      return React.createElement(component, { ...this.props, data });
    }
  };
