import React from 'react';

const RitNonDiscriminationStatement = (props) => {
  return (
    <p>
      Department of Access Services and the Professional Development Program (PDP) in accordance
      with{' '}
      <a href="https://www.rit.edu/nondiscrimination.html" target="_blank">
        RIT policy
      </a>
      .
    </p>
  );
};

export default RitNonDiscriminationStatement;
