import { FETCH_EVENT_DETAIL } from 'store/actions/events/detail';

const initialState = {
  isLoading: true,
  hasErrored: false,
  error: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EVENT_DETAIL.PENDING:
      return { ...state, isLoading: action.bool };
    case FETCH_EVENT_DETAIL.SUCCESS:
      return { ...state, event: action.data, isLoading: false };
    case FETCH_EVENT_DETAIL.ERROR:
      return { ...state, hasErrored: action.bool, error: action.error, isLoading: false };
    default:
      return state;
  }
};
