/*

Mass-toggle checkboxes.

Usage:
<button type="button" data-toggle-checkboxes=".checkbox-parent"></button>
<div class="checkbox-parent">
...checkbox inputs...
</div>
*/

$('[data-toggle-checkboxes]').on('click', function(e) {
  e.preventDefault();
  const targetParent = $($(this).data('toggle-checkboxes'));

  if (
    $(this)
      .children('i')
      .hasClass('fa-toggle-off')
  ) {
    $(this).get(0).lastChild.nodeValue = 'Toggle All Off';
    $(this)
      .children('i')
      .removeClass('fa-toggle-off')
      .addClass('fa-toggle-on');
    targetParent.find('input[type="checkbox"]').prop('checked', true);
  } else {
    $(this).get(0).lastChild.nodeValue = 'Toggle All On';
    $(this)
      .children('i')
      .removeClass('fa-toggle-on')
      .addClass('fa-toggle-off');
    targetParent.find('input[type="checkbox"]').prop('checked', false);
  }
});
