import {
  ACADEMICYEAR_FILTER,
  FETCH_ADMIN_EVENTS,
  ADD_EVENT,
  SYNC_ADMIN_FILTERS,
  ACADEMICSEASON_FILTER,
} from 'store/actions/events/admin';

import { defaultFilterOptions } from 'components/events/helpers';
import getSeason from 'helpers/get-season';
import formatAcademicYear from 'helpers/format-academic-year';
import filterEventsBySeason from 'helpers/filter-events-by-season';

const academicSeason = getSeason(new Date().getMonth() + 1);
const academicSeasons = [
  {
    key: 'Fall',
    title: 'Fall',
    active: academicSeason === 'Fall',
  },
  {
    key: 'Spring',
    title: 'Spring',
    active: academicSeason === 'Spring',
  },
  {
    key: 'Summer',
    title: 'Summer',
    active: academicSeason === 'Summer',
  },
];

let currentAcademicYear;
if (academicSeason === 'Spring' && new Date().getMonth() + 1 === 12) {
  currentAcademicYear = new Date().getFullYear(); // look forward to next Spring during December
} else if (academicSeason === 'Summer') {
  currentAcademicYear = new Date().getFullYear() - 1;
} else {
  currentAcademicYear =
    academicSeason === 'Fall' ? new Date().getFullYear() : new Date().getFullYear() - 1;
}

export const initialState = {
  events: [], // All events for a given year
  seasonalEvents: [], // Events scoped down to a season
  filteredEvents: [], // Events filtered by search terms
  academicSeasons: academicSeasons,
  academicSeason: academicSeason,
  eventScope: formatAcademicYear(currentAcademicYear),
  isLoading: true,
  hasErrored: false,
  options: defaultFilterOptions,
  searchTerms: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ADMIN_EVENTS.PENDING:
      return { ...state, isLoading: action.bool };
    case FETCH_ADMIN_EVENTS.SUCCESS:
      return {
        ...state,
        events: action.data,
        seasonalEvents: filterEventsBySeason(action.data, state.academicSeason),
        isLoading: false,
      };
    case FETCH_ADMIN_EVENTS.ERROR:
      return { ...state, hasErrored: action.bool, isLoading: false };
    case ADD_EVENT:
      return { ...state, events: [action.event, ...state.events] };
    case ACADEMICYEAR_FILTER:
      return { ...state, eventScope: action.academicyear };
    case ACADEMICSEASON_FILTER:
      const { academicSeason } = action;
      const academicSeasons = state.academicSeasons.map((o) => ({
        ...o,
        active: academicSeason === o.key,
      }));
      const seasonalEvents = filterEventsBySeason(state.events, academicSeason);
      return { ...state, academicSeason, academicSeasons, seasonalEvents };
    case SYNC_ADMIN_FILTERS:
      return {
        ...state,
        // This is the ONLY place filteredEvents should be updated.
        // This reducer doesn't handle the event filtering - the component that dispatches SYNC_ADMIN_FILTERS does.
        filteredEvents: action.events,
        options: action.options,
        searchTerms: action.searchTerms,
      };
    default:
      return state;
  }
};
