import { Events, notifyError, notifySuccess } from 'api';
import { asyncActionType } from 'store/actions';

export const ACADEMICYEAR_FILTER = 'ACADEMICYEAR_FILTER';
export const ACADEMICSEASON_FILTER = 'ACADEMICSEASON_FILTER';
export const ADD_EVENT = 'ADD_EVENT';
export const SYNC_ADMIN_FILTERS = 'SYNC_ADMIN_FILTERS';
export const FETCH_ADMIN_EVENTS = asyncActionType('FETCH_ADMIN_EVENTS');

export const academicyearFilter = (academicyear) => {
  return {
    type: ACADEMICYEAR_FILTER,
    academicyear,
  };
};

export const academicSeasonFilter = (academicSeason) => {
  return {
    type: ACADEMICSEASON_FILTER,
    academicSeason,
  };
};

export const addEvent = (event) => {
  return {
    type: ADD_EVENT,
    event,
  };
};

export const syncAdminFilters = (filters) => {
  return {
    type: SYNC_ADMIN_FILTERS,
    events: filters.events,
    options: filters.options,
    searchTerms: filters.searchTerms,
  };
};

export const eventsAdminPending = (bool) => {
  return {
    type: FETCH_ADMIN_EVENTS.PENDING,
    bool,
  };
};

export const eventsAdminError = (bool) => {
  return {
    type: FETCH_ADMIN_EVENTS.ERROR,
    bool,
  };
};

export const eventsAdminSuccess = (data) => {
  return {
    type: FETCH_ADMIN_EVENTS.SUCCESS,
    data,
  };
};

export const filterAcademicSeason = (academicSeason) => (dispatch, getState) => {
  const { eventsAdmin } = getState();
  if (academicSeason === eventsAdmin.academicSeason) {
    return;
  }
  dispatch(academicSeasonFilter(academicSeason));
};

export const copyEventThunk = (eventId, data) => (dispatch, getState) => {
  return Events.copy(eventId, { ...data })
    .then((event) => {
      dispatch(addEvent(event));
      notifySuccess(`Copy made, new event ${event.title} created.`);
    })
    .catch((err) => notifyError(err));
};

export const requestEventsAdmin = (academicyear, refresh = false) => (dispatch, getState) => {
  const { eventsAdmin } = getState();
  if (!eventsAdmin.isLoading && academicyear === eventsAdmin.eventScope && !refresh) {
    return null;
  }
  dispatch(academicyearFilter(academicyear));
  dispatch(eventsAdminPending(true));
  Events.admin(academicyear)
    .then((data) => {
      dispatch(eventsAdminSuccess(data));
    })
    .catch((err) => {
      dispatch(eventsAdminError(true));
      throw err;
    });
};
