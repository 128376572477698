import { asyncActionType } from 'store/actions';
import { Carousels, notifyError } from 'api';

export const FETCH_CAROUSEL_LIST = asyncActionType('FETCH_CAROUSEL_LIST');

export const carouselListPending = (bool) => {
  return {
    type: FETCH_CAROUSEL_LIST.PENDING,
    bool,
  };
};

export const carouselListError = (bool) => {
  return {
    type: FETCH_CAROUSEL_LIST.ERROR,
    bool,
  };
};

export const carouselListSuccess = (carousels) => {
  return {
    type: FETCH_CAROUSEL_LIST.SUCCESS,
    carousels,
  };
};

export const carouselListThunk = () => (dispatch, getState) => {
  dispatch(carouselListPending(true));
  return Carousels.list()
    .then((data) => {
      dispatch(carouselListSuccess(data));
      return data;
    })
    .catch((err) => {
      notifyError(err);
      dispatch(carouselListError(true));
    });
};
