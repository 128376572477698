import { asyncActionType } from 'store/actions';
import { Answer, Survey, notifyError } from 'api';

export const FETCH_ANSWER_LIST = asyncActionType('FETCH_ANSWER_LIST');
export const FETCH_ANSWER_DETAIL = asyncActionType('FETCH_ANSWER_DETAIL');
export const ANSWER_UPDATE = 'ANSWER_UPDATE';

export const answerUpdate = (answerId, data) => {
  return {
    type: ANSWER_UPDATE,
    answerId,
    data,
  };
};

export const answerDetailPending = (answerId, bool) => {
  return {
    type: FETCH_ANSWER_DETAIL.PENDING,
    answerId,
    bool,
  };
};

export const answerDetailError = (answerId, bool) => {
  return {
    type: FETCH_ANSWER_DETAIL.ERROR,
    answerId,
    bool,
  };
};

export const answerDetailSuccess = (answer) => {
  return {
    type: FETCH_ANSWER_DETAIL.SUCCESS,
    answer,
  };
};

export const answerDetail = (answerId) => (dispatch, getState) => {
  dispatch(answerDetailPending(answerId, true));
  return Answer.get(answerId)
    .then((data) => {
      dispatch(answerDetailSuccess(data));
      return data;
    })
    .catch((err) => {
      notifyError(err);
      dispatch(answerDetailError(answerId, true));
    });
};

export const answerListPending = (bool) => {
  return {
    type: FETCH_ANSWER_LIST.PENDING,
    bool,
  };
};

export const answerListError = (bool) => {
  return {
    type: FETCH_ANSWER_LIST.ERROR,
    bool,
  };
};

export const answerListSuccess = (answers) => {
  return {
    type: FETCH_ANSWER_LIST.SUCCESS,
    answers,
  };
};

export const surveyAnswersListThunk = (surveyId) => (dispatch, getState) => {
  dispatch(answerListPending(true));
  return Survey.answersList(surveyId)
    .then((data) => {
      dispatch(answerListSuccess(data));
      return data;
    })
    .catch((err) => {
      notifyError(err);
      dispatch(answerListError(true));
    });
};
