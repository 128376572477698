import PNotify from 'pnotify';
import 'pnotify/dist/pnotify.confirm.js';

PNotify.prototype.options.styling = 'fontawesome';

var stackTopleft = { dir1: 'down', dir2: 'right', push: 'top' };
window.show_stack_topleft = function(title, text, type) {
  var opts = {
    title: title,
    text: text,
    addclass: 'stack-topleft',
    stack: stackTopleft,
    type: type,
  };
  new PNotify(opts); // eslint-disable-line no-new
};

window.PNotify = PNotify;
