import Loadable from 'react-loadable';
import { Loading } from 'components';

const Registrations = Loadable({
  loader: () => import('./Registrations'),
  loading: Loading,
});

const RegistrationsArchive = Loadable({
  loader: () => import('./RegistrationsArchive'),
  loading: Loading,
});

export { Registrations, RegistrationsArchive };
