import React from 'react';
import { PinraEvents } from 'api';
import { PinraInstructions } from './PinraInstructions';
import { failedAssertion } from 'helpers/reporting';
import { Formik } from 'formik';
import { object, string, number } from 'yup';
import cx from 'classnames';
import Modal from 'react-bootstrap-modal';

const pinraSchema = object().shape({
  participantName: string()
    .required('Participant Name is required')
    .max(255)
    .trim(),
  ridMemberNumber: number()
    .required('RID Member # is required')
    .positive()
    .integer(),
  participantAddress: string()
    .required('Participant Address is required')
    .max(255)
    .trim(),
  email: string()
    .required('Email is required')
    .max(255)
    .trim(),
  phoneNumber: string()
    .required('Phone Number is required')
    .max(255)
    .trim(),
  activityName: string()
    .required('Activity/Conference Name is required')
    .max(255)
    .trim(),
  activityTheme: string()
    .required('Activity/Conference Theme or Focus is required')
    .max(255)
    .trim(),
  activityDatesTimes: string()
    .required('Dates and Times of activity are required')
    .max(255)
    .trim(),
  ceusToBeAwarded: number()
    .required("Total number of CEU's to be awarded is required")
    .positive(),
  contentArea: string().required('Select a Content Area'),
  participantSignature: string()
    .required('Signature is required')
    .max(255)
    .trim(),
});

const mainInputs = [
  { name: 'participantName', label: 'Participant Name', placeholder: 'Jane Doe', type: 'text' },
  { name: 'ridMemberNumber', label: 'RID Member #', placeholder: '12345', type: 'text' },
  {
    name: 'participantAddress',
    label: 'Participant Address(include city/state)',
    placeholder: '123 Main Street Rochester Ny, 14567',
    type: 'text',
  },
  { name: 'email', label: 'RIT Email', placeholder: 'jmddis@rit.edu', type: 'email' },
  { name: 'phoneNumber', label: 'Phone Number', placeholder: '(585) 222-2222', type: 'text' },
  {
    name: 'activityName',
    label: 'Activity/Conference Name',
    placeholder: 'Workshop on Strategies for Exploring the Complexities of Ethical Decision Making',
    type: 'text',
  },
  {
    name: 'activityTheme',
    label: 'Activity/Conference Theme or Focus(Attach brochure/flyer)',
    placeholder: 'Professional Practice',
    type: 'text',
  },
  {
    name: 'activityDatesTimes',
    label: 'Date(s), Start and End Time(s) of activity you will attend',
    placeholder: 'April 4th, 2021 6pm-8pm',
    type: 'text',
  },
  {
    name: 'ceusToBeAwarded',
    label: "Total number of CEU's to be awarded",
    placeholder: '.2',
    type: 'text',
  },
];

export class PinraForm extends React.Component {
  constructor() {
    super();
    this.state = {
      disabled: true,
      slug: '',
      showSuccess: false,
    };
  }

  async componentDidMount() {
    try {
      PinraEvents.current().then((res) => {
        this.setState({ slug: res.profile.slug, listLoading: false, listError: false });
      });
    } catch (err) {
      failedAssertion(err);
      this.setState({ listError: true, listLoading: false });
    }
  }

  showSuccessModal = () => this.setState({ showSuccess: true });
  hideSuccessModal = () => this.setState({ showSuccess: false });
  handleSubmit = async (data, { resetForm }) => {
    data['slug'] = this.state.slug;
    try {
      await PinraEvents.post(data);
      this.showSuccessModal();
      // window.show_stack_topleft(
      //   'Success',
      //   'Thanks! Keep an eye on your PD dashboard for updates.',
      //   'success'
      // );
      resetForm({});
    } catch (err) {
      window.show_stack_topleft('Error', 'Failed to submit, please try again later.', 'error');
      failedAssertion(err, data);
    }
  };

  enableSubmit = () => {
    this.setState({ disabled: false });
    var win = window.open(
      'https://docs.google.com/forms/d/e/1FAIpQLSej5ANpLPVOUz8xAtDSZH8ol3SkQFbCB8A0Gr0JMJzuNnsqWw/viewform?usp=sf_link​',
      '_blank'
    );
    win.focus();
  };

  render() {
    return (
      <>
        <PinraInstructions />
        <Formik
          initialValues={{
            participantName: '',
            participantAddress: '',
            ridMemberNumber: '',
            email: '',
            phoneNumber: '',
            activityName: '',
            activityTheme: '',
            activityDatesTimes: '',
            ceusToBeAwarded: '',
            contentArea: '',
            participantSignature: '',
          }}
          validationSchema={pinraSchema}
          onSubmit={this.handleSubmit}
        >
          {({ values, touched, errors, handleChange, handleBlur, handleSubmit }) => (
            <form onSubmit={handleSubmit} className="form">
              {mainInputs.map(({ name, label, placeholder, type }) => (
                <div
                  key={name}
                  className={cx('form-group', { 'has-error': touched[name] && errors[name] })}
                >
                  <label className="control-label" htmlFor={`id_${name}`}>
                    {label}
                  </label>
                  <input
                    type={type}
                    name={name}
                    value={values[name] || ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={placeholder}
                    id={`id_${name}`}
                    className="form-control"
                  />
                  {touched[name] && errors[name] && (
                    <span className="help-block">{errors[name]}</span>
                  )}
                </div>
              ))}
              <div
                className={cx('form-group', {
                  'has-error': touched.contentArea && errors.contentArea,
                })}
              >
                <label className="control-label" htmlFor="id_contentArea">
                  Content Area
                </label>
                <div role="group" id="id_contentArea">
                  <label>
                    <input
                      type="radio"
                      name="contentArea"
                      value="p"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    &nbsp;Professional Studies&nbsp;
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="contentArea"
                      value="g"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    &nbsp;General Studies
                  </label>
                </div>
                {touched.contentArea && errors.contentArea && (
                  <span className="help-block">{errors.contentArea}</span>
                )}
              </div>
              <div
                className={cx('form-group', {
                  'has-error': touched['participantSignature'] && errors['participantSignature'],
                })}
              >
                <label className="control-label" htmlFor="id_participantSignature">
                  Participant Signature(Type your name)
                </label>
                <input
                  type="text"
                  name="participantSignature"
                  value={values['participantSignature'] || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Jane Doe"
                  id="id_participantSignature"
                  className="form-control"
                />
                {touched['participantSignature'] && errors['participantSignature'] && (
                  <span className="help-block">{errors['participantSignature']}</span>
                )}
              </div>
              <div
                className={cx('form-group', {
                  'has-error': touched.documents && errors.documents,
                })}
              >
                <label className="control-label" htmlFor={'documents-upload'}>
                  Upload your event brochure or flyer(Required)
                </label>
                <br />
                <button type="button" className="btn btn-secondary" onClick={this.enableSubmit}>
                  Upload using Google Forms
                </button>
              </div>
              <div className="form-group">
                <button
                  className="btn btn-primary mt-5"
                  type="submit"
                  disabled={this.state.disabled}
                >
                  <i className="fa fa-plus icon-space-r" />
                  Submit
                </button>
              </div>
            </form>
          )}
        </Formik>
        <Modal
          show={this.state.showSuccess}
          onHide={this.hideSuccessModal}
          modalPrefix="bootstrap-modal modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>Success!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Your PINRA has been submitted. The status of your PINRA can be found in your PD
            Dashboard
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-primary" onClick={this.hideSuccessModal}>
              Ok
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
