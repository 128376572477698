import { EventDictionary, EventLabels } from 'helpers/dictionaries';

export const ceuTitle = (ceus, ceuType) => {
  if (ceus <= 0) {
    return 'No CEUs Offered';
  }

  let text = `${ceus} ${ceuType} CEU`;
  if (ceus !== 1) {
    text += 's';
  }

  return text;
};

export const dictFilters = [
  ['WEEK_DAYS', 'day'],
  ['EVENT_TYPES', 'type'],
  ['REG_STATUS', 'registration_status'],
  ['STRAND_TYPES', 'strand_type'],
  ['PUB_STATUS', 'publication_status'],
];
export const filterDelimiter = '%%%%';
export const filterReturnValue = (type, value) => {
  return `${type}${filterDelimiter}${value}`;
};

export const defaultFilterOptions = {};
dictFilters.forEach((filter) => {
  const dictKey = filter[0];
  const selectType = filter[1];
  const types = EventDictionary[dictKey];
  const labels = EventLabels[dictKey];

  defaultFilterOptions[selectType] = [];

  for (let key in types) {
    const type = types[key];
    defaultFilterOptions[selectType].push({
      type: selectType,
      value: labels[type],
      selected: false,
      returnValue: filterReturnValue(selectType, type),
    });
  }
});

export const filterButtonLabels = {
  title: 'Title includes',
  type: 'Event type',
  strand_type: 'Strand',
  registration_status: 'My registration status',
  day: 'Meeting day',
  publication_status: 'Status',
};

export const filterTypeLabels = {
  title: 'Title includes',
  type: 'Event type',
  strand_type: 'Strand',
  registration_status: 'Status',
  day: 'Day',
  publication_status: 'Status',
};

export const EXPLORE_SCOPE_OPTIONS = [
  {
    key: 'registrationUpcoming',
    title: 'Registration Upcoming',
    sentence_part: 'with',
  },
  {
    key: 'registrationOpen',
    title: 'Registration Open',
    sentence_part: 'with',
  },
  {
    key: 'addDropOpen',
    title: 'Add Drop Open',
    sentence_part: 'with',
  },
  {
    key: 'upcoming',
    title: 'Upcoming',
    sentence_part: '',
  },
  {
    key: 'inSession',
    title: 'In Session',
    sentence_part: 'currently',
  },
];
