import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Radio, RadioGroup } from 'react-radio-group';

const RadioButtonGroup = ({ name, options, selectedValue, ...props }) => {
  return (
    <RadioGroup name={name} selectedValue={selectedValue} className="btn-group">
      {options.map((option) => {
        const isSelected = option[0] === selectedValue;
        const className = classNames('btn', {
          'btn-default': !isSelected,
          'btn-primary': isSelected,
        });
        return (
          <label key={option[0]} className={className}>
            <Radio {...props} value={option[0]} className="events-radio-group__radio" />
            {option[1]}
          </label>
        );
      })}
    </RadioGroup>
  );
};

RadioButtonGroup.propTypes = {
  options: PropTypes.arrayOf(PropTypes.array),
  selectedValue: PropTypes.string,
  name: PropTypes.string.isRequired,
};

export default RadioButtonGroup;
