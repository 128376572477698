import { asyncActionType } from 'store/actions';
import { requestCurrentUser } from 'store/actions/accounts/user';
import { requestEventsDetail } from 'store/actions/events/detail';
import { Events, Employees, Registration, notifyError, notifySuccess } from 'api';
import { EventDictionary } from 'helpers/dictionaries';

const { REG_STATUS: STATUS } = EventDictionary;

export const REGISTRATION_UPDATE = 'REGISTRATION_UPDATE';
export const FETCH_REGISTRATION_DETAIL = asyncActionType('FETCH_REGISTRATION_DETAIL');
export const FETCH_REGISTRATION_LIST = asyncActionType('FETCH_REGISTRATION_LIST');
export const FETCH_REGISTRATION_USER = asyncActionType('FETCH_REGISTRATION_USER');
export const FETCH_REGISTRATION_RECENT = asyncActionType('FETCH_REGISTRATION_RECENT');
export const FETCH_REGISTRATION_BYYEAR = asyncActionType('FETCH_REGISTRATION_BYYEAR');

export const registrationUpdate = (registration) => {
  return {
    type: REGISTRATION_UPDATE,
    registration,
  };
};

export const registrationListPending = (bool) => {
  return {
    type: FETCH_REGISTRATION_LIST.PENDING,
    bool,
  };
};

export const registrationListError = (bool, error) => {
  return {
    type: FETCH_REGISTRATION_LIST.ERROR,
    bool,
    error,
  };
};

export const registrationListSuccess = (data) => {
  return {
    type: FETCH_REGISTRATION_LIST.SUCCESS,
    data,
  };
};

export const registrationDetailPending = (bool) => {
  return {
    type: FETCH_REGISTRATION_DETAIL.PENDING,
    bool,
  };
};

export const registrationDetailError = (bool, error) => {
  return {
    type: FETCH_REGISTRATION_DETAIL.ERROR,
    bool,
    error,
  };
};

export const registrationDetailSuccess = (data) => {
  return {
    type: FETCH_REGISTRATION_DETAIL.SUCCESS,
    data,
  };
};

export const registrationUserPending = (bool) => {
  return {
    type: FETCH_REGISTRATION_USER.PENDING,
    bool,
  };
};

export const registrationUserError = (bool, error) => {
  return {
    type: FETCH_REGISTRATION_USER.ERROR,
    bool,
    error,
  };
};

export const registrationUserSuccess = (data) => {
  return {
    type: FETCH_REGISTRATION_USER.SUCCESS,
    data,
  };
};

export const recentRegistrationsPending = (bool) => {
  return {
    type: FETCH_REGISTRATION_RECENT.PENDING,
    bool,
  };
};

export const recentRegistrationsError = (bool, error) => {
  return {
    type: FETCH_REGISTRATION_RECENT.ERROR,
    bool,
    error,
  };
};

export const recentRegistrationsSuccess = (data) => {
  return {
    type: FETCH_REGISTRATION_RECENT.SUCCESS,
    data,
  };
};

export const registrationsByYearPending = (bool) => {
  return {
    type: FETCH_REGISTRATION_BYYEAR.PENDING,
    bool,
  };
};

export const registrationsByYearError = (bool, error) => {
  return {
    type: FETCH_REGISTRATION_BYYEAR.ERROR,
    bool,
    error,
  };
};

export const registrationsByYearSuccess = (data) => {
  return {
    type: FETCH_REGISTRATION_BYYEAR.SUCCESS,
    data,
  };
};

export const registrationAccept = (registrationId) => (dispatch, getState) => {
  return Registration.managerUpdate(registrationId, { status: STATUS.APPROVED })
    .then((res) => {
      dispatch(registrationUpdate(res));
      notifySuccess('Accepted registration.');
    })
    .catch((err) => {
      notifyError(err);
      dispatch(registrationListError(true, err));
    });
};

export const registrationManagerApprove = (registrationId) => (dispatch, getState) => {
  return Registration.managerUpdate(registrationId, { status: STATUS.APPROVED_PENDING_REVIEW })
    .then((res) => {
      dispatch(registrationUpdate(res));
      notifySuccess('Approved registration.');
    })
    .catch((err) => {
      notifyError(err);
      dispatch(registrationListError(true, err));
    });
};

export const registrationDeny = (registrationId, data) => (dispatch, getState) => {
  return Registration.managerUpdate(registrationId, {
    pk: registrationId,
    status: STATUS.DENIED,
    ...data,
  })
    .then((res) => {
      dispatch(registrationUpdate(res));
      notifySuccess('Denied registration.');
    })
    .catch((err) => {
      notifyError(err);
      dispatch(registrationListError(true, err));
    });
};

export const registrationListThunk = (eventId) => (dispatch, getState) => {
  if (getState().registrations.error) {
    dispatch(registrationListError(false, null));
  }
  dispatch(registrationListPending(true));
  dispatch(requestCurrentUser());
  dispatch(requestEventsDetail(eventId));
  return Events.registrationList(eventId)
    .then((data) => {
      dispatch(registrationListSuccess(data));
    })
    .catch((err) => {
      notifyError(err);
      dispatch(registrationListError(true, err));
    });
};

export const requestEmployee = (userId) => (dispatch, getState) => {
  dispatch(registrationUserPending(true));
  return Employees.detail(userId)
    .then((data) => {
      dispatch(registrationUserSuccess(data));
    })
    .catch((err) => {
      notifyError(err);
      dispatch(registrationUserError(true, err));
    });
};

export const requestCurrentEmployee = () => async (dispatch, getState) => {
  const user = await dispatch(requestCurrentUser());
  return dispatch(requestEmployee(user.pk));
};

export const registrationDetailThunk = (eventId, registrationId) => (dispatch, getState) => {
  dispatch(registrationDetailPending(true));
  dispatch(requestCurrentUser());
  dispatch(requestEventsDetail(eventId));
  const { list } = getState().registrations;
  const item = list.find((item) => item.id === registrationId);
  if (item) {
    dispatch(requestEmployee(item.user)).then(() => dispatch(registrationDetailSuccess(item)));
  }
  return Registration.detail(registrationId)
    .then((data) => {
      dispatch(requestEmployee(data.user)).then(() => dispatch(registrationDetailSuccess(data)));
    })
    .catch((err) => {
      notifyError(err);
      dispatch(registrationDetailError(true, err));
    });
};

export const requestRecentRegistrations = () => (dispatch) => {
  dispatch(recentRegistrationsPending(true));
  return Registration.recent()
    .then((data) => {
      dispatch(recentRegistrationsSuccess(data));
    })
    .catch((err) => {
      notifyError(err);
      dispatch(recentRegistrationsError(true, err));
    });
};

export const requestRegistrationsByYear = (academicYear) => (dispatch) => {
  dispatch(registrationsByYearPending(true));
  return Registration.byYear(academicYear)
    .then((data) => {
      dispatch(registrationsByYearSuccess(data));
    })
    .catch((err) => {
      notifyError(err);
      dispatch(registrationsByYearError(true, err));
    });
};
