import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import DocumentTitle from 'react-document-title';

import { breadcrumbPropTypes } from 'helpers/proptypes';
import { failedAssertion } from 'helpers/reporting';

import BreadcrumbContext from './BreadcrumbContext';

const Breadcrumbs = ({ crumbs, reduxState }) => {
  if (reduxState && reduxState.breadcrumbs && reduxState.breadcrumbs.showBreadcrumbs === false) {
    return null;
  }

  return (
    <ol className="breadcrumb">
      {crumbs.map((crumb, index) => {
        let title = crumb.title;
        if (typeof crumb.title === 'function') {
          try {
            title = crumb.title(reduxState, crumb.params);
          } catch (error) {
            failedAssertion('Breadcrumbs: error rendering title', { error });
            return null;
          }
        }

        if (index === crumbs.length - 1) {
          return (
            <li className="active" key={index}>
              {title ? (
                <DocumentTitle title={`${title} – RIT DAS PDP`}>
                  <span>{title}</span>
                </DocumentTitle>
              ) : (
                title
              )}
            </li>
          );
        } else if (crumb.router) {
          return (
            <li key={index}>
              <Link to={crumb.url || '/'}>{title}</Link>
            </li>
          );
        } else {
          return (
            <li key={index}>
              <a href={crumb.url || '/'}>{title}</a>
            </li>
          );
        }
      })}
    </ol>
  );
};

Breadcrumbs.propTypes = {
  reduxState: PropTypes.object,
  crumbs: PropTypes.arrayOf(breadcrumbPropTypes).isRequired,
};

const mapStateToProps = (reduxState) => {
  return {
    reduxState,
  };
};

export const WrappedBreadcrumbs = connect(
  mapStateToProps,
  null
)((props) => (
  <BreadcrumbContext.Consumer>
    {({ crumbs }) => <Breadcrumbs crumbs={crumbs} {...props} />}
  </BreadcrumbContext.Consumer>
));

export default Breadcrumbs;
