const PDC = 'i';
const WORKSHOP = 'w';
const STEP_FORWARD_LEADERSHIP = 'l';
const SMALL_GROUP_PDC = 'g';
const UNKNOWN = 'u';

const NO_CREDIT = 'n';
const RID_CREDIT = 'r';
const ACET_CREDIT = 'a';
const BOTH_RID_ACET_CREDIT = 'b';

const PROFESSIONAL_STUDIES = 'p';
const GENERAL_STUDIES = 'g';

const CAREER_PATH = 'cp';
const MENTORING = 'm';
const HEALTH_AND_WELLNESS = 'hw';
const RTC_AND_NT = 'rt';
const INTREPRETING_SKILLS = 'is';
const DIVERSITY_AND_INCLUSION = 'di';
const POSTSECONDARY_CONTENT = 'pc';

const NOT_REGISTERED = '';
const APPROVED = 'a';
const APPROVED_PENDING_REVIEW = 'r';
const DENIED = 'd';
const PENDING = 'p';
const WITHDRAW = 'w';
const IN_CART = 'c';
const FACILITATOR = 'f';

const MONDAY = 'Monday';
const TUESDAY = 'Tuesday';
const WEDNESDAY = 'Wednesday';
const THURSDAY = 'Thursday';
const FRIDAY = 'Friday';
const ONLINE = 'Online';

const SPRING = 'Spring';
const SUMMER = 'Summer';
const FALL = 'Fall';
// const WINTER = 'Winter';

const PUBLISHED = 'p';
const DRAFT = 'd';

const PRESENT = 'p';
const LATE = 'l';
const ABSENT = 'a';

const INTERPRETER = 'i';
const CAPTIONIST = 'c';
const NON_RIT = 'n';

const RID_NAD_CEU_TRACKING = 'r';
const BEI_CEU_TRACKING = 'b';
const ACET_CEU_TRACKING = 'a';
const NO_CEU_TRACKING = 'n';

const IN_PERSON = 'i';
const BLENDED = 'b';

const NONE = 'n';
const SOME = 's';
const EXTENSIVE = 'e';
const TEACHING = 't';

const HIDDEN = 'h';
const PRIVATE = 'p';
const PUBLIC = 'u';

export const ProfileDictionary = {
  DISPLAY_CHOICES: {
    HIDDEN,
    PRIVATE,
    PUBLIC,
  },
};

export const AttendanceRecordDictionary = {
  ATTENDANCE_STATUS: {
    PRESENT,
    LATE,
    ABSENT,
  },
};

export const EventDictionary = {
  CEU_TYPES: {
    NONE,
    PROFESSIONAL_STUDIES,
    GENERAL_STUDIES,
  },
  INSTRUCTION_LEVELS: {
    NONE,
    SOME,
    EXTENSIVE,
    TEACHING,
  },
  LEARNING_TYPES: {
    IN_PERSON,
    ONLINE,
    BLENDED,
  },
  CEU_TRACKING_CHOICES: {
    RID_NAD_CEU_TRACKING,
    BEI_CEU_TRACKING,
    ACET_CEU_TRACKING,
    NO_CEU_TRACKING,
  },
  EMPLOYEE_TYPES: {
    INTERPRETER,
    CAPTIONIST,
    NON_RIT,
  },
  CREDIT_TYPES: {
    NO_CREDIT,
    RID_CREDIT,
    ACET_CREDIT,
    BOTH_RID_ACET_CREDIT,
  },
  EVENT_TYPES: {
    PDC,
    WORKSHOP,
    STEP_FORWARD_LEADERSHIP,
    SMALL_GROUP_PDC,
    UNKNOWN,
  },
  STRAND_TYPES: {
    CAREER_PATH,
    MENTORING,
    HEALTH_AND_WELLNESS,
    RTC_AND_NT,
    STEP_FORWARD_LEADERSHIP,
    INTREPRETING_SKILLS,
    DIVERSITY_AND_INCLUSION,
    POSTSECONDARY_CONTENT,
  },
  REG_STATUS: {
    NOT_REGISTERED,
    APPROVED,
    APPROVED_PENDING_REVIEW,
    DENIED,
    PENDING,
    WITHDRAW,
    IN_CART,
    FACILITATOR,
  },
  WEEK_DAYS: {
    MONDAY,
    TUESDAY,
    WEDNESDAY,
    THURSDAY,
    FRIDAY,
    ONLINE,
  },
  SEASON_TYPES: {
    SPRING,
    SUMMER,
    FALL,
    // WINTER,
  },
  PUB_STATUS: {
    PUBLISHED,
    DRAFT,
  },
};

export const EventLabels = {
  CEU_TYPES: {
    [NONE]: 'None',
    [PROFESSIONAL_STUDIES]: 'Professional Studies',
    [GENERAL_STUDIES]: 'General Studies',
  },
  INSTRUCTION_LEVELS: {
    [NONE]: 'Little or none',
    [SOME]: 'Some',
    [EXTENSIVE]: 'Extensive',
    [TEACHING]: 'Teaching',
  },
  LEARNING_TYPES: {
    [IN_PERSON]: 'In Person',
    [ONLINE]: 'Online',
    [BLENDED]: 'Blended',
  },
  CEU_TRACKING_CHOICES: {
    [RID_NAD_CEU_TRACKING]: 'RID/NAD',
    [BEI_CEU_TRACKING]: 'BEI',
    [ACET_CEU_TRACKING]: 'ACET',
    [NO_CEU_TRACKING]: 'No CEU tracking',
  },
  EMPLOYEE_TYPES: {
    [INTERPRETER]: 'Interpreters',
    [CAPTIONIST]: 'Captionists',
    [NON_RIT]: 'All',
  },
  CREDIT_TYPES: {
    [NO_CREDIT]: 'No Credit Awarded',
    [RID_CREDIT]: 'RID Credit',
    [ACET_CREDIT]: 'ACET Credit',
    [BOTH_RID_ACET_CREDIT]: 'RID and ACET Credit',
  },
  EVENT_TYPES: {
    [PDC]: 'Professional Development Course',
    [WORKSHOP]: 'Workshop',
    [STEP_FORWARD_LEADERSHIP]: 'Step Forward Leadership',
    [SMALL_GROUP_PDC]: 'Small Group PDC',
    [UNKNOWN]: '',
  },
  EVENT_TYPES_ABBREVIATED: {
    [PDC]: 'PDC',
    [WORKSHOP]: 'WRK',
    // [SEMINAR]: 'SEM',
    [STEP_FORWARD_LEADERSHIP]: 'SFL',
    [SMALL_GROUP_PDC]: 'GRP',
    [UNKNOWN]: '-',
  },
  STRAND_TYPES: {
    [CAREER_PATH]: 'Career Path',
    [HEALTH_AND_WELLNESS]: 'Health and Wellness',
    [MENTORING]: 'Mentoring',
    [RTC_AND_NT]: 'RTC and Note-taking',
    [STEP_FORWARD_LEADERSHIP]: 'Step Forward Leadership',
    [POSTSECONDARY_CONTENT]: 'Post-Secondary Content',
    [INTREPRETING_SKILLS]: 'Interpreting Skills',
    [DIVERSITY_AND_INCLUSION]: 'Diversity and Inclusion',
  },
  REG_STATUS: {
    [NOT_REGISTERED]: 'Not Registered',
    [APPROVED]: 'Approved',
    [APPROVED_PENDING_REVIEW]: 'Approved by Manager, Pending Review',
    [DENIED]: 'Denied',
    [PENDING]: 'Pending',
    [WITHDRAW]: 'Withdrawn',
    [IN_CART]: 'Added to cart',
    [FACILITATOR]: 'Facilitating',
  },
  WEEK_DAYS: {
    [MONDAY]: 'Monday',
    [TUESDAY]: 'Tuesday',
    [WEDNESDAY]: 'Wednesday',
    [THURSDAY]: 'Thursday',
    [FRIDAY]: 'Friday',
    [ONLINE]: 'Online',
  },
  PUB_STATUS: {
    [PUBLISHED]: 'Published',
    [DRAFT]: 'Draft',
  },
};

export const Tags = {
  FACILITATOR: 'facilitator',
  COMMITTEE: 'committee',
  AGENDA: 'agenda',
  MINUTES: 'minutes',
  CAROUSEL: 'carousel',
  COMMITTEE_MEMBER: 'committee-member',
  EVENT: 'event',
  MEETING: 'meeting',
  REGISTRATION: 'registration',
  CALENDAR_EVENT: 'calendar-event',
};

const ADMINS = 'admins';
const CAROUSEL_ADMINS = 'carousel_admins';
const COMMITTEE_MEMBER = 'committee_member';
const DEAN = 'Dean';
const FACILITATORS = 'facilitators';
const MANAGERS = 'managers';
const PDP_SPONSOR = 'pdp_sponsor';
const SFL_ADMINS = 'sfl_admins';

export const GroupsPropertyMap = {
  [ADMINS]: 'isAdmin',
  [CAROUSEL_ADMINS]: 'isCarouselAdmin',
  [COMMITTEE_MEMBER]: 'isCommitteeMember',
  [DEAN]: 'isDean',
  [FACILITATORS]: 'isFacilitator',
  [MANAGERS]: 'isManager',
  [PDP_SPONSOR]: 'isPdpSponsor',
  [SFL_ADMINS]: 'isSflAdmin',
};

const YES_NO = 'yes-no';
const YES_NO_COMMENT = 'yes-no-comment';
const YES_NO_IDK = 'yes-no-idk';
const OPEN = 'open';
const OPEN_TEXT = 'open-text';
const CHOICE = 'choice';
const CHOICE_FREEFORM = 'choice-freeform';
const CHOICE_MULTIPLE = 'choice-multiple';
const CHOICE_MULTIPLE_FREEFORM = 'choice-multiple-freeform';
const CHOICE_MATRIX = 'choice-matrix';
const RANGE = 'range';
const NUMBER = 'number';
const COMMENT = 'comment';

export const QuestionTypeDict = {
  YES_NO,
  YES_NO_COMMENT,
  YES_NO_IDK,
  OPEN,
  OPEN_TEXT,
  CHOICE,
  CHOICE_FREEFORM,
  CHOICE_MULTIPLE,
  CHOICE_MULTIPLE_FREEFORM,
  CHOICE_MATRIX,
  RANGE,
  NUMBER,
  COMMENT,
};

export const QuestionTypeLabels = {
  [YES_NO]: 'Yes/No',
  [YES_NO_COMMENT]: 'Yes/No with Comment',
  [YES_NO_IDK]: 'Yes/No/Not Sure',
  [OPEN]: 'Open Answer',
  [OPEN_TEXT]: 'Open Long Answer',
  [CHOICE]: 'Choice',
  [CHOICE_FREEFORM]: 'Choice Freeform',
  [CHOICE_MULTIPLE]: 'Multiple Choice',
  [CHOICE_MULTIPLE_FREEFORM]: 'Multiple Choice Freeform',
  [CHOICE_MATRIX]: 'Choice Matrix',
  [RANGE]: 'Range',
  [NUMBER]: 'Number',
  [COMMENT]: 'Comment',
};
