import { getCookie } from 'helpers/_getCookie';

$(document).ready(function() {
  var deleteSflApplication = function(e) {
    e.preventDefault();
    let tableRow = $(this).closest('tr');
    $(this)
      .closest('.modal')
      .modal('toggle');
    let csrftoken = getCookie('csrftoken');
    $.ajaxSetup({
      beforeSend: function(xhr, settings) {
        if (!this.crossDomain) {
          xhr.setRequestHeader('X-CSRFToken', csrftoken);
        }
      },
    });
    $.ajax({
      url: $(this).attr('action'),
      data: $(this).serialize(),
      error: function(response) {
        window.show_stack_topleft('Error', 'The application could not be deleted.', 'error');
        return false;
      },
      success: function(response) {
        $('.modal-backdrop').remove();
        tableRow.remove();
        if ($('tbody > tr').length === 0) {
          $('tbody').append(
            '<tr><td colspan="5">No applications have been created. <a href="/step-forward-leadership/apply">Create</a> one now.</td></tr>'
          );
        }
        window.show_stack_topleft('Success', 'Application deleted.', 'success');
        return false;
      },
      type: 'DELETE',
    });
  };

  $('form.sfl-delete').submit(deleteSflApplication);
});
