export default (month) => {
  month = parseInt(month);
  switch (month) {
    case 1:
    case 2:
    case 3:
    case 4:
      return 'Spring';
    case 5:
    case 6:
    case 7:
      return 'Summer';
    case 8:
    case 9:
    case 10:
    case 11:
    case 12:
      return 'Fall';
  }
};
