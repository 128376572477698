import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const RelativeDate = (props) => {
  if (!props.date) {
    return;
  }
  let humanText = props.date.fromNow();
  if (humanText === 'a few seconds ago') {
    humanText = 'just now';
  }
  if (props.date.isBefore(moment().subtract(6, 'months'))) {
    humanText = props.date.format('YYYY-MM-DD');
  }
  return <span title={props.date.format('YYYY-MM-DD h:mm a')}>{humanText}</span>;
};

RelativeDate.propTypes = {
  date: PropTypes.object,
};

export default RelativeDate;
