import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/*
 * An all-in-one component that covers most page header use cases.
 * Uses props instead of component composition to simplify usage.
 * More complex use cases (e.g. avatar) that build upon PageHeader should not be considered here;
 * page headers that need advanced customization should build the page header with the invididual
 * component building blocks.
 *
 * <PageHeader.Basic
 *  title="The page title"
 *  subtitle="Some small text"
 *  actions={<Link to={} className="btn btn-default">View More</Link>}
 * />
 */
export const Basic = ({ title, subtitle, actions, subtext, center }) => {
  return (
    <Wrapper center={center}>
      <Title>
        {title}
        {subtitle && ' '}
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
      </Title>
      {actions && <Actions>{actions}</Actions>}
      {subtext && <Subtext>{subtext}</Subtext>}
    </Wrapper>
  );
};
Basic.propTypes = {
  title: PropTypes.node,
  subtitle: PropTypes.node,
  actions: PropTypes.node,
  subtext: PropTypes.node,
  center: PropTypes.bool,
};

/*
 * The primary wrapper around *all* page headers.
 * This provides the foundation to set up flexbox + bottom border line
 *
 * <PageHeader.Wrapper>
 *   <PageHeader.Title>....
 *   ...
 * </PageHeader.Wrapper>
 */
export const Wrapper = ({ children, center }) => (
  <div className={classNames('page-header__wrapper', { 'page-header__wrapper--center': center })}>
    {children}
  </div>
);
Wrapper.propTypes = {
  children: PropTypes.node,
  center: PropTypes.bool,
};

/*
 * The actual text of the page header.
 *
 * <PageHeader.Wrapper>
 *   <PageHeader.Title>My page title</PageHeader.Title>
 * </PageHeader.Wrapper>
 */
export const Title = ({ children }) => <h1 className="page-header__title">{children}</h1>;
Title.propTypes = {
  children: PropTypes.node,
};

/*
 * Top-right position of a page header to hold "action" buttons.
 * Should be used sparingly; a dropdown menu is good for multiple actions.
 * Should be placed after the <PageHeader.Title>, such as:
 *
 * <PageHeader.Wrapper>
 *   <PageHeader.Title>The page title</PageHeader.Title>
 *   <PageHeader.Actions>
 *     <Link to={} className="btn btn-default">View More</Link>
 *   </PageHeader.Actions>
 * </PageHeader.Wrapper>
 */
export const Actions = ({ children }) => <div>{children}</div>;
Actions.propTypes = {
  children: PropTypes.node,
};

/*
 * Subtitle to the primary title.
 * Should be placed *within* the <PageHeader.Title>, such as:
 *
 * <PageHeader.Wrapper>
 *   <PageHeader.Title>
 *    Edit event
 *    <PageHeader.Subtitle>ASL to Spoken English</PageHeader.Subtitle>
 *   </PageHeader.Title>
 * </PageHeader.Wrapper>
 */
export const Subtitle = ({ children }) => (
  <small className="page-header__subtitle">{children}</small>
);
Subtitle.propTypes = {
  children: PropTypes.node,
};

/*
 * Affixes an avatar to the page header.
 *
 * <PageHeader.Wrapper>
 *   <PageHeader.WithAvatar src="the-avatar.jpg">
 *    <PageHeader.Title>The page title</PageHeader.Title>
 *    ...other components (actions, subtext, etc)...
 *   </PageHeader.WithAvatar>
 * </PageHeader.Wrapper>
 */
export const WithAvatar = ({ src, alt, children }) => {
  if (!src) {
    return children;
  }
  return (
    <div className="page-header__with-avatar">
      <img className="page-header__avatar" src={src} alt={alt} />
      {children}
    </div>
  );
};
WithAvatar.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  children: PropTypes.node,
};

/*
 * A second line underneath the page title. Useful for contextual information on the page's subject,
 * e.g. an event's academic semester, status, type, etc.
 *
 * <PageHeader.Wrapper>
 *  <PageHeader.Title>The page title</PageHeader.Title>
 *  <PageHeader.Subtext>2001-2002 Fall</PageHeader.Title>
 * </PageHeader.Wrapper>
 */
export const Subtext = ({ children }) => {
  return (
    <>
      <div className="flex-break" />
      <div className="page-header__subtext">{children}</div>
    </>
  );
};
Subtext.propTypes = {
  children: PropTypes.node,
};
