import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { failedAssertion } from 'helpers/reporting';

class GlobalRouting extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    history: PropTypes.object.isRequired,
  };

  componentWillMount() {
    window.reactGlobalRouting = {
      history: this.props.history,
      navigate: (path, event) => {
        if (event.metaKey) {
          window.open(path, '_blank');
        } else {
          window.reactGlobalRouting.history.push(path);
        }

        // Call preventDefault at the very end in case something above breaks.
        // The browser will follow the link naturally as a fallback.
        event.preventDefault();
      },
    };
  }

  componentDidMount() {
    // Activate Google Analytics tracking
    this.props.history.listen(function(location) {
      if (typeof window.ga !== 'undefined') {
        window.ga('set', 'page', location.pathname + location.search);
        window.ga('send', 'pageview', location.pathname + location.search);
      }
    });

    // Add [data-react-nav] to any <a> to enforce on-page routing
    const navbar = document.querySelector('[data-react-nav-root]');
    if (!navbar) {
      failedAssertion('Could not find navbar to integrate global routing with. Got:', { navbar });
      return;
    }
    // NodeList.forEach isn't supported in Safari < 10. Must convert to Array first until we drop Safari 9 support.
    Array.from(navbar.querySelectorAll('[data-react-nav]')).forEach((link) => {
      const href = link.getAttribute('href');
      if (!href) {
        return;
      }
      link.addEventListener('click', window.reactGlobalRouting.navigate.bind(null, href), {
        capture: true, // dispatch before other events beneath it in dom tree
        passive: false, // we *do* call preventDefault, so this cannot be passive
      });
    });
  }

  render() {
    window.reactGlobalRouting.history = this.props.history;
    return this.props.children;
  }
}

export const GlobalRoutingWithoutRouter = GlobalRouting;
export default withRouter(GlobalRouting);
