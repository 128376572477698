import React from 'react';
import PropTypes from 'prop-types';

const IconTooltip = (props) => {
  return <span data-tip={props.message} className={`fa ${props.icon} fa-fw`} />;
};

IconTooltip.propTypes = {
  message: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
};

export default IconTooltip;
