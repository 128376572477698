import { EditLock } from 'api';

import { failedAssertion } from './reporting';

const METADATA_DIV_ID = 'edit-lock-metadata';
const WARNING_DIV_ID = 'edit-lock-warning';

let interval = null;

async function checkin() {
  const metadata = document.getElementById(METADATA_DIV_ID);
  if (!metadata) {
    failedAssertion('EditLock could not check-in to warn other users: no metadata found on page');
  }
  const { editLockModelClass, editLockModelPk, editLockCheckinBy } = metadata.dataset;
  try {
    await EditLock.checkin({
      model_class: editLockModelClass,
      model_pk: editLockModelPk,
      checkin_by: editLockCheckinBy,
    });
  } catch (error) {
    failedAssertion(
      'EditLock could not check-in to warn other users: checking API call failed',
      error
    );
  }
}

function startInterval() {
  clearInterval(interval);
  const eightSeconds = 8 * 1000;
  interval = setInterval(checkin, eightSeconds);
}

window.editLockDismissWarning = function() {
  checkin();
  startInterval();
  const elements = document.getElementsByClassName('edit-lock-warning');
  for (let element of elements) {
    element.classList.add('edit-lock-warning--dismissed');
  }
};

export function checkinIfNeeded() {
  const warning = document.getElementById(WARNING_DIV_ID);
  if (warning) {
    return;
  }
  const metadata = document.getElementById(METADATA_DIV_ID);
  if (!metadata) {
    return;
  }
  checkin();
  startInterval();
}
