import React from 'react';
import { Form } from './Form';
import { ResourcesPage } from '../ResourcesPage';

export const SponsorPlanForm = () => (
  <ResourcesPage>
    <Form
      header="Sponsor's Continuing Education Activity Plan"
      forms={[
        {
          title: 'Sponsor Activity Plan Form.docx',
          url: 'files/forms/Approved%20Sponsor%20Activity%20Plan%20Form.docx',
        },
      ]}
    >
      <p>
        This form is filled out by the PDP Manager and must be submitted to the RID Sponsor{' '}
        <strong>at least 30 days prior</strong> to the start of the activity.
      </p>
      <p>
        In order to ensure timely submission to the RID Sponsor and thus the RID,{' '}
        <strong>
          the PDP Manager requires that this form be filled out and submitted at least 35 days prior
          to the start of the event.
        </strong>
      </p>
      <p>
        Most of the information needed to complete the Sponsor Form is contingent upon the
        successful completion of the Facilitators Form.
      </p>
    </Form>
  </ResourcesPage>
);
