/* eslint camelcase: 0 */

import { Employees, Registration, notifyError } from 'api';
import { asyncActionType } from 'store/actions';
import { EventDictionary } from 'helpers/dictionaries';

const { REG_STATUS } = EventDictionary;

export const CHANGE_MANAGER_ACADEMIC_YEAR = 'CHANGE_MANAGER_ACADEMIC_YEAR';
export const FILTER_EMPLOYEES = 'FILTER_EMPLOYEES';
export const SELECT_ACTION_ITEM = 'SELECT_ACTION_ITEM';
export const SET_ACTION_ITEMS = 'SET_ACTION_ITEMS';
export const UPDATE_SEARCH_VALUE = 'UPDATE_SEARCH_VALUE';
export const SHOW_PREVIOUSLY_ACTIONED_ITEMS = 'SHOW_PREVIOUSLY_ACTIONED_ITEMS';
export const UPDATE_MANAGER_DASHBOARD_EMPLOYEE = 'UPDATE_MANAGER_DASHBOARD_EMPLOYEE';

export const FETCH_EMPLOYEES = asyncActionType('FETCH_EMPLOYEES');

export const MANAGER_REGISTRATION_APPROVE = asyncActionType('MANAGER_REGISTRATION_APPROVE');
export const MANAGER_REGISTRATION_DENY = asyncActionType('MANAGER_REGISTRATION_DENY');

export const changeManagerAcademicYear = (academicyear) => {
  return {
    type: CHANGE_MANAGER_ACADEMIC_YEAR,
    academicyear,
  };
};

export const fetchEmployeesPending = (bool) => {
  return {
    type: FETCH_EMPLOYEES.PENDING,
    bool,
  };
};

export const fetchEmployeesSuccess = (data) => {
  return {
    type: FETCH_EMPLOYEES.SUCCESS,
    data,
  };
};

export const fetchEmployeesError = (bool) => {
  return {
    type: FETCH_EMPLOYEES.ERROR,
    bool,
  };
};

export const filterEmployees = (employees) => {
  return {
    type: FILTER_EMPLOYEES,
    employees,
    meta: { debounce: 100 },
  };
};

export const updateSearchValue = (value) => {
  return {
    type: UPDATE_SEARCH_VALUE,
    value,
  };
};

export const managerRegistrationApprovePending = (bool) => {
  return {
    type: MANAGER_REGISTRATION_APPROVE.PENDING,
    bool,
  };
};

export const managerRegistrationApproveSuccess = (registration) => {
  return {
    type: MANAGER_REGISTRATION_APPROVE.SUCCESS,
    registration,
  };
};

export const managerRegistrationApproveError = (bool) => {
  return {
    type: MANAGER_REGISTRATION_APPROVE.ERROR,
    bool,
  };
};

export const managerRegistrationDenyPending = (bool) => {
  return {
    type: MANAGER_REGISTRATION_DENY.PENDING,
    bool,
  };
};

export const managerRegistrationDenySuccess = (registration) => {
  return {
    type: MANAGER_REGISTRATION_DENY.SUCCESS,
    registration,
  };
};

export const managerRegistrationDenyError = (bool) => {
  return {
    type: MANAGER_REGISTRATION_DENY.ERROR,
    bool,
  };
};

export const updateManagerDashboardEmployee = (slug) => {
  return {
    type: UPDATE_MANAGER_DASHBOARD_EMPLOYEE,
    slug,
  };
};

export const showPreviouslyActionedItems = (bool) => {
  return {
    type: SHOW_PREVIOUSLY_ACTIONED_ITEMS,
    bool,
  };
};

export const selectActionItem = (actionItemId) => {
  actionItemId = parseInt(actionItemId);
  return {
    type: SELECT_ACTION_ITEM,
    actionItemId,
  };
};

export const setActionItems = (items) => {
  return {
    type: SET_ACTION_ITEMS,
    items,
  };
};

const employeeMatching = (employee, search) => {
  let found = false;
  const lowercaseName = employee.name.toLowerCase();
  found = lowercaseName.includes(search.toLowerCase());
  if (found) return true;

  const lowercaseEmail = employee.email.toLowerCase();
  found = lowercaseEmail.includes(search.toLowerCase());

  return found;
};

export const searchEmployees = (query) => (dispatch, getState) => {
  dispatch(updateSearchValue(query));
  const { employees } = getState().accountsManager;
  const filteredEmployees = employees.filter((employee) => employeeMatching(employee, query));
  dispatch(filterEmployees(filteredEmployees));
};

const convertToActionItem = (item, employee) => {
  return {
    actionItemPk: item.pk,
    modified: item.modified,
    title: item.event.title,
    type: item.event.type,
    strand_type: item.event.strand_type,
    event_status: item.event.event_status,
    event_pk: item.event.pk,
    times: item.event.times,
    first: item.event.first,
    last: item.event.last,
    registration_end: item.event.registration_end,
    name: employee.name,
    photo: employee.profile.avatar,
    slug: employee.profile.slug,
    status: item.status,
    approved: item.approved,
    reason: item.reason,
  };
};

export const requestEmployees = () => (dispatch, getState) => {
  dispatch(fetchEmployeesPending(true));
  return Employees.list()
    .then((employees) => {
      const data = employees.map((employee) => {
        let action_items = [];
        let actioned_items = [];
        let pending_registrations = [];
        employee.user_registrations.map((registration) => {
          const item = { ...convertToActionItem(registration, employee) };
          if (registration.status === REG_STATUS.PENDING) {
            action_items.push(item);
            pending_registrations.push(registration);
          } else if (registration.status !== REG_STATUS.FACILITATOR) {
            actioned_items.push(item);
          }
        });
        return {
          ...employee,
          pending_registrations,
          action_items,
          actioned_items,
        };
      });
      dispatch(fetchEmployeesSuccess(data));
    })
    .catch((err) => {
      dispatch(fetchEmployeesError(true));
      throw err;
    });
};

export const managerRegistrationApprove = (pk) => (dispatch, getState) => {
  Registration.managerUpdate(pk, { pk, status: REG_STATUS.APPROVED_PENDING_REVIEW })
    .then((data) => {
      dispatch(managerRegistrationApproveSuccess(data));
      dispatch(updateEmployeeActionItems());
      dispatch(getActionItems());
    })
    .catch((err) => {
      notifyError(err);
      throw err;
    });
};

export const managerRegistrationDeny = (pk, data) => (dispatch, getState) => {
  Registration.managerUpdate(pk, { pk, status: REG_STATUS.DENIED, ...data })
    .then((data) => {
      dispatch(managerRegistrationDenySuccess(data));
      dispatch(updateEmployeeActionItems());
      dispatch(getActionItems());
    })
    .catch((err) => {
      notifyError(err);
      throw err;
    });
};

export const updateEmployeeActionItems = () => (dispatch, getState) => {
  const { employees } = getState().accountsManager;
  const data = employees.map((employee) => {
    let action_items = [];
    let actioned_items = [];
    let pending_registrations = [];
    employee.user_registrations.map((registration) => {
      const item = { ...convertToActionItem(registration, employee) };
      if (registration.status === REG_STATUS.PENDING) {
        action_items.push(item);
        pending_registrations.push(registration);
      } else if (registration.status !== REG_STATUS.FACILITATOR) {
        actioned_items.push(item);
      }
    });
    return {
      ...employee,
      pending_registrations,
      action_items,
      actioned_items,
    };
  });
  dispatch(fetchEmployeesSuccess(data));
};

export const getActionItems = () => (dispatch, getState) => {
  const { employees } = getState().accountsManager;
  let actionItems = [];
  let actionedItems = [];
  employees.map((employee) => {
    actionItems = actionItems.concat(employee.action_items);
    actionedItems = actionedItems.concat(employee.actioned_items);
  });
  let allActionItems = actionItems.concat(actionedItems);
  dispatch(setActionItems({ actionItems, actionedItems, allActionItems }));
};
