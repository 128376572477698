import React from 'react';
import { Form } from './Form';
import { ResourcesPage } from '../ResourcesPage';

export const FacilitatorPlanForm = () => (
  <ResourcesPage>
    <Form
      header="Facilitator's Continuing Education Activity Plan"
      forms={[{ title: 'Facilitator Form.docx', url: 'files/forms/Facilitator%20Form.docx' }]}
    >
      <p>
        This form is to be completed by either the facilitator or the RID Sponsor and kept on file
        with the Sponsor's form.
      </p>
      <p>
        This form and the Sponsor form must be completed and submitted to the RID at least 30 days
        prior to the start of the event. In order to ensure timely submissions to the DAS RID
        Sponsor and thus the RID,{' '}
        <strong>
          the PDP Cordinator requires that this form be filled out and submitted at least 35 days
          prior to the start of the event.
        </strong>
      </p>
      <p>
        This form documents the specifics of the RID sponsored PD activity you will be leading. The
        following information is <strong>required</strong> for this form to be complete and in order
        for an event to be approved by our RID Sponsor:
      </p>
      <ul>
        <li>Presenter name(s)</li>
        <li>Presenter bio or resume (attach as seperate document)</li>
        <li>Date(s) of Activity or event (discuss and determine with the PDP Manager)</li>
        <li>Time of Activity or event (discuss and determine with the PDP Manager)</li>
        <li>Title of Activity</li>
        <li>Level of Instruction (Little/none, Some, Extensive, Teaching)</li>
        <li>
          Target Audience (Target Audience: (DAS Interpreters is the general response to this. Can
          be specific to CT or level within the DAS Career Ladder.)
        </li>
        <li>Event Description (Include general information about what you will be presenting)</li>
        <li>
          Educational Objectives (list specific observable actions by participants that will
          demonstrate comprehension and integration of information presented)
        </li>
        <li>
          Media and Materials: (List print, technical equipment and other materials needed. Also
          list WHO will provide the materials and equipment)
        </li>
        <li>
          Evaluation and Assessment: (describe how you will evaluate participant learning and
          presentation effectiveness)
        </li>
      </ul>
      <p>
        <strong className="italics">
          If you have specific plans for evaluating and assessing participants, please indicate that
          in your process/plan. Please also include the following: "DAS pre- and post- tests will be
          administered as well as an online program evaluation". Summary results of the program
          evaluation will be shared with RID.
        </strong>
      </p>
      <p>
        The following was taken from a workshop description submitted to and accepted by the
        Conference of Interpreter Trainers (CIT). Learning objectives include not only what the
        presenters will deliver but also results the participants can expect upon completion of the
        experience. Notice that all of the statements use action verbs and are written simply and
        clearly. Please refer to the to the List of Verbs for Formulating Educational Objectives
        document to assist you in writing your workshop, IST or small group pdc plan. If you are
        unable to formulate action statements of what you will provide participants and what they
        can expect to learn or do at the completion of the event, you may need to refine your focus.
      </p>
      <ul className="top-margin">
        <li className="topic">During this interactive workshop, presenters will:</li>
        <li>share instructional strategies for online or blended courses,</li>
        <li>
          present planning strategies to transition traditional courses and programs to distance
          delivery
        </li>
        <li>
          demonstrate an effective process for converting face-to-face learning activities to online
          learning activities,
        </li>
        <li>discuss pedagogical strategies for online learning</li>
        <li>
          share perceptions of students and faculty members on the distance learning experience
        </li>
        <li>
          demonstrate how to build online learning communities that keep students engaged in the
          learning process
        </li>
        <li>discuss creation of authentic, online learning activities</li>
        <li>present challenges to assessment and academic integrity in online learning</li>
        <li>provide assessment results that demonstrate distance delivery efficacy</li>
      </ul>
      <ul className="top-margin">
        <li className="topic">
          Following participation in this sesssion, participants will be able to:
        </li>
        <li>
          identify effective planning strategies for transitioning traditionally delivered programs
          to a blended delivery model
        </li>
        <li>explain the benefit of using best practices within Distance Learning instruction</li>
        <li>
          analyze a given learning activity for its appropriateness in transitioning from a
          traditional face-to-face delivery to a blended integration
        </li>
        <li>compare student and faculty perceptions of blended delivery experiences</li>
        <li>evaluate an interpreter preparation curriculum for its distance delivery potential</li>
      </ul>
    </Form>
  </ResourcesPage>
);
