import { combineReducers } from 'redux';
import { QUESTION_DELETE } from 'store/actions/questions';
import {
  FETCH_QUESTION_BLOCK_DETAIL,
  QUESTION_BLOCK_UPDATE,
  QUESTION_BLOCK_DELETE,
  QUESTION_BLOCK_QUESTION_ADDED,
  QUESTION_BLOCK_QUESTION_MOVED,
} from 'store/actions/question-blocks';

export const initialState = {};

export const keys = (state = initialState, action) => {
  let block;
  switch (action.type) {
    case FETCH_QUESTION_BLOCK_DETAIL.PENDING:
      block = state[action.blockId];
      return { ...state, [action.blockId]: { ...block, isLoading: action.bool } };
    case FETCH_QUESTION_BLOCK_DETAIL.SUCCESS:
      return { ...state, [action.block.id]: { ...action.block, isLoading: false } };
    case FETCH_QUESTION_BLOCK_DETAIL.ERROR:
      block = state[action.blockId];
      return { ...state, [action.blockId]: { ...block, hasErrored: action.bool } };
    case QUESTION_BLOCK_UPDATE:
      block = state[action.blockId];
      return { ...state, [action.blockId]: { ...block, ...action.data } };
    case QUESTION_BLOCK_DELETE:
      delete state[action.blockId];
      return { ...state };
    case QUESTION_DELETE:
      block = state[action.blockId];
      block.questions = block.questions.filter((questionId) => questionId !== action.questionId);
      return { ...state, [block.id]: { ...block, questions: block.questions } };
    case QUESTION_BLOCK_QUESTION_ADDED:
      block = state[action.blockId];
      block.questions.push(action.questionId);
      return {
        ...state,
        [block.id]: { ...block, questions: block.questions },
      };
    case QUESTION_BLOCK_QUESTION_MOVED:
      let oldBlock = state[action.oldBlockId];
      let newBlock = state[action.newBlockId];
      oldBlock.questions = oldBlock.questions.filter(
        (questionId) => questionId !== action.questionId
      );
      newBlock.questions.push(action.questionId);
      return {
        ...state,
        [oldBlock.id]: { ...oldBlock, questions: oldBlock.questions },
        [newBlock.id]: { ...newBlock, questions: newBlock.questions },
      };
    default:
      return state;
  }
};

export const ids = (state = [], action) => {
  let set;
  switch (action.type) {
    case FETCH_QUESTION_BLOCK_DETAIL.SUCCESS:
      set = new Set([...state, action.block.id]);
      return [...set];
    case QUESTION_BLOCK_DELETE:
      return state.filter((id) => id !== action.blockId);
    default:
      return state;
  }
};

const questionBlocks = combineReducers({
  keys,
  ids,
});

export default questionBlocks;
