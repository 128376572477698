/* eslint camelcase: 0 */
import React from 'react';
import PropTypes from 'prop-types';

import { EventLabels } from 'helpers/dictionaries';

const { CREDIT_TYPES } = EventLabels;
const { INSTRUCTION_LEVELS } = EventLabels;

const NtidSponsorMessage = ({ ceu_val, ceu_type, instruction_level }) => {
  ceu_type = CREDIT_TYPES[ceu_type] ? CREDIT_TYPES[ceu_type] : ceu_type;
  instruction_level = INSTRUCTION_LEVELS[instruction_level]
    ? INSTRUCTION_LEVELS[instruction_level]
    : instruction_level;
  const message = `NTID Department of Access Services is an Approved RID CMP Sponsor for \
                   continuing education activities. This ${ceu_type} program is offered for \
                   ${ceu_val} CEUs at the '${instruction_level}' Content Knowledge Level.`;
  return <div>{message}</div>;
};

NtidSponsorMessage.propTypes = {
  ceu_type: PropTypes.string.isRequired,
  ceu_val: PropTypes.number.isRequired,
  instruction_level: PropTypes.string.isRequired,
};

export default NtidSponsorMessage;
