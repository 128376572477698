import PropTypes from 'prop-types';
import { EventDictionary } from 'helpers/dictionaries';

export const creditApprovalPropType = PropTypes.oneOf(Object.values(EventDictionary.CREDIT_TYPES));

export const eventTypePropType = PropTypes.oneOf(Object.values(EventDictionary.EVENT_TYPES));

export const eventStrandPropType = PropTypes.oneOf(Object.values(EventDictionary.STRAND_TYPES));

export const eventRegistrationStatusPropType = PropTypes.oneOf(
  Object.values(EventDictionary.REG_STATUS)
);

export const eventPublicationStatusPropType = PropTypes.oneOf(
  Object.values(EventDictionary.PUB_STATUS)
);

export const eventRadioOptionsPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    key: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    sentence_part: PropTypes.string,
    active: PropTypes.bool,
  })
);
