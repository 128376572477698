import React from 'react';

// New *experimental feature* in React 16.3. See:
// https://medium.com/@leonardobrunolima/react-tips-context-api-performance-considerations-d964f3ad3087
// https://medium.com/@koba04/a-secret-parts-of-react-new-context-api-e9506a4578aa
export const observedBits = {
  IGNORE: 0b0,
  UPDATE: 0b1,
};

const BreadcrumbContext = React.createContext(
  {
    crumbs: [],
    addCrumb: () => {},
    updateCrumbs: () => {},
  },
  (prev, next) => {
    return observedBits.UPDATE;
  }
);

export default BreadcrumbContext;
