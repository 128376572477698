import { FETCH_USER_GROUP_LIST } from 'store/actions/accounts/groups';

export const initialState = { isLoading: true, hasErrored: false };

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USER_GROUP_LIST.PENDING:
      return { ...state, isLoading: action.bool };
    case FETCH_USER_GROUP_LIST.ERROR:
      return { ...state, hasErrored: action.bool };
    case FETCH_USER_GROUP_LIST.SUCCESS:
      return { ...state, [action.groupName]: action.data, isLoading: false, hasErrored: false };
    default:
      return state;
  }
};
