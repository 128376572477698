import { SHOW_BREADCRUMBS } from 'store/actions/breadcrumbs';

export const initialState = {
  showBreadcrumbs: true,
};

const breadcrumbs = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_BREADCRUMBS:
      return { ...state, showBreadcrumbs: action.bool };
    default:
      return state;
  }
};

export default breadcrumbs;
