import {
  FETCH_NOTIFICATIONS,
  READ_NOTIFICATION,
  READ_ALL_NOTIFICATIONS,
} from 'store/actions/notifications';

const initialState = {
  data: [],
  pageInfo: {
    hasNextPage: false,
    hasPrevPage: false,
    numPages: 1,
  },
  isLoading: true,
  hasErrored: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_NOTIFICATIONS.PENDING:
      return { ...state, isLoading: action.bool };
    case FETCH_NOTIFICATIONS.SUCCESS:
      return {
        ...state,
        data: action.data,
        pageInfo: action.pageInfo,
        isLoading: false,
        hasErrored: false,
      };
    case FETCH_NOTIFICATIONS.ERROR:
      return { ...state, hasErrored: action.bool, isLoading: false };
    case READ_NOTIFICATION.PENDING:
      return {
        ...state,
        data: state.data.map((item) => {
          if (item.id === action.data.id) {
            return {
              ...item,
              read: true,
            };
          }
          return item;
        }),
      };
    case READ_NOTIFICATION.SUCCESS:
      return { ...state, hasErrored: false };
    case READ_NOTIFICATION.ERROR:
      return { ...state, hasErrored: action.bool, isLoading: false };
    case READ_ALL_NOTIFICATIONS.PENDING:
      return {
        ...state,
        data: state.data.map((item) => ({
          ...item,
          read: true,
        })),
      };
    case READ_ALL_NOTIFICATIONS.SUCCESS:
      return { ...state, hasErrored: false };
    case READ_ALL_NOTIFICATIONS.ERROR:
      return { ...state, hasErrored: action.bool, isLoading: false };
    default:
      return state;
  }
};
