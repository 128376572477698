import { User, notifyError } from 'api';
import { asyncActionType } from 'store/actions';
import { refreshEventsDetail } from 'store/actions/events/detail';
import { getName } from 'helpers/utils';
export const ADD_TO_CART = asyncActionType('ADD_TO_CART');
export const REMOVE_FROM_CART = asyncActionType('REMOVE_FROM_CART');

export const FETCH_USER = asyncActionType('FETCH_USER');
FETCH_USER.USER_ID_LOADING = 'USER_ID_LOADING';

export const SET_CURRENT_USER = 'SET_CURRENT_USER';

export const setCurrentUser = (userId) => {
  return {
    type: SET_CURRENT_USER,
    userId,
  };
};

export const addToCartPending = (bool) => {
  return {
    type: ADD_TO_CART.PENDING,
    bool,
  };
};

export const addToCartSuccess = (userId, data) => {
  return {
    type: ADD_TO_CART.SUCCESS,
    userId,
    data,
  };
};

export const addToCartError = (bool) => {
  return {
    type: ADD_TO_CART.ERROR,
    bool,
  };
};

export const addEventToCart = (eventId) => (dispatch, getState) => {
  dispatch(addToCartPending(true));
  const { accountsUser } = getState();
  const currentUser = accountsUser[accountsUser.current];
  const eventIds = [...currentUser.shoppingcart.events, eventId];
  return User.addToCart({ events: eventIds })
    .then((res) => {
      dispatch(addToCartSuccess(currentUser.pk, res));
      dispatch(refreshEventsDetail(eventId));
    })
    .catch((err) => {
      notifyError(err);
      dispatch(addToCartError(true));
      throw err;
    });
};

export const removeFromCartPending = (bool) => {
  return {
    type: REMOVE_FROM_CART.PENDING,
    bool,
  };
};

export const removeFromCartSuccess = (userId, data) => {
  return {
    type: REMOVE_FROM_CART.SUCCESS,
    userId,
    data,
  };
};

export const removeFromCartError = (bool) => {
  return {
    type: REMOVE_FROM_CART.ERROR,
    bool,
  };
};

export const removeEventFromCart = (eventId) => (dispatch, getState) => {
  dispatch(removeFromCartPending(true));
  return User.removeFromCart({ events: eventId })
    .then((res) => {
      dispatch(removeFromCartSuccess(res.owner, res));
      dispatch(refreshEventsDetail(eventId));
    })
    .catch((err) => {
      dispatch(removeFromCartError(true));
      throw err;
    });
};

export const userPending = (bool) => {
  return {
    type: FETCH_USER.PENDING,
    bool,
  };
};

export const userIdLoading = (userId) => {
  return {
    type: FETCH_USER.USER_ID_LOADING,
    userId,
  };
};

export const userError = (bool) => {
  return {
    type: FETCH_USER.ERROR,
    bool,
  };
};

export const userSuccess = (userId, data) => {
  return {
    type: FETCH_USER.SUCCESS,
    userId,
    data,
  };
};

export const fetchUser = (userId) => (dispatch, getState) => {
  const { accountsUser } = getState();
  if (accountsUser.loadingUserIds.includes(userId)) {
    return;
  }
  dispatch(userIdLoading(userId));
  return User.get(userId)
    .then((data) => {
      dispatch(userSuccess(data.pk, { ...data, name: getName(data) }));
    })
    .catch((err) => {
      notifyError(err);
    });
};

export const requestUser = (userId) => (dispatch, getState) => {
  const user = getState().accountsUser[userId];
  if (user) {
    return Promise.resolve(user);
  }
  return dispatch(fetchUser(userId));
};

export const requestCurrentUser = () => (dispatch, getState) => {
  const { accountsUser } = getState();
  if (accountsUser && accountsUser.current) {
    return Promise.resolve(accountsUser[accountsUser.current]);
  }
  dispatch(userPending(true));
  return User.current()
    .then((data) => {
      const user = { ...data, name: getName(data) };
      dispatch(setCurrentUser(data.pk));
      dispatch(userSuccess(data.pk, user));
      return user;
    })
    .catch((err) => {
      dispatch(userError(true));
      throw err;
    });
};
