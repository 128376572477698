import React from 'react';
import { PageHeader } from 'components';
import { ResourcesPage } from './ResourcesPage';

export const ResourcesIndex = () => (
  <ResourcesPage>
    <PageHeader.Basic title="Resources" />
    <p>
      Here you will find a variety of links to web content that will help you expand your knowledge
      base, offer insight and vocabulary and in general help you prepare for interpreting across our
      curriculum. Consider setting up a small group pdc to really investigate some of the sites and
      build a solid foundation for understanding the content we face daily in the classroom and
      beyond!
    </p>
    <p>
      In addition to content and prep links you’ll find links to online, RID approved, CEU bearing
      workshops. These links lead you to alternative PD outside our program. Follow the simple steps
      outlined below and you may be eligible to receive PD funding to reimburse you after completing
      the course or workshop.
    </p>
    <h2 className="section-header" id="online-opportunities">
      Online PD Opportunities
    </h2>
    <div className="content">
      <p>
        The websites listed below offer online RID approved, CEU bearing PD opportunities for very
        reasonable fees. If the workshop you are interested in fits into your PD plan and is
        approved by your manager, you are eligible to receive reimbursement of the workshop fee!
      </p>
      <ul>
        <li>
          <a href="http://www.ceusonthego.com" target="_blank">
            CEUs on the Go
          </a>
        </li>
        <li>
          <a href="http://www.signs-of-development.org" target="_blank">
            Signs of Development
          </a>
        </li>
        <li>
          <a href="http://www.deafactioncenter.org" target="_blank">
            Deaf Action Center
          </a>
        </li>
        <li>
          <a href="http://www.winkshopasl.com" target="_blank">
            Winkshop ASL
          </a>
        </li>
      </ul>
      <dl className="top-margin">
        <dt>Follow these steps to register:</dt>
        <dd>
          <ol>
            <li>Look at the offerings</li>
            <li>
              Contact your manager to ensure that you have their support and request funds if needed
            </li>
            <li>“Take” the workshop and complete all requirements</li>
            <li>
              Submit your receipt and proof of satisfactory completion of all requirements to your
              manager
            </li>
            <li>Document in your POW/PD plan</li>
          </ol>
        </dd>
      </dl>
    </div>
    <h2 className="section-header" id="other-links-resources">
      Other Links and Resources
    </h2>
    <ul className="content">
      <li>
        <a href="https://myaccess.rit.edu/ASDS/" target="_blank">
          ASDS
        </a>
      </li>
      <li>
        <a href="https://wallacecenter.rit.edu/" target="_blank">
          Wallace Center
        </a>
      </li>
      <li>
        <a href="http://www.rit.edu/fa/betterme/" target="_blank">
          Better Me
        </a>
      </li>
      <li>
        <a href="http://wiki.rit.edu" target="_blank">
          RIT Wiki
        </a>
      </li>
      <li>
        <a
          href="http://finweb.rit.edu/humanresources/benefits/education/index.html#3"
          target="_blank"
        >
          Tuition Assistance
        </a>
      </li>
      <li>
        <a href="http://www.rid.org/" target="_blank">
          Registry of Interpreters for the Deaf
        </a>
      </li>
      <li>
        <a href="http://www.gvrrid.org/" target="_blank">
          Genesee Valley Region RID
        </a>
      </li>
      <li>
        <a href="http://www.nad.org/" target="_blank">
          National Association of the Deaf
        </a>
      </li>
      <li>
        <a href="http://www.cit-asl.org/" target="_blank">
          Conference of Interpreter Trainers
        </a>
      </li>
      <li>
        <a href="https://www.streetleverage.com" target="_blank">
          StreetLeverage Interpreter Community
        </a>
      </li>
    </ul>
  </ResourcesPage>
);
