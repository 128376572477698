import {
  ADD_TO_CART,
  FETCH_USER,
  REMOVE_FROM_CART,
  SET_CURRENT_USER,
} from 'store/actions/accounts/user';
import { GroupsPropertyMap } from 'helpers/dictionaries';

export const initialState = { isLoading: true, hasErrored: false, loadingUserIds: [] };

// Initialize empty prop map e.g. { isAdmin: false, isManager: false, ... }
const groupsPropsDefault = Object.entries(GroupsPropertyMap).reduce((acc, property) => {
  acc[property[1]] = false;
  return acc;
}, {});

let user;
export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USER.PENDING:
      return { ...state, isLoading: action.bool };
    case FETCH_USER.USER_ID_LOADING:
      return {
        ...state,
        loadingUserIds: [...state.loadingUserIds, action.userId],
      };
    case FETCH_USER.SUCCESS:
      user = { ...state[action.userId], ...action.data };
      const groupsProps = { ...groupsPropsDefault };
      if (user.groups) {
        user.groups.map((group) => {
          const propMapping = GroupsPropertyMap[group.name];
          if (propMapping) {
            groupsProps[propMapping] = true;
          }
        });
      }
      return {
        ...state,
        [action.userId]: { ...user, ...groupsProps },
        isLoading: false,
        loadingUserIds: state.loadingUserIds.filter((id) => id !== action.userId),
      };
    case FETCH_USER.ERROR:
      return { ...state, isLoading: false, hasErrored: action.bool };
    case ADD_TO_CART.PENDING:
      return { ...state, isLoading: action.bool };
    case ADD_TO_CART.SUCCESS:
      user = state[action.userId];
      return {
        ...state,
        [action.userId]: { ...user, shoppingcart: action.data },
        isLoading: false,
      };
    case ADD_TO_CART.ERROR:
      return { ...state, isLoading: false };
    case REMOVE_FROM_CART.PENDING:
      return { ...state, isLoading: action.bool };
    case REMOVE_FROM_CART.SUCCESS:
      user = state[action.userId];
      return {
        ...state,
        [action.userId]: { ...user, shoppingcart: action.data },
        isLoading: false,
      };
    case REMOVE_FROM_CART.ERROR:
      return { ...state, isLoading: false, hasErrored: action.bool };
    case SET_CURRENT_USER:
      return { ...state, current: action.userId };
    default:
      return state;
  }
};
