/* eslint camelcase: 0 */
import {
  FILTER_EMPLOYEES,
  FETCH_EMPLOYEES,
  MANAGER_REGISTRATION_APPROVE,
  MANAGER_REGISTRATION_DENY,
  SELECT_ACTION_ITEM,
  SET_ACTION_ITEMS,
  SHOW_PREVIOUSLY_ACTIONED_ITEMS,
  UPDATE_MANAGER_DASHBOARD_EMPLOYEE,
  UPDATE_SEARCH_VALUE,
  CHANGE_MANAGER_ACADEMIC_YEAR,
} from 'store/actions/accounts/manager';

import getSeason from 'helpers/get-season';
import formatAcademicYear from 'helpers/format-academic-year';

const academicSeason = getSeason(new Date().getMonth() + 1);

let currentAcademicYear =
  academicSeason === 'Fall' ? new Date().getFullYear() : new Date().getFullYear() - 1;

const initialState = {
  isLoading: true,
  hasErrored: false,
  filteredEmployees: [],
  employees: [],
  actionItems: [],
  actionedItems: [],
  allActionItems: [],
  showPreviouslyActionedItems: false,
  currentEmployee: undefined,
  currentActionItem: null,
  searchValue: undefined,
  academicyear: formatAcademicYear(currentAcademicYear),
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_SEARCH_VALUE:
      return { ...state, searchValue: action.value };
    case CHANGE_MANAGER_ACADEMIC_YEAR:
      return { ...state, academicyear: action.academicyear };
    case SHOW_PREVIOUSLY_ACTIONED_ITEMS:
      return { ...state, showPreviouslyActionedItems: action.bool };
    case UPDATE_MANAGER_DASHBOARD_EMPLOYEE:
      return { ...state, currentEmployee: action.slug };
    case FETCH_EMPLOYEES.PENDING:
      return { ...state, isLoading: action.bool };
    case FETCH_EMPLOYEES.SUCCESS:
      return {
        ...state,
        employees: action.data,
        filteredEmployees: action.data,
        isLoading: false,
      };
    case FETCH_EMPLOYEES.ERROR:
      return { ...state, hasErrored: action.bool, isLoading: false };
    case MANAGER_REGISTRATION_APPROVE.PENDING:
      return { ...state, isLoading: action.bool };
    case MANAGER_REGISTRATION_APPROVE.SUCCESS:
      return {
        ...state,
        isLoading: false,
        employees: state.employees.map((employee) => {
          const user_registrations = employee.user_registrations.map((registration) => {
            if (registration.pk === action.registration.pk) {
              return {
                ...registration,
                status: action.registration.status,
                modified: action.registration.modified,
              };
            }
            return registration;
          });
          return { ...employee, user_registrations };
        }),
        filteredEmployees: state.filteredEmployees.map((employee) => {
          const user_registrations = employee.user_registrations.map((registration) => {
            if (registration.pk === action.registration.pk) {
              return {
                ...registration,
                status: action.registration.status,
                modified: action.registration.modified,
              };
            }
            return registration;
          });
          return { ...employee, user_registrations };
        }),
      };
    case MANAGER_REGISTRATION_APPROVE.ERROR:
      return { ...state, hasErrored: action.bool, isLoading: false };
    case MANAGER_REGISTRATION_DENY.PENDING:
      return { ...state, isLoading: action.bool };
    case MANAGER_REGISTRATION_DENY.SUCCESS:
      return {
        ...state,
        isLoading: false,
        employees: state.employees.map((employee) => {
          const user_registrations = employee.user_registrations.map((registration) => {
            if (registration.pk === action.registration.pk) {
              return {
                ...registration,
                status: action.registration.status,
                modified: action.registration.modified,
              };
            }
            return registration;
          });
          return { ...employee, user_registrations };
        }),
        filteredEmployees: state.filteredEmployees.map((employee) => {
          const user_registrations = employee.user_registrations.map((registration) => {
            if (registration.pk === action.registration.pk) {
              return {
                ...registration,
                status: action.registration.status,
                modified: action.registration.modified,
              };
            }
            return registration;
          });
          return { ...employee, user_registrations };
        }),
      };
    case MANAGER_REGISTRATION_DENY.ERROR:
      return { ...state, hasErrored: action.bool, isLoading: false };
    case FILTER_EMPLOYEES:
      return { ...state, filteredEmployees: action.employees };
    case SET_ACTION_ITEMS:
      const hasCurrentActionItem = !!state.currentActionItem;
      if (hasCurrentActionItem) {
        return {
          ...state,
          ...action.items,
          currentActionItem: action.items.allActionItems.find(
            (actionItem) => actionItem.actionItemPk === state.currentActionItem.actionItemPk
          ),
        };
      }
      return { ...state, ...action.items };
    case SELECT_ACTION_ITEM:
      return {
        ...state,
        currentActionItem: state.allActionItems.find(
          (actionItem) => actionItem.actionItemPk === action.actionItemId
        ),
      };
    default:
      return state;
  }
};
