import { Notifications } from 'api';
import { asyncActionType } from 'store/actions';

export const FETCH_NOTIFICATIONS = asyncActionType('FETCH_NOTIFICATIONS');
export const READ_NOTIFICATION = asyncActionType('READ_NOTIFICATION');
export const READ_ALL_NOTIFICATIONS = asyncActionType('READ_ALL_NOTIFICATIONS');

export const requestNotifications = ({ filter, page }) => (dispatch, getState) => {
  dispatch(notificationsPending(true));
  return Notifications.list(filter, page || 1)
    .then((data) => {
      dispatch(notificationsSuccess(data));
    })
    .catch((err) => {
      dispatch(notificationsError(true));
      throw err;
    });
};

export const readNotification = (notification) => (dispatch, getState) => {
  dispatch(readNotificationPending(true, notification));
  Notifications.read(notification.id)
    .then((data) => {
      dispatch(readNotificationSuccess(data));
    })
    .catch((err) => {
      dispatch(readNotificationError(true));
      throw err;
    });
};

export const readAllNotifications = (notification) => (dispatch, getState) => {
  dispatch(readAllNotificationsPending(true));
  Notifications.readAll()
    .then((data) => {
      dispatch(readAllNotificationsSuccess(data));
    })
    .catch((err) => {
      dispatch(readAllNotificationsError(true));
      throw err;
    });
};

export const notificationsPending = (bool) => {
  return {
    type: FETCH_NOTIFICATIONS.PENDING,
    bool,
  };
};

export const notificationsError = (bool) => {
  return {
    type: FETCH_NOTIFICATIONS.ERROR,
    bool,
  };
};

export const notificationsSuccess = ({ data, pageInfo }) => {
  return {
    type: FETCH_NOTIFICATIONS.SUCCESS,
    data,
    pageInfo,
  };
};

export const readNotificationPending = (bool, data) => {
  return {
    type: READ_NOTIFICATION.PENDING,
    bool,
    data,
  };
};

export const readNotificationError = (bool) => {
  return {
    type: READ_NOTIFICATION.ERROR,
    bool,
  };
};

export const readNotificationSuccess = (data) => {
  return {
    type: READ_NOTIFICATION.SUCCESS,
    data,
  };
};

export const readAllNotificationsPending = (bool) => {
  return {
    type: READ_ALL_NOTIFICATIONS.PENDING,
    bool,
  };
};

export const readAllNotificationsError = (bool) => {
  return {
    type: READ_ALL_NOTIFICATIONS.ERROR,
    bool,
  };
};

export const readAllNotificationsSuccess = (data) => {
  return {
    type: READ_ALL_NOTIFICATIONS.SUCCESS,
    data,
  };
};
