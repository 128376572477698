export default function unsavedAlert() {
  const unsavedForms = $('[data-unsaved-warning]');
  let unsaved = false;

  if (unsavedForms !== null) {
    unsavedForms.find('input').on('change', function() {
      unsaved = true;
    });

    const unloadPage = () => {
      if (unsaved) {
        return 'You have unsaved changes on this page. Are you sure you want to leave?';
      }
    };

    unsavedForms.submit(function() {
      unsaved = false;
    });

    $('button[type="submit"]')
      .closest('.form-group')
      .find('.btn')
      .click(function() {
        unsaved = false;
      });

    window.onbeforeunload = unloadPage;
  }
}
