import React from 'react';
import PropTypes from 'prop-types';

import { failedAssertion } from 'helpers/reporting';

import BreadcrumbContext from './BreadcrumbContext';

export default class AutoBreadcrumbs extends React.Component {
  static propTypes = {
    children: PropTypes.element,
  };

  crumbs = [];

  addCrumb = (title, url, params, startFresh = false, router = true) => {
    if (!title) {
      failedAssertion(
        'AutoBreadcrumbs: addCrumb() requires a title; none provided. Ignoring crumb.',
        {
          title,
          url,
          startFresh,
        }
      );
    }
    if (!startFresh && this.crumbs.some((x) => x.url === url && x.title === title)) {
      return null;
    }
    const newCrumb = {
      title,
      url,
      params,
      router,
    };
    this.crumbs = startFresh ? [newCrumb] : [...this.crumbs, newCrumb];
  };

  updateCrumbs = () => {
    this.setState({ crumbs: this.crumbs });
  };

  state = {
    crumbs: [],
    addCrumb: this.addCrumb,
    updateCrumbs: this.updateCrumbs,
    prevUrl: window.location.pathname,
  };

  render() {
    return (
      <BreadcrumbContext.Provider value={this.state}>
        {this.props.children}
      </BreadcrumbContext.Provider>
    );
  }
}
