import Loadable from 'react-loadable';
import { Loading } from 'components';

const AsyncEventsAdmin = Loadable({
  loader: () => import('./EventsAdmin'),
  loading: Loading,
});

const AsyncEventDetail = Loadable({
  loader: () => import('./EventDetail'),
  loading: Loading,
});

const AsyncEventsExplore = Loadable({
  loader: () => import('./EventsExplore'),
  loading: Loading,
});

const AsyncEventsHomePage = Loadable({
  loader: () => import('./EventsHomePage'),
  loading: Loading,
});

export { AsyncEventsAdmin, AsyncEventDetail, AsyncEventsExplore, AsyncEventsHomePage };
