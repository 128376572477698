import { asyncActionType } from 'store/actions';
import { Question, notifyError } from 'api';

export const FETCH_QUESTION_DETAIL = asyncActionType('FETCH_QUESTION_DETAIL');
export const QUESTION_UPDATE = 'QUESTION_UPDATE';
export const QUESTION_DELETE = 'QUESTION_DELETE';

export const questionUpdate = (questionId, data) => {
  return {
    type: QUESTION_UPDATE,
    questionId,
    data,
  };
};

export const questionDelete = (questionId, blockId) => {
  return {
    type: QUESTION_DELETE,
    questionId,
    blockId,
  };
};

export const questionDetailPending = (questionId, bool) => {
  return {
    type: FETCH_QUESTION_DETAIL.PENDING,
    questionId,
    bool,
  };
};

export const questionDetailError = (questionId, bool) => {
  return {
    type: FETCH_QUESTION_DETAIL.ERROR,
    questionId,
    bool,
  };
};

export const questionDetailSuccess = (question) => {
  return {
    type: FETCH_QUESTION_DETAIL.SUCCESS,
    question,
  };
};

export const questionDetail = (questionId) => (dispatch, getState) => {
  dispatch(questionDetailPending(questionId, true));
  return Question.get(questionId)
    .then((data) => {
      dispatch(questionDetailSuccess(data));
      return data;
    })
    .catch((err) => {
      notifyError(err);
      dispatch(questionDetailError(questionId, true));
    });
};

export const retrieveQuestion = (questionId) => (dispatch, getState) => {
  const { keys } = getState().questions;
  let question = keys[questionId];
  if (question) {
    return Promise.resolve(question);
  }
  return Question.get(questionId)
    .then((data) => {
      dispatch(questionDetailSuccess(data));
      return data;
    })
    .catch((err) => {
      notifyError(err);
      dispatch(questionDetailError(questionId, true));
    });
};

export const deleteQuestion = (questionId, blockId) => (dispatch, getState) => {
  return Question.delete(questionId)
    .then((data) => {
      dispatch(questionDelete(questionId, blockId));
      return true;
    })
    .catch((err) => {
      notifyError(err);
      return false;
    });
};
