import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Radio, RadioGroup } from 'react-radio-group';

const FormRadioGroup = ({ error, help, label, options, ...props }) => {
  const classes = classNames('form-group', { 'has-error': error });
  return (
    <div className={classes}>
      <label htmlFor={label.htmlFor} className="label-control">
        {label.text}
      </label>
      <RadioGroup name={props.name} selectedValue={props.value}>
        {options.map((option) => {
          return (
            <div className="radio" key={option[0]}>
              <label>
                <Radio {...props} value={option[0]} />
                {option[1]}
              </label>
            </div>
          );
        })}
      </RadioGroup>
      {help ? <p className="help-block">{help}</p> : null}
      {error ? <span className="help-block">{error}</span> : null}
    </div>
  );
};

FormRadioGroup.propTypes = {
  help: PropTypes.string,
  error: PropTypes.string,
  label: PropTypes.shape({
    htmlFor: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }),
  value: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.array),
};

export default FormRadioGroup;
