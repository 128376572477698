import PNotify from 'pnotify';
import { getCookie } from 'helpers/_getCookie';

$(document).ready(function() {
  var commentOnEvent = function(e) {
    e.preventDefault();
    var csrftoken = getCookie('csrftoken');
    $.ajaxSetup({
      beforeSend: function(xhr, settings) {
        if (!this.crossDomain) {
          xhr.setRequestHeader('X-CSRFToken', csrftoken);
        }
      },
    });
    $.ajax({
      url: $(this).attr('action'),
      data: $(this).serialize(),
      error: function(response) {
        window.show_stack_topleft('Error', 'The comment failed to submit.', 'error');
        return false;
      },
      success: function(response) {
        window.show_stack_topleft('Success', 'Comment submitted.', 'success');
        $('#id_message').val('');
        var messageWithBreaks = response['message'].replace(/(?:\r\n|\r|\n)/g, '<br />');
        var avatar = $('.comment-wrapper:last')
          .children('a:first')
          .clone();
        var comment = $('#comment-clone').clone();
        comment.children('a:first').replaceWith(avatar);
        var authorName = '<strong>' + response['author_name'] + '</strong>';
        comment.find('.comment-text').html(authorName + ' commented ' + response['timestamp']);
        comment.find('.comment-body').html(messageWithBreaks);
        comment.insertBefore('.comment-wrapper:last');
        comment.find('.comment-actions a:first').attr('href', response['edit_url']);
        comment
          .find('.comment-actions a:last')
          .attr('href', response['delete_url'])
          .on('click', deleteCommentCallback);
        comment.removeClass('hidden').removeAttr('id');
      },
      type: $(this).attr('method'),
    });
  };

  var showConfirmModal = function(title, text, type, callback) {
    var opts = {
      title: title,
      text: text,
      hide: false,
      type: type,
      confirm: {
        confirm: true,
      },
      buttons: {
        closer: false,
        sticker: false,
      },
      history: {
        history: false,
      },
      addclass: 'stack-modal',
      stack: { dir1: 'down', dir2: 'right', push: 'top', modal: true },
    };

    new PNotify(opts)
      .get() // eslint-disable-line no-new
      .on('pnotify.confirm', function() {
        return callback(true); // eslint-disable-line standard/no-callback-literal
      })
      .on('pnotify.cancel', function() {
        return callback(false); // eslint-disable-line standard/no-callback-literal
      });
  };

  var deleteCommentCallback = function(e) {
    e.preventDefault();
    var target = $(this);
    showConfirmModal(
      'Confirmation Needed',
      'Are you sure you want to delete this comment? This action cannot be undone.',
      'error',
      function(confirmed) {
        if (confirmed) {
          var csrftoken = getCookie('csrftoken');
          $.ajaxSetup({
            beforeSend: function(xhr, settings) {
              if (!this.crossDomain) {
                xhr.setRequestHeader('X-CSRFToken', csrftoken);
              }
            },
          });
          $.ajax({
            url: target[0].href,
            data: {},
            error: function(response) {
              window.show_stack_topleft('Error', response.responseJSON['detail'], 'error');
            },
            success: function(data) {
              window.show_stack_topleft('Deleted', 'Comment has been removed.', 'success');
              target.parents('.comment-wrapper').remove();
            },
            type: 'DELETE',
          });
        }
      }
    );

    return false;
  };

  $('form.comment-form').submit(commentOnEvent);

  $('a.delete-comment').on('click', deleteCommentCallback);

  PNotify.prototype.options.delay = 2000;
});
