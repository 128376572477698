import moment from 'moment';

export const isRegistrationOpen = (start, end) => {
  const now = moment();
  return now >= moment(start) && now < moment(end);
};

export const getName = (data, type = 'user') => {
  let profile = {};
  let ridprofile = {};
  if (type === 'user') {
    profile = data.profile;
    ridprofile = data.ridprofile;
  }
  if (type === 'profile') {
    profile = data;
  }
  if (profile && (profile.first_name || profile.last_name)) {
    return [profile.first_name, profile.last_name].join(' ').trim();
  }
  if (ridprofile && (ridprofile.first_name || ridprofile.last_name)) {
    return [ridprofile.first_name, ridprofile.last_name].join(' ').trim();
  }
  if (profile && profile.slug) {
    return profile.slug;
  }
  return '';
};
