import React from 'react';
import PropTypes from 'prop-types';

export default class ActionButton extends React.Component {
  static propTypes = {
    pk: PropTypes.number.isRequired,
    onClick: PropTypes.func.isRequired,
    className: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    type: PropTypes.string,
    dataTip: PropTypes.string,
  };

  handleClick = () => {
    this.props.onClick({ pk: this.props.pk, type: this.props.type });
  };

  render() {
    return (
      <button
        type="button"
        className={`btn ${this.props.className}`}
        onClick={this.handleClick}
        data-tip={this.props.dataTip}
      >
        <i className={`fa ${this.props.icon}`} />
      </button>
    );
  }
}
