import { detect } from 'detect-browser';
const browser = detect();

// Should be in sync with .babelrc
// Currently based on support for Symbol
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Symbol#Browser_compatibility
const SUPPORTED_BROWSERS = {
  firefox: 36,
  chrome: 38,
  opera: 25,
  safari: 9,
  ios: 9,
  ie: 999,
};

const html = [
  '<strong>Warning: You are using an old, unsupported web browser.</strong>',
  '<br />',
  'Please update your browser or switch to a different one. We recommend',
  ' <a href="https://www.google.com/chrome/browser/desktop/index.html">Google Chrome</a>,',
  ' <a href="https://www.mozilla.org/en-US/firefox/new/">Firefox</a>, ',
  ' <a href="https://support.apple.com/en-us/HT204416">Safari</a>, ',
  ' or <a href="https://www.microsoft.com/en-us/windows/microsoft-edge">Microsoft Edge</a>. ',
  '<br />',
].join('');

// handle the case where we don't detect the browser
if (browser) {
  const supportedVersion = SUPPORTED_BROWSERS[browser.name];
  const currentVersionNumber = parseInt(browser.version, 10);
  if (supportedVersion && supportedVersion > currentVersionNumber) {
    // Only show this message if we know for sure it's an unsupported browser & version.
    // False-positives are bad for user trust.
    const body = document.getElementsByTagName('BODY')[0];
    const masthead = document.getElementById('masthead');
    let outdated = document.createElement('div');
    outdated.setAttribute('id', 'outdatedBrowser');
    outdated.innerHTML = html;
    body.insertBefore(outdated, masthead);
    document.getElementById('outdatedBrowser').style.display = 'block';

    // Disable Rollbar reporting - we are guaranteed to get errors on unsupported browsers
    window.Rollbar.configure({ enabled: false });
  }
}
